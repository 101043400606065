import React, { Component } from "react";
// import moment from 'moment'
// import ApiCalendar from 'react-google-calendar-api';
import { Row, Col, Table }  from 'react-bootstrap';
// import TopDashboard from '../components/TopDashboard';
import "./Cal.css";
// import { Calendar, momentLocalizer } from 'react-big-calendar'
// import 'react-big-calendar/lib/css/react-big-calendar.css'
// const localizer = momentLocalizer(moment)

export default class Cal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // sign: false,
      // eventsList: [],
      // googleUserName: '',
    }
    // ApiCalendar.onLoad(() => {
    //   ApiCalendar.listenSign(this.signUpdate);
    //   this.componentDidMount()
    // });
  }
  // signUpdate = (sign) => {
  //   this.componentDidMount()
  // }
  componentDidMount = () => {
    // let sign = ApiCalendar.sign
    // let googleUserName = ''
    // if (sign) {
    //   var profile = ApiCalendar.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
    //   googleUserName = profile.getEmail()
    // }
    // this.setState({ sign, googleUserName }, () => {
    //   if (sign) {
    //     this.getEventsForAll()
    //   }
    // })
  }
  // handleSignClick = (e, name) => {
  //   if (name === 'sign-in') {
  //     ApiCalendar.handleAuthClick();
  //   } else if (name === 'sign-out') {
  //     ApiCalendar.handleSignoutClick();
  //     this.setState({ eventsList: [] })
  //   }
  // }
  // handleRefreshClick = () => {
  //   this.setState({ eventsList: [] }, () => {
  //     this.getEventsForAll()
  //   })
  // }
  // getEventsForAll = async () => {
  //   let users = [
  //     { 
  //       name: 'Richard A. Nava',
  //       initials: 'RA',
  //       color: '000000',
  //       email: 'rnava@geedop.com',
  //       calendar: 'rnava@geedop.com'
  //     },
  //     {
  //       name: 'Katia Benavidez',
  //       initials: 'KB',
  //       color: '2137a8',
  //       email: 'katia.benavidez@geedop.com',
  //       calendar: 'katia.benavidez@geedop.com'
  //     },
  //     { 
  //       name: 'Andrea Moreno',
  //       initials: 'AM',
  //       color: 'ab00ff',
  //       email: 'andrea.moreno@geedop.com',
  //       calendar: 'andrea.moreno@geedop.com'
  //     },
  //   ]
  //   let eventsList = [...this.state.eventsList]
  //   for (var i = users.length - 1; i >= 0; i--) {
  //     let { initials, color, calendar } = users[i]
  //     var timeMin = new Date()
  //     var timeMax = new Date()
  //     timeMin.setDate(timeMin.getDate() - 30)
  //     timeMax.setDate(timeMax.getDate() + 365)
  //     await ApiCalendar.gapi.client.calendar.events.list({
  //           'calendarId': calendar,
  //           'timeMin': timeMin.toISOString(),
  //           'timeMax': timeMax.toISOString(),
  //           'showDeleted': false,
  //           'singleEvents': true,
  //           'orderBy': 'startTime'
  //       }).then((response) => {
  //       let gEventsList = response.result.items
  //       for (var i = gEventsList.length - 1; i >= 0; i--) {
  //         let start = ''
  //         let end = ''
  //         let gStart = gEventsList[i].start
  //         let gEnd = gEventsList[i].end
  //         if (gStart.date) {
  //           start = new Date(gStart.date)
  //           end = new Date(gEnd.date)
  //         } else if (gStart.dateTime) {
  //           start = new Date(gStart.dateTime)
  //           end = new Date(gEnd.dateTime)
  //         }
  //         let title = (gEventsList[i].visibility === 'private' ? '(Busy)' : gEventsList[i].summary)
  //         title = initials + ':' + title
  //         let hexColor = color
  //         eventsList.push({start, end, title, hexColor})
  //       }
  //     });
  //   }
  //   this.setState({ eventsList })
  // }
  // handleEventColoring = (event, start, end, isSelected) => {
  //     var backgroundColor = '#' + event.hexColor;
  //     var style = {
  //       backgroundColor: backgroundColor,
  //     };
  //     return { style }
  //   }
  
  render() {
    return (
      <div className="Cal">
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <h4 className="section-title">2021 Holidays/Días Festivos</h4>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Day</th>
                  <th>Weekday</th>
                  <th>Event</th>
                  <th>Office</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Feb</td>
                  <td>01</td>
                  <td>Lunes</td>
                  <td>Día de la Constitución Mexicana</td>
                  <td>MX</td>
                </tr>
                <tr>
                  <td>Mar</td>
                  <td>15</td>
                  <td>Lunes</td>
                  <td>Natalicio de Benito Juárez</td>
                  <td>MX</td>
                </tr>
                <tr>
                  <td>Apr</td>
                  <td>02</td>
                  <td>Viernes</td>
                  <td>Viernes Santo</td>
                  <td>MX</td>
                </tr>
                <tr>
                  <td>Apr</td>
                  <td>30</td>
                  <td>Viernes</td>
                  <td>Día del Trabajo</td>
                  <td>MX</td>
                </tr>
                <tr>
                  <td>May</td>
                  <td>31</td>
                  <td>Monday</td>
                  <td>Memorial Day</td>
                  <td>US</td>
                </tr>
                <tr>
                  <td>Jul</td>
                  <td>05</td>
                  <td>Monday</td>
                  <td>Independence Day</td>
                  <td>US</td>
                </tr>
                <tr>
                  <td>Sep</td>
                  <td>06</td>
                  <td>Monday</td>
                  <td>Labor Day</td>
                  <td>US</td>
                </tr>
                <tr>
                  <td>Sep</td>
                  <td>16</td>
                  <td>Jueves</td>
                  <td>Independencia de México</td>
                  <td>MX</td>
                </tr>
                <tr>
                  <td>Nov</td>
                  <td>11</td>
                  <td>Thursday</td>
                  <td>Veterans Day</td>
                  <td>US</td>
                </tr>
                <tr>
                  <td>Nov</td>
                  <td>15</td>
                  <td>Lunes</td>
                  <td>Día de la Revolución Mexicana</td>
                  <td>MX</td>
                </tr>
                <tr>
                  <td>Nov</td>
                  <td>25</td>
                  <td>Thursday</td>
                  <td>Thanksgiving</td>
                  <td>US</td>
                </tr>
                <tr>
                  <td>Dec</td>
                  <td>24</td>
                  <td>Viernes</td>
                  <td>Noche Buena</td>
                  <td>MX, US</td>
                </tr>
                <tr>
                  <td>Dec</td>
                  <td>31</td>
                  <td>Viernes</td>
                  <td>Fin de Año</td>
                  <td>MX, US</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={2}></Col>
        </Row>
        {/*<TopDashboard 
          {...this.props}
        />
        <Container fluid className="calendar-ribbon" >
          <Row>
            <Col md={6} className="title">
              <h4 className="section-title">{'Calendar: '+ this.state.googleUserName}</h4>
            </Col>
            <Col md={6} className="buttons">
              {!this.state.sign &&
                <Button size="sm" onClick={(e) => this.handleSignClick(e, 'sign-in')}>Sign in</Button>
              }
              {this.state.sign &&
                <Button size="sm" variant="secondary" onClick={(e) => this.handleSignClick(e, 'sign-out')}>Sign out</Button>
              }
              {this.state.sign &&
                <Button size="sm" variant="primary" onClick={this.handleRefreshClick}>Refresh</Button>
              }
            </Col>
          </Row>
        </Container>
        <Container fluid className="calendar" >
          <div className="signin-cover">

          </div>
          <Calendar
            localizer={localizer}
            events={this.state.eventsList}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%' }}
            defaultView="week"
            popup={true}
            eventPropGetter={this.handleEventColoring}
          />
        </Container>*/}
      </div>
    );
  }
}