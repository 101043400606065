import React, { Component } from 'react'
import Moment from 'react-moment'
import { Container, Row, Col, Table, Badge } from 'react-bootstrap'
import './TopDashboard.css'
import { FaTrophy, FaSearchDollar, FaCalendarDay, FaRegClock } from 'react-icons/fa'
import { GiGolfFlag } from 'react-icons/gi'
import { xFormatMoney } from '../components/XFunctions';

class TopDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      monthRemainDays: 0,
    }
  }
  componentDidMount = () => {
    this.props.getOdooSalesStats()
    this.executeOnTimer()
    this.timer = setInterval(() => { this.executeOnTimer() }, 30000);
  }
  executeOnTimer = () => {
    this.calcRemainingDays()
    this.props.getOdooSalesStats()
  }
  UNSAFE_componentWillUnmount = () => {
    clearInterval(this.timer)
  }
  calcRemainingDays = () => {
    let date = new Date()
    let time = new Date(date.getTime())
    time.setMonth(date.getMonth() + 1)
    time.setDate(0)
    let monthRemainDays = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0
    this.setState({ monthRemainDays })
  }
  render() {
    // Get only the first three places. Not interested in the rest
    let leaderboardStats = JSON.parse(JSON.stringify(this.props.leaderboardStats))
    if (leaderboardStats.length >= 4 ) {
      leaderboardStats = leaderboardStats.slice(0, 3)
    }
    return (
      <Container fluid={true} className="top-dashboard sky-gradient-11">
        <Row>
          <Col className="clock">
            <h6><FaRegClock className="icon"/> Today</h6>
            <div className="current-time"><Moment style={{whiteSpace: "pre"}}  format={'LT[\n]ddd MMM D[\n]YYYY'} interval={1000}></Moment></div>
            {/* TODO: Add Google Maps Timezone API functionality to find current time in any city */}
          </Col>
          <Col className="quicklinks">
            <h6><FaCalendarDay className="icon"/> Days remaining</h6>
            <div className="days-remain"><strong className="value">{this.state.monthRemainDays}</strong></div>
          </Col>
          <Col className="monthly-sales-leaderboard">
            <h6><FaTrophy  className="icon"/> Leaderboard</h6>
            <Table hover size="sm" striped={false}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>QTs</th>
                  <th>SOs</th>
                  <th>Month</th>
                </tr>
              </thead>
              <tbody>
                {leaderboardStats.map((item, i) => {
                  return(
                    <tr key={i} id={'uid-' + item[0]} >
                      <td>{String(i + 1)}</td>
                      <td>{item[1]}</td>
                      <td>{item[4]}</td>
                      <td>{item[2]}</td>
                      <td>{item[3]}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
          <Col className="monthly-sales-sum">
            <h6><FaSearchDollar className="icon"/> Monthly</h6>
            <div><a className="goal" href="https://topgolf.com/us/" target="_blank" rel="noopener noreferrer"><GiGolfFlag />Topgolf: <strong>84,000.00</strong></a></div>
            <div>Current: <Badge variant="light">{this.props.monthSalesSum }</Badge></div>
            <div>Goal: <strong>{xFormatMoney(parseFloat(this.props.monthSalesSum.replace(/,/g, '')) - 84000.00)}</strong></div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default TopDashboard;
