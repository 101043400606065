import React, { Component } from "react";
import "./ContactDetails.css";
import { Container, Modal, Button, Table, Row, Col, ButtonToolbar } from "react-bootstrap";
import { FaEdit, FaRegTimesCircle } from 'react-icons/fa';
import { xToLocalDate } from  "./XFunctions";

const initialState = {}

export default class ContactDetails extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }
  componentDidMount = () => {}
  handleClose = () => {
    this.props.triggerCloseModal()
  }
  handleContactEditOnClick = () => {
    this.props.triggerContactEdit()
  }
  handleContactDeleteOnClick = () => {
    this.props.triggerContactDelete(this.props.selectedContact.id)
  }
  
  render() {
    const selContact = this.props.selectedContact
    return (
      <Modal className="ContactDetails" size="lg" show={this.props.showContactDetailsModal} onHide={this.handleClose} >
        <Modal.Header closeButton >
          <Modal.Title>
            <h5 className="contact-details-title">{selContact.firstName + ' ' + selContact.lastName}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="top">
            <Row className="tools-wrap d-flex justify-content-end">
              <Button className="contact-edit" variant="light" size="sm" onClick={this.handleContactEditOnClick}>
                <FaEdit /> Edit
              </Button>
            </Row>
            <Row className="contact-panel">
              <Col className="contact-fields">
                <div className="metadata">
                  <p>{'Last Modified: ' + (selContact.modifiedDateTime !== 'NULL_STRING' ? xToLocalDate(selContact.modifiedDateTime) : '[unknown]') + ' by ' + selContact.modifiedByUser.userName}</p>
                  <p>{'Created: ' + (selContact.createdDateTime !== 'NULL_STRING' ? xToLocalDate(selContact.createdDateTime) : '[unknown]') + ' by ' + selContact.createdByUser.userName}</p> 
                  <p>{'Owner: ' + selContact.ownerUser.userName}</p>
                  <p>{'Latest Activity: ' +  (selContact.latestActivityDateTime !== 'NULL_STRING' ? xToLocalDate(selContact.latestActivityDateTime) : '[unknown]')}</p>
                </div>
                <Table size="sm">
                  <tbody>
                    <tr>
                      <td className="field-label">Name</td>
                      <td>{(selContact.firstName !== 'NULL_STRING' ? selContact.firstName : '') + ' ' + (selContact.lastName !== 'NULL_STRING' ? selContact.lastName : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Account</td>
                      <td>{(selContact.accountName && selContact.accountName !== 'NULL_STRING' ? selContact.accountName : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Job Title</td>
                      <td>{(selContact.jobTitle && selContact.jobTitle !== 'NULL_STRING' ? selContact.jobTitle : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Office Phone</td>
                      <td>{(selContact.officePhone && selContact.officePhone !== 'NULL_STRING' ? selContact.officePhone : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Mobile Phone</td>
                      <td>{(selContact.mobilePhone && selContact.mobilePhone !== 'NULL_STRING' ? selContact.mobilePhone : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Primary Email</td>
                      <td>{(selContact.primaryEmail && selContact.primaryEmail !== 'NULL_STRING' ? selContact.primaryEmail : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Department</td>
                      <td>{(selContact.department && selContact.department !== 'NULL_STRING' ? selContact.department : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Status</td>
                      <td>{(selContact.status && selContact.status !== 'NULL_STRING' ? selContact.status : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Source</td>
                      <td>{(selContact.source && selContact.source !== 'NULL_STRING' ? selContact.source : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Secondary Email</td>
                      <td>{(selContact.secondaryEmail && selContact.secondaryEmail !== 'NULL_STRING' ? selContact.secondaryEmail : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Office Fax</td>
                      <td>{(selContact.officeFax && selContact.officeFax !== 'NULL_STRING' ? selContact.officeFax : '')}</td>
                    </tr>
                    <tr>
                      <td className="field-label">Primary Address</td>
                      <td>
                        <div>{(selContact.primaryAddressStreet1 && selContact.primaryAddressStreet1 !== 'NULL_STRING' ? selContact.primaryAddressStreet1 : '')}</div>
                        <div>{(selContact.primaryAddressStreet2 && selContact.primaryAddressStreet2 !== 'NULL_STRING' ? selContact.primaryAddressStreet2 : '')}</div>
                        <div>{(selContact.primaryAddressCity && selContact.primaryAddressCity !== 'NULL_STRING' ? selContact.primaryAddressCity : '') + ', ' + (selContact.primaryAddressState && selContact.primaryAddressState !== 'NULL_STRING' ? selContact.primaryAddressState : '') + ', ' + (selContact.primaryAddressPostalCode && selContact.primaryAddressPostalCode !== 'NULL_STRING' ? selContact.primaryAddressPostalCode : '')}</div>
                        <div>{(selContact.primaryAddressCountry && selContact.primaryAddressCountry !== 'NULL_STRING' ? selContact.primaryAddressCountry : '')}</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Secondary Address</td>
                      <td>
                        <div>{(selContact.secondaryAddressStreet1 && selContact.secondaryAddressStreet1 !== 'NULL_STRING' ? selContact.secondaryAddressStreet1 : '')}</div>
                        <div>{(selContact.secondaryAddressStreet2 && selContact.secondaryAddressStreet2 !== 'NULL_STRING' ? selContact.secondaryAddressStreet2 : '')}</div>
                        <div>{(selContact.secondaryAddressCity && selContact.secondaryAddressCity !== 'NULL_STRING' ? selContact.secondaryAddressCity : '') + ', ' + (selContact.secondaryAddressState && selContact.secondaryAddressState !== 'NULL_STRING' ? selContact.secondaryAddressState : '') + ', ' + (selContact.secondaryAddressPostalCode && selContact.secondaryAddressPostalCode !== 'NULL_STRING' ? selContact.secondaryAddressPostalCode : '')}</div>
                        <div>{(selContact.secondaryAddressCountry && selContact.secondaryAddressCountry !== 'NULL_STRING' ? selContact.secondaryAddressCountry : '')}</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Description</td>
                      <td>
                        {(selContact.description && selContact.description !== 'NULL_STRING') &&
                          <div dangerouslySetInnerHTML={{__html: selContact.description.replace(/\r?\n/g, '<br />')}} />
                        }
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="contact-delete">
            <Button variant="light" size="sm" onClick={this.handleContactDeleteOnClick}>
              <FaRegTimesCircle /> Delete
            </Button>
          </ButtonToolbar>
          <ButtonToolbar>
            <Button className="cancel-save" variant="secondary" size="sm" onClick={this.handleClose}>
              Close
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}


