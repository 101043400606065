import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify"
import config from "./config"

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "base",
        endpoint: config.apiBase.URL,
        region: config.apiBase.REGION
      },
      {
        name: "crmEmails",
        endpoint: config.apiCRMEmails.URL,
        region: config.apiBase.REGION
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: config.storage.BUCKET, //REQUIRED -  Amazon S3 bucket
      region: config.storage.REGION, //OPTIONAL -  Amazon service region
    }
  }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
