import React, { Component } from "react";
import { Storage } from 'aws-amplify';
import { Container, Row, Col, Card, Button, Tab, Nav, CardColumns }  from 'react-bootstrap';
import TopDashboard from '../components/TopDashboard';
import "./Marketing.css";
import { FaArrowDown } from 'react-icons/fa';

export default class Marketing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultMaterials: []
    }
  }
  componentDidMount = () => {
    Storage.list('marketing/materials/', { level: 'public', delimeter: "/" }).then(result => this.createMaterialObjects(result)).catch(err => console.log(err))
  }

  async createMaterialObjects(result){
    var contentFiles = result.filter((uri) => {
      var uriKey = uri.key
      var noFileExt = uriKey.substring(uriKey.lastIndexOf("/") + 1, uriKey.lastIndexOf("."));
      return noFileExt.endsWith("CONTENT")
    })
    let objectArray = contentFiles
      .map(obj => obj.key.split('/').slice(2)) // Remove the first two levels "marketing" and "materials"
      .reduce((children, mapuri) => this.insert(children, mapuri), []);
    var materials = []
    objectArray.forEach((cat) => {
      // var items = []
      cat.children.forEach((child) => {
        var contentUri = "marketing/materials/" + cat.name + "/" + child.name + "/CONTENT.zip"
        var metatUri = "marketing/materials/" + cat.name + "/" + child.name + "/META.json"
        var thumUri = "marketing/materials/" + cat.name + "/" + child.name + "/THUM.jpg"
        materials.push({ category: cat.name, name: child.name, contentUri, metatUri, thumUri })
      })
    })
    var resultMaterials = []
    await Promise.all(materials.map(async (material) => {
      var contentResult = await Storage.get(material.contentUri).then((data) => {return data}).catch(err => console.log(err))
      var thumResult = await Storage.get(material.thumUri).then((data) => {return data}).catch(err => console.log(err))
      var metaResult = await Storage.get(material.metatUri, {download: true}).then((data) => {return data}).catch(err => console.log(err))
      material.content = contentResult
      material.thumbnail = thumResult
      material.metadata = JSON.parse(metaResult.Body.toString())
      resultMaterials.push(material)
    }))
    var groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, []);
    }
    resultMaterials = groupBy(resultMaterials, 'category')
    this.setState({ resultMaterials })
  }

  // Insert path into directory tree structure:
  insert(children = [], [head, ...tail]) {
    let child = children.find(child => child.name === head);
    if (!child) children.push(child = {name: head, children: []});
    if (tail.length > 0) this.insert(child.children, tail);
    return children;
  }

  buildMaterialCards(resultMaterials, prop) {
    var matItems = []
    matItems.push(resultMaterials[prop].map((material, idx) => {
      return (
        <Card key={idx} >
          <Card.Img variant="top" src={material.thumbnail} className="small-image-card" />
          <Card.Body>
            <Card.Title>{material.metadata.title}</Card.Title>
            <Card.Text>{material.metadata.desc}</Card.Text>
            <Button variant="secondary" size="sm" href={material.content}>Download <FaArrowDown /></Button>
          </Card.Body>
        </Card>
      )
    }))
    return matItems
  }
  
  render() {
    const resultMaterials = this.state.resultMaterials
    var tabs = []
    var tabPanes = []
    var matItems = []
    var defaultTab = null
    for (var prop in resultMaterials) {
      matItems = this.buildMaterialCards(resultMaterials, prop)
      
      if (!defaultTab) {
        defaultTab = prop
      }
      tabPanes.push(<Tab.Pane eventKey={prop} key={prop} ><CardColumns>{matItems}</CardColumns></Tab.Pane>)
      tabs.push(<Nav.Item key={prop}><Nav.Link eventKey={prop}>{prop}</Nav.Link></Nav.Item>)
    }

    return (
      <div className="Marketing">
        <TopDashboard 
          {...this.props}
        />
        <Container fluid >
          <Row className="section-title">
            <Col><h4>Marketing Materials</h4></Col>
          </Row>
          <Row>
            <Col>
              {(defaultTab) && 
                <Tab.Container id="left-material-tabs" defaultActiveKey={defaultTab}>
                  <Row>
                    <Col sm={2}>
                      <Nav variant="pills" className="flex-column dark">
                        {tabs}
                      </Nav>
                    </Col>
                    <Col sm={10}>
                      <Tab.Content>
                        {tabPanes}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}