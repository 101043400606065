import React, { Component } from "react";
import "./Purchasing.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Container, ButtonToolbar, ToggleButtonGroup,
         ToggleButton, ButtonGroup, Button, Modal, Form, InputGroup
       } from "react-bootstrap";
import { API } from "aws-amplify";
import moment from 'moment';
import { xDateStrToUTCOdooString, xToLocalDate } from '../components/XFunctions';
import { FaCheck, FaFilter, FaSync, 
         FaRegStickyNote, FaSearch
       } from 'react-icons/fa';
import PurchaseDetails from '../components/PurchaseDetails';

export default class Purchasing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      purchasesList: [],
      purchasesFilter: 4,
      showPurchaseOrderModal: false,
      tableFilterShow: false,
      selectedPurchaseOrder: null,
      showWorkingMsg: false,
      porderTotalCount: null,
      searchByNumVal: '',
      poListViewSelIdx: null,
      poListView: null
    }
  }
  componentDidMount = () => {
    this.getPurchases()
  }
  getColumnWidth = (data, accessor) => {
    const cellLength = Math.max(...data.map(row => {
      let value = row[accessor]
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value).width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 22, 50)
  }
  getColumnWidthArray = (data, accessor) => {
    const cellLength = Math.max(...data.map(row => {
      let value = row[accessor] ? row[accessor][0] : null
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value + '.(0).').width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 20, 50)
  }
  getPurchases = () => {
    if (this.state.purchasesFilter === 4) {
      this.getActivePurchases()
    } else if (this.state.purchasesFilter === 5) {
      this.getOdooPurchasesByNum()
    } else {
      this.getOdooPurchases()
    }
  }
  getActivePurchases = async () => {
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'activepurchaseorders', 
      }
    }).then(response => response).catch(error => null)
    if (res) {
      this.loadPurchasesIntoTable(res.porders)
    }
  }
  getOdooPurchases = async () => {
    let startOfMonth, endOfMonth
    switch(this.state.purchasesFilter) {
      case 0:
        startOfMonth = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        break
      case 1:
        startOfMonth = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
        break
      case 2:
        startOfMonth = moment().subtract(1,'month').startOf('month').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().subtract(1,'month').endOf('month').format('YYYY-MM-DDTHH:mm:ss')
        break
      case 3:
        startOfMonth = moment().subtract(2,'months').startOf('month').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().subtract(2,'months').endOf('month').format('YYYY-MM-DDTHH:mm:ss')
        break
      default:
    }
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'purchaseorders', 
        frDate: xDateStrToUTCOdooString(startOfMonth + 'Z'),
        toDate: xDateStrToUTCOdooString(endOfMonth + 'Z'),
      }
    }).then(response => response).catch(error => null)
    if (res) {
      // console.log(res)
      this.loadPurchasesIntoTable(res.porders)
    }
  }
  getOdooPurchaseProducts= async (purchaseId) => {
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'purchaseorderproducts',
        orderNumber: purchaseId
      }
    }).then(response => response).catch(error => null)
    if (res) {
      // console.log(res)
      this.loadPurchaseProducts(res.porderlines)
    }
  }
  getOdooPurchasesByNum = async () => {
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'purchaseordersbynum', 
        number: this.state.searchByNumVal
      }
    }).then(response => response).catch(error => null)
    if (res) {
      this.loadPurchasesIntoTable(res.porders)
    }
  }
  loadPurchaseProducts = (purchaseOrderProducts) => {
    let productList = []
    purchaseOrderProducts.forEach(function(el) {
      productList.push({
        name: el.name,
        qty: el.product_qty
      })
    })
    this.setState({ purchaseOrderProducts: productList })
  }
  loadPurchasesIntoTable = (porders) => {
    let purchasesList = []
    porders.forEach(function(el) {
      let userSplit = el.create_uid[1].split(' ')
      let userAbb = userSplit[0].charAt(0) + userSplit[1].charAt(0)
      let partnerSp = el.partner_id[1].split(' ')
      let partner = partnerSp[0].replace(',', '')
      // let po = el.name
      let slen = el.shipments.length
      let nozero = slen > 0
      // let stli = slen > 0 ? slen - 1 : 0
      let stli = 0
      if (nozero) {
        el.shipments.forEach((ship, si) => {
          if (el.shipments[si].tracking === undefined) {
            el.shipments[si].tracking = []
          }
          if (el.shipments[si].invoices === undefined) {
            el.shipments[si].invoices = []
          }
        })
      } else {
        if (el.tracking === undefined) {
         el.tracking = []
        }
        if (el.invoices === undefined) {
          el.invoices = []
        }
      }
      purchasesList.push({
        amount: el.amount_total,
        company: el.company_id[1],
        poc: userAbb,
        currency: el.currency_id[1],
        confirmed: el.date_order,
        approved: el.date_approve,
        destination: el.picking_type_id[1],
        number: el.name,
        id: el.id,
        partner_full: el.partner_id[1],
        partner: partner,
        partner_ref: el.partner_ref,
        notes: el.notes,
        incoterm: el.incoterm_id ? el.incoterm_id[1] : false,
        received_confirmation: el.received_confirmation,
        shipments: el.shipments,
        ship_via: nozero ? el.shipments[stli].ship_via : 'N/A',
        ship_date: nozero ? el.shipments[stli].ship_date : '0000-00-00',
        ship_shipped: nozero ? el.shipments[stli].ship_shipped : true,
        tracking: nozero ? el.shipments[stli].tracking : ['N/A'],
        deliver_date: nozero ? el.shipments[stli].deliver_date : el.deliver_date,
        deliver_delivered: nozero ? el.shipments[stli].deliver_delivered : el.deliver_delivered,
        deliver_packing_filed: nozero ? el.shipments[stli].deliver_packing_filed : el.deliver_packing_filed,
        invoices: nozero ? el.shipments[stli].invoices : el.invoices,
        invoices_filed: nozero ? el.shipments[stli].invoices_filed : el.invoices_filed,
      })
    })
    this.setState({ purchasesList, porderTotalCount: purchasesList.length, showWorkingMsg: false }, () => {
      // console.log('purchasesList: ', purchasesList)
    })
  }
  handleFilterChange = (e) => {
    this.setState({ searchByNumVal: '', porderTotalCount: null, purchasesFilter: e, purchasesList: [] }, () => {
      this.getPurchases()
    })
  }
  handlePurchaseOrderModalClose = () => {
    this.setState({ selectedPurchaseOrder: null, showPurchaseOrderModal: false })
  }
  handleTableFilterShowClick = (e) => {
    this.setState({ tableFilterShow: e.target.checked })
  }
  handleTableRefreshClick = () => {
    this.setState({ porderTotalCount: null, purchasesList: [] }, () => {
      this.getPurchases()
    })
  }
  handleSearchByNumOnChange = (e) => {
    this.setState({ searchByNumVal: e.target.value })
  }
  handleSearchByNumOnClick = () => {
    this.setState({ porderTotalCount: null, purchasesFilter: 5, purchasesList: [] }, () => {
      this.getPurchases()
    })
  }
  savePurchaseOrder = async (order) => {
    this.setState({ showWorkingMsg: true })
    await API.post('base', 'litoken', {
      body: { 
        ...order,
        type: 'pordersave'
      }
    }).then(response => {
      this.getPurchases()
    }).catch(error => error)
  }
  checkIfReady = (rowInfo, column) => {
    if  (column && rowInfo) {
      switch (column.id) {
        case 'ship_via':
          if (!rowInfo.original.ship_via) {
            return 'notready'
          } 
          break
        case 'tracking':
          if (rowInfo.original.tracking.length === 0) {
            return 'notready'
          }
          break
        case 'ship_date':
          if ((!rowInfo.original.ship_shipped) || (!rowInfo.original.ship_shipped && rowInfo.original.ship_date)) {
            return 'notready'
          } 
          break
        case 'deliver_date':
          if ((!rowInfo.original.deliver_delivered) || (!rowInfo.original.deliver_delivered && rowInfo.original.deliver_date)) {
            return 'notready'
          } 
          break
        case 'received_confirmation':
          if (!rowInfo.original.received_confirmation) {
            return 'notready'
          }
          break
        case 'invoices_filed':
          if (!rowInfo.original.invoices_filed) {
            return 'notready'
          }
          break
        case 'deliver_packing_filed':
          if (!rowInfo.original.deliver_packing_filed) {
            return 'notready'
          }
          break
        case 'invoices':
          if (rowInfo.original.invoices && rowInfo.original.invoices.length === 0) {
            return 'notready'
          }
          break
        default:
      }
    }
    return null
  }
  handleOnNextPOClick = async () => {
    let { poListView, poListViewSelIdx, purchasesList } = this.state
    let newPoListViewSelIdx = poListViewSelIdx + 1
    if (newPoListViewSelIdx < (poListView.length)) {
      this.handlePurchaseOrderModalClose()
      var selectedPurchaseOrder = purchasesList.find(obj => {
        return obj.id === poListView[newPoListViewSelIdx]
      })
      await this.getOdooPurchaseProducts(selectedPurchaseOrder.id)
      this.setState({ 
        selectedPurchaseOrder, 
        showPurchaseOrderModal: true, 
        poListViewSelIdx: newPoListViewSelIdx 
      })
    }
  }
  handleOnPrevPOClick = async () => {
    let { poListView, poListViewSelIdx, purchasesList } = this.state
    let newPoListViewSelIdx = poListViewSelIdx - 1
    if (newPoListViewSelIdx > -1) {
      this.handlePurchaseOrderModalClose()
      var selectedPurchaseOrder = purchasesList.find(obj => {
        return obj.id === poListView[newPoListViewSelIdx]
      })
      await this.getOdooPurchaseProducts(selectedPurchaseOrder.id)
      this.setState({ 
        selectedPurchaseOrder, 
        showPurchaseOrderModal: true, 
        poListViewSelIdx: newPoListViewSelIdx 
      })
    }
  }


  render() {
    const columns = [
      {
        columns: [
          {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: (row) => {
              return row.index + 1
            },
            style: { textAlign: 'right', backgroundColor: '#e4e4e4' },
            width: 34,
          },
          {
            Header: "Entered",
            accessor: "confirmed",
            style: { textAlign: 'left' },
            width: 145,
            Cell: props => xToLocalDate(props.value),
            filterable: false,
          },
          {
            Header: "POC",
            accessor: "poc",
            style: { textAlign: 'center' },
            width: 36
          },
          {
            Header: "PO Number",
            accessor: "number",
            style: { textAlign: 'center' },
            width: this.getColumnWidth(this.state.purchasesList, 'number')
          },
          {
            Header: "Partner",
            accessor: "partner",
            width: this.getColumnWidth(this.state.purchasesList, 'partner')
          },
          // {
          //   Header: "Amount",
          //   accessor: "amount",
          //   width: 74,
          //   style: { textAlign: 'right' },
          //   Cell: props => xFormatMoney(props.value)
          // },
          {
            Header: "Vendor Confirm",
            accessor: "received_confirmation",
            width: 65,
            style: { textAlign: 'center' },
            filterable: false,
            Cell: props => (props.value ? <FaCheck /> : null ),

          },
          {
            Header: "Ship Carrier",
            accessor: "ship_via",
            width: this.getColumnWidth(this.state.purchasesList, 'ship_via'),
          },
          {
            Header: "Tracking IDs",
            accessor: "tracking",
            width: this.getColumnWidthArray(this.state.purchasesList, 'tracking'),
             Cell: (props) => {
              switch(props.value.length) {
                case 0:
                  return null
                default:
                  return <div><span className="multi-count" >{'(' + String(props.value.length) + ') '}</span>{String(props.value[0])}</div>
              }
            }
          },
          {
            Header: "Ship Date",
            accessor: "ship_date",
            style: { textAlign: 'center' },
            width: this.getColumnWidth(this.state.purchasesList, 'ship_date'),
            filterable: false,
          },
          {
            Header: "Delivered Date",
            accessor: "deliver_date",
            width: this.getColumnWidth(this.state.purchasesList, 'deliver_date'),
            style: { textAlign: 'center' },
            filterable: false,
          },
          {
            Header: "Invoice Filed",
            accessor: "invoices_filed",
            style: { textAlign: 'center' },
            width: 60,
            Cell: props => (props.value ? <FaCheck /> : null ),
            filterable: false,
          },
          {
            Header: "Packing Filed",
            accessor: "deliver_packing_filed",
            style: { textAlign: 'center' },
            width: 70,
            Cell: props => (props.value ? <FaCheck /> : null ),
            filterable: false,
          },
          {
            Header: "Invoice Numbers",
            accessor: "invoices",
            width: this.getColumnWidthArray(this.state.purchasesList, 'invoices'),
            Cell: (props) => {
              if (!props.value) {
                return null
              }
              switch(props.value.length) {
                case 0:
                  return null
                default:
                  return <div><span className="multi-count" >{'(' + String(props.value.length) + ') '}</span>{String(props.value[0])}</div>
              }
            }
          },
          {
            Header: "Notes",
            accessor: "notes",
            style: { textAlign: 'center' },
            width: 40,
            Cell: props => (props.value ? <span className="notes"><FaRegStickyNote /></span> : '' ),
            filterable: false,
          },
        ]
      },
    ]
    const { purchasesFilter, tableFilterShow, purchasesList, showPurchaseOrderModal, selectedPurchaseOrder, purchaseOrderProducts } = this.state
    return (
      <div className="Purchasing">
        <Container fluid={true} className="purchases-ribbon">
          <div className="purchases-filters">
            <h4 className="section-title">Purchase Orders</h4><div className="porder-total-count">{this.state.porderTotalCount !== null ? this.state.porderTotalCount : <div className="spinner"></div>}</div>
            <ButtonToolbar className="filter-toolbar">
              <ToggleButtonGroup size="sm" type="radio" name="options" value={purchasesFilter} onChange={this.handleFilterChange}>
                <ToggleButton value={4} variant="light">Active</ToggleButton>
                <ToggleButton value={0} variant="light">Today</ToggleButton>
                <ToggleButton value={1} variant="light">This month</ToggleButton>
                <ToggleButton value={2} variant="light">{moment().subtract(1,'month').format('MMMM')}</ToggleButton>
                <ToggleButton value={3} variant="light">{moment().subtract(2,'month').format('MMMM')}</ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
            <Form className="search-by-num">
              <Form.Group controlId="searchByNumber" className="">
                <InputGroup className="">
                  <Form.Control placeholder="PO number" onChange={this.handleSearchByNumOnChange} size="sm" value={this.state.searchByNumVal} />
                  <InputGroup.Append>
                    <Button onClick={this.handleSearchByNumOnClick} size="sm" variant="light"><FaSearch /></Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
            <Button className="table-refresh" size="sm" variant="light" onClick={this.handleTableRefreshClick}><FaSync /></Button>
            <ButtonGroup toggle className="table-filter-show" >
              <ToggleButton 
                type="checkbox" 
                size="sm" 
                variant="light" 
                checked={tableFilterShow}
                onChange={this.handleTableFilterShowClick}><FaFilter /></ToggleButton>
            </ButtonGroup>
          </div>
        </Container>
        <ReactTable
          defaultSorted={[{id: 'confirmed', desc: true}]}
          pageSizeOptions={[50, 100]}
          data={purchasesList}
          columns={columns}
          defaultPageSize={100}
          className="-highlight"
          filterable={tableFilterShow}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: async (e, handleOriginal) => {
                if (rowInfo) {
                  await this.getOdooPurchaseProducts(rowInfo.original.id)
                  let poListView = []
                  let sortedData = instance.state.sortedData
                  for (var i = 0; i <= sortedData.length - 1; i++) {
                    poListView.push(sortedData[i]._original.id)
                  }
                  this.setState({ 
                    selectedPurchaseOrder: rowInfo.original,
                    showPurchaseOrderModal: true, 
                    poListViewSelIdx: rowInfo.viewIndex,
                    poListView
                  })
                }
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal();
                }
              },
              className: this.checkIfReady(rowInfo, column)
            };
          }}
        />
        {selectedPurchaseOrder &&
          <PurchaseDetails 
            selectedPurchaseOrder={JSON.parse(JSON.stringify(selectedPurchaseOrder))}
            purchaseOrderProducts={purchaseOrderProducts} 
            showPurchaseOrderModal={showPurchaseOrderModal}
            triggerCloseModal={this.handlePurchaseOrderModalClose}
            savePurchaseOrder={this.savePurchaseOrder}
            nextPurchaseOrder={this.handleOnNextPOClick}
            prevPurchaseOrder={this.handleOnPrevPOClick}
          />
        }
        <Modal className="working-msg" size="sm" show={this.state.showWorkingMsg} >
          <Modal.Header></Modal.Header>
          <Modal.Body>Please wait...</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }
}