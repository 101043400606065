import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { FaTrashAlt } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';
import "./FilesDrop.css";
import { xGenerateUID } from '../components/XFunctions';


function FilesDrop(props) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      let newFiles = acceptedFiles.map(file => Object.assign(file, {
        id: file.id ? file.id : xGenerateUID(),
        preview: URL.createObjectURL(file)
      }))
      let originalFiles = [...files]
      originalFiles.push(...newFiles)
      setFiles(originalFiles)
      props.updateFiles(originalFiles)
    }
  });
  function removeImage(fileid, e) {
    e.stopPropagation()
    if (this.props.productArchived) {
      alert('Product is archived.')
      return
    }
    let filteredFiles = files.filter(x => x.id !== fileid)
    setFiles(filteredFiles)
    props.updateFiles(filteredFiles)
  }
  function removeImageExisting(file, e) {
    e.stopPropagation()
    if (props.productArchived) {
      alert('Product is archived.')
      return
    }
    props.fileExistingDelete(file)
  }
  const thumbsExisting = props.filesExisting.map(file => (
    <div className="thumb" key={file}>
      <div className="thumbinner">
        <div className="ribbon"><FaTrashAlt className="trash" onClick={(e) => removeImageExisting(file, e)} /></div>
        <img
          src={'https://84mbjzuf67.execute-api.us-east-1.amazonaws.com/dev/90x90/' + file} alt=""
        />
      </div>
    </div>
  ));
  const thumbs = files.map(file => (
    <div className="thumb" key={file.id}>
      <div className="thumbinner">
        <div className="ribbon"><FaTrashAlt className="trash" onClick={(e) => removeImage(file.id, e)} /></div>
        <img
          src={file.preview} alt=""
        />
      </div>
    </div>
  ));
  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className="FilesDrop">
      <div className="thumbscontainer">
        {thumbsExisting}
        {thumbs}
        <div className="thumb">
          <div className="thumbinner">
            <div {...getRootProps({className: 'dropzone'})}>
              {props.productArchived !== true &&
                <input {...getInputProps()} />
              }
              <GoPlus className="plus" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilesDrop