import React, { Component } from "react";
import "./ContactEdit.css";
import { Container, Modal, Button, Table, Row, Col, ButtonToolbar, Form } from "react-bootstrap";

const initialState = {
  selectedContact: null,
}
const sources = [
  "",
  "LinkedIn",
  "Inbound Email",
  "CC'd on Email",
  "Self-Generated",
  "Stop-Painting",
  "RPS Corporation",
  "Inbound Call",
  "Tradeshow",
  "Word of Mouth",
]
const statuses = [
  "Qualified",
  "Customer",
]
const countries = [
  "",
  "Afganistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Ter",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Canary Islands",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Channel Islands",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos Island",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote DIvoire",
  "Croatia",
  "Cuba",
  "Curaco",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Ter",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Great Britain",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guyana",
  "Haiti",
  "Hawaii",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indonesia",
  "India",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea Sout",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malaysia",
  "Malawi",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Midway Islands",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Nambia",
  "Nauru",
  "Nepal",
  "Netherland Antilles",
  "Netherlands",
  "Nevis",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau Island",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Phillipines",
  "Pitcairn Island",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Montenegro",
  "Republic of Serbia",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "St Barthelemy",
  "St Eustatius",
  "St Helena",
  "St Kitts-Nevis",
  "St Lucia",
  "St Maarten",
  "St Pierre & Miquelon",
  "St Vincent & Grenadines",
  "Saipan",
  "Samoa",
  "Samoa American",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tahiti",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos Is",
  "Tuvalu",
  "Uganda",
  "United Kingdom",
  "Ukraine",
  "United Arab Erimates",
  "United States of America",
  "Uraguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (Brit)",
  "Virgin Islands (USA)",
  "Wake Island",
  "Wallis & Futana Is",
  "Yemen",
  "Zaire",
  "Zambia",
  "Zimbabwe",
]

export default class ContactEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {...initialState}
  }
  componentDidMount = () => {
    let sc = this.props.isNew ? {} : {...this.props.selectedContact}
    let sa = this.props.selectedAccount
    let selectedContact = {
      id: sc.id,
      accountId: sa.id,
      firstName: (sc.firstName && sc.firstName !== 'NULL_STRING') ? sc.firstName : '',
      lastName: (sc.lastName && sc.lastName !== 'NULL_STRING') ? sc.lastName : '',
      accountName: sa.name,
      jobTitle: (sc.jobTitle && sc.jobTitle !== 'NULL_STRING') ? sc.jobTitle : '',
      officePhone: (sc.officePhone && sc.officePhone !== 'NULL_STRING') ? sc.officePhone : '',
      mobilePhone: (sc.mobilePhone && sc.mobilePhone !== 'NULL_STRING') ? sc.mobilePhone : '',
      primaryEmail: (sc.primaryEmail && sc.primaryEmail !== 'NULL_STRING') ? sc.primaryEmail : '',
      department: (sc.department && sc.department !== 'NULL_STRING') ? sc.department : '',
      status: (sc.status && sc.status !== 'NULL_STRING') ? sc.status : '',
      source: (sc.source && sc.source !== 'NULL_STRING') ? sc.source : '',
      secondaryEmail: (sc.secondaryEmail && sc.secondaryEmail !== 'NULL_STRING') ? sc.secondaryEmail : '',
      officeFax: (sc.officeFax && sc.officeFax !== 'NULL_STRING') ? sc.officeFax : '',
      primaryAddressStreet1: (sc.primaryAddressStreet1 && sc.primaryAddressStreet1 !== 'NULL_STRING') ? sc.primaryAddressStreet1 : '',
      primaryAddressStreet2: (sc.primaryAddressStreet2 && sc.primaryAddressStreet2 !== 'NULL_STRING') ? sc.primaryAddressStreet2 : '',
      primaryAddressCity: (sc.primaryAddressCity && sc.primaryAddressCity !== 'NULL_STRING') ? sc.primaryAddressCity : '',
      primaryAddressState: (sc.primaryAddressState && sc.primaryAddressState !== 'NULL_STRING') ? sc.primaryAddressState : '',
      primaryAddressPostalCode: (sc.primaryAddressPostalCode && sc.primaryAddressPostalCode !== 'NULL_STRING') ? sc.primaryAddressPostalCode : '',
      primaryAddressCountry: (sc.primaryAddressCountry && sc.primaryAddressCountry !== 'NULL_STRING') ? sc.primaryAddressCountry.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '',
      secondaryAddressStreet1: (sc.secondaryAddressStreet1 && sc.secondaryAddressStreet1 !== 'NULL_STRING') ? sc.secondaryAddressStreet1 : '',
      secondaryAddressStreet2: (sc.secondaryAddressStreet2 && sc.secondaryAddressStreet2 !== 'NULL_STRING') ? sc.secondaryAddressStreet2 : '',
      secondaryAddressCity: (sc.secondaryAddressCity && sc.secondaryAddressCity !== 'NULL_STRING') ? sc.secondaryAddressCity : '',
      secondaryAddressState: (sc.secondaryAddressState && sc.secondaryAddressState !== 'NULL_STRING') ? sc.secondaryAddressState : '',
      secondaryAddressPostalCode: (sc.secondaryAddressPostalCode && sc.secondaryAddressPostalCode !== 'NULL_STRING') ? sc.secondaryAddressPostalCode : '',
      secondaryAddressCountry: (sc.secondaryAddressCountry && sc.secondaryAddressCountry !== 'NULL_STRING') ? sc.secondaryAddressCountry.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '',
      description: (sc.description && sc.description !== 'NULL_STRING') ? sc.description : '',
    }
    this.setState({ selectedContact })
  }
  UNSAFE_componentWillUnmount = () => {
    this.setState({ selectedContact: null })
  }
  handleClose = () => {
    this.componentDidMount()
    this.props.triggerCloseModal()
  }
  handleOnChange = (e, id) => {
    let value = e.target.value
    this.setState(prevState => ({
      selectedContact: {
        ...prevState.selectedContact,
        [id]: value
      }
    }))
  }
  handleSave = () => {
    let sc = {...this.state.selectedContact}
    if (sc.firstName === '' || sc.lastName === '') {
      alert('Please enter First Name and Last Name.')
      return
    }
    let selectedContact = {}
    for(var prop in sc) {
      if (sc[prop] !== '') {
        selectedContact[prop] = sc[prop]
      }
    }
    this.props.triggerSaveContact(selectedContact)
  }
  
  render() {
    const selCt = this.state.selectedContact
    if (!selCt) {
      return null
    }
    return (
      <Modal className="ContactEdit" size="lg" show={this.props.showContactEditModal} onHide={this.handleClose} >
        <Modal.Header closeButton >
          <Modal.Title>
            <h5 className="contact-edit-title">{selCt.firstName + ' ' + selCt.lastName}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="top">
            <Row className="tools-wrap d-flex justify-content-end">
            </Row>
            <Row className="contact-edit-panel">
              <Col className="contact-edit-fields">
                <Table size="sm">
                  <tbody>
                    <tr>
                      <td className="field-label">First Name</td>
                      <td colSpan="3">
                        <Form.Control required size="sm" type="text" placeholder="Fisrt Name" value={selCt.firstName} onChange={(e) => this.handleOnChange(e, 'firstName')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Last Name</td>
                      <td colSpan="3">
                        <Form.Control required size="sm" type="text" placeholder="Last Name" value={selCt.lastName} onChange={(e) => this.handleOnChange(e, 'lastName')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Account Name</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Account Name" value={selCt.accountName} disabled />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Job Title</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Job Title" value={selCt.jobTitle} onChange={(e) => this.handleOnChange(e, 'jobTitle')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Office Phone</td>
                      <td>
                        <Form.Control size="sm" type="text" placeholder="Office Phone" value={selCt.officePhone} onChange={(e) => this.handleOnChange(e, 'officePhone')} />
                      </td>
                      <td className="field-label">Mobile Phone</td>
                      <td>
                        <Form.Control size="sm" type="text" placeholder="Mobile Phone" value={selCt.mobilePhone} onChange={(e) => this.handleOnChange(e, 'mobilePhone')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Primary Email</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Primary Email" value={selCt.primaryEmail} onChange={(e) => this.handleOnChange(e, 'primaryEmail')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Department</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Department" value={selCt.department} onChange={(e) => this.handleOnChange(e, 'department')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Status</td>
                      <td colSpan="3">
                        <Form.Control size="sm" as="select" value={selCt.status} onChange={(e) => this.handleOnChange(e, 'status')} >
                          {statuses.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Source</td>
                      <td colSpan="3">
                        <Form.Control size="sm" as="select" value={selCt.source} onChange={(e) => this.handleOnChange(e, 'source')} >
                          {sources.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Secondary Email</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Secondary Email" value={selCt.secondaryEmail} onChange={(e) => this.handleOnChange(e, 'secondaryEmail')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Office Fax</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Office Fax" value={selCt.officeFax} onChange={(e) => this.handleOnChange(e, 'officeFax')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Primary Address</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Street 1" value={selCt.primaryAddressStreet1} onChange={(e) => this.handleOnChange(e, 'primaryAddressStreet1')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="Street 2" value={selCt.primaryAddressStreet2} onChange={(e) => this.handleOnChange(e, 'primaryAddressStreet2')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="City" value={selCt.primaryAddressCity} onChange={(e) => this.handleOnChange(e, 'primaryAddressCity')} />
                        <div className="spacer" />
                        <Row>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="State" value={selCt.primaryAddressState} onChange={(e) => this.handleOnChange(e, 'primaryAddressState')} />
                          </Col>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="Postal Code" value={selCt.primaryAddressPostalCode} onChange={(e) => this.handleOnChange(e, 'primaryAddressPostalCode')} />
                          </Col>
                        </Row>
                        <div className="spacer" />
                        <Form.Control size="sm" as="select" value={selCt.primaryAddressCountry} onChange={(e) => this.handleOnChange(e, 'primaryAddressCountry')} >
                          {countries.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Secondary Address</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Street 1" value={selCt.secondaryAddressStreet1} onChange={(e) => this.handleOnChange(e, 'secondaryAddressStreet1')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="Street 2" value={selCt.secondaryAddressStreet2} onChange={(e) => this.handleOnChange(e, 'secondaryAddressStreet2')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="City" value={selCt.secondaryAddressCity} onChange={(e) => this.handleOnChange(e, 'secondaryAddressCity')} />
                        <div className="spacer" />
                        <Row>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="State" value={selCt.secondaryAddressState} onChange={(e) => this.handleOnChange(e, 'secondaryAddressState')} />
                          </Col>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="Postal Code" value={selCt.secondaryAddressPostalCode} onChange={(e) => this.handleOnChange(e, 'secondaryAddressPostalCode')} />
                          </Col>
                        </Row>
                        <div className="spacer" />
                        <Form.Control size="sm" as="select" value={selCt.secondaryAddressCountry} onChange={(e) => this.handleOnChange(e, 'secondaryAddressCountry')} >
                          {countries.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Description</td>
                      <td colSpan="3">
                        <Form.Control size="sm" as="textarea" rows="10" placeholder="Description" value={selCt.description} onChange={(e) => this.handleOnChange(e, 'description')} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button className="cancel-save" variant="secondary" size="sm" onClick={this.handleClose}>Cancel</Button>
            <Button className="cancel-save" variant="primary" size="sm" onClick={this.handleSave}>Save</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}


