import React, { Component } from "react";
import "./AccountEdit.css";
import { Container, Modal, Button, Table, Row, Col, ButtonToolbar, Form } from "react-bootstrap";
import { FaRegTimesCircle } from 'react-icons/fa';

const initialState = {
  selectedAccount: null,
}
const industries = [
  "",
  "Aerospace",
  "Automotive",
  "Call Center",
  "Cardboard",
  "Chemistry",
  "Developer",
  "Distributor",
  "Electric",
  "Electronic",
  "Energy",
  "Food",
  "Glass",
  "Medical",
  "Metallurgy",
  "Metalworking",
  "Packaging",
  "Plastics",
  "Services",
  "Textile",
  "Vacant",
  "Warehouse/Logistics/Distribution",
  "Woodworking",
]
const statuses = [
  "",
  "1-Prospect",
  "2-Information",
  "3-Sample",
  "4-Quote",
  "5-Supplier",
  "6-Client",
  "7-Hold",
  "8-No Interest",
  "9-Abandoned",
]
const countries = [
  "",
  "Afganistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Ter",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Canary Islands",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Channel Islands",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos Island",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote DIvoire",
  "Croatia",
  "Cuba",
  "Curaco",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Ter",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Great Britain",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guyana",
  "Haiti",
  "Hawaii",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indonesia",
  "India",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea Sout",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malaysia",
  "Malawi",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Midway Islands",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Nambia",
  "Nauru",
  "Nepal",
  "Netherland Antilles",
  "Netherlands",
  "Nevis",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau Island",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Phillipines",
  "Pitcairn Island",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Montenegro",
  "Republic of Serbia",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "St Barthelemy",
  "St Eustatius",
  "St Helena",
  "St Kitts-Nevis",
  "St Lucia",
  "St Maarten",
  "St Pierre & Miquelon",
  "St Vincent & Grenadines",
  "Saipan",
  "Samoa",
  "Samoa American",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tahiti",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos Is",
  "Tuvalu",
  "Uganda",
  "United Kingdom",
  "Ukraine",
  "United Arab Erimates",
  "United States of America",
  "Uraguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (Brit)",
  "Virgin Islands (USA)",
  "Wake Island",
  "Wallis & Futana Is",
  "Yemen",
  "Zaire",
  "Zambia",
  "Zimbabwe",
]

export default class AccountEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {...initialState}
  }
  componentDidMount = () => {
    let sa = this.props.isNew ? {} : {...this.props.selectedAccount}
    let selectedAccount = {
      id: sa.id,
      name: (sa.name && sa.name !== 'NULL_STRING') ? sa.name : '',
      brand: (sa.brand && sa.brand !== 'NULL_STRING') ? sa.brand : '',
      industry: (sa.industry && sa.industry !== 'NULL_STRING') ? sa.industry : '',
      status: (sa.status && sa.status !== 'NULL_STRING') ? sa.status : '',
      officePhone: (sa.officePhone && sa.officePhone !== 'NULL_STRING') ? sa.officePhone : '',
      officeFax: (sa.officeFax && sa.officeFax !== 'NULL_STRING') ? sa.officeFax : '',
      website: (sa.website && sa.website !== 'NULL_STRING') ? sa.website : '',
      shippingAddressStreet1: (sa.shippingAddressStreet1 && sa.shippingAddressStreet1 !== 'NULL_STRING') ? sa.shippingAddressStreet1 : '',
      shippingAddressStreet2: (sa.shippingAddressStreet2 && sa.shippingAddressStreet2 !== 'NULL_STRING') ? sa.shippingAddressStreet2 : '',
      shippingAddressCity: (sa.shippingAddressCity && sa.shippingAddressCity !== 'NULL_STRING') ? sa.shippingAddressCity : '',
      shippingAddressState: (sa.shippingAddressState && sa.shippingAddressState !== 'NULL_STRING') ? sa.shippingAddressState : '',
      shippingAddressPostalCode: (sa.shippingAddressPostalCode && sa.shippingAddressPostalCode !== 'NULL_STRING') ? sa.shippingAddressPostalCode : '',
      shippingAddressCountry: (sa.shippingAddressCountry && sa.shippingAddressCountry !== 'NULL_STRING') ? sa.shippingAddressCountry.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '',
      description: (sa.description && sa.description !== 'NULL_STRING') ? sa.description : '',
      billingAddressStreet1: (sa.billingAddressStreet1 && sa.billingAddressStreet1 !== 'NULL_STRING') ? sa.billingAddressStreet1 : '',
      billingAddressStreet2: (sa.billingAddressStreet2 && sa.billingAddressStreet2 !== 'NULL_STRING') ? sa.billingAddressStreet2 : '',
      billingAddressCity: (sa.billingAddressCity && sa.billingAddressCity !== 'NULL_STRING') ? sa.billingAddressCity : '',
      billingAddressState: (sa.billingAddressState && sa.billingAddressState !== 'NULL_STRING') ? sa.billingAddressState : '',
      billingAddressPostalCode: (sa.billingAddressPostalCode && sa.billingAddressPostalCode !== 'NULL_STRING') ? sa.billingAddressPostalCode : '',
      billingAddressCountry: (sa.billingAddressCountry && sa.billingAddressCountry !== 'NULL_STRING') ? sa.billingAddressCountry.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '',
    }
    this.setState({ selectedAccount })
  }
  UNSAFE_componentWillUnmount = () => {
    this.setState({ selectedAccount: null })
  }
  handleClose = () => {
    this.componentDidMount()
    this.props.triggerCloseModal()
  }
  handleOnChange = (e, id) => {
    let value = e.target.value
    this.setState(prevState => ({
      selectedAccount: {
        ...prevState.selectedAccount,
        [id]: value
      }
    }))
  }
  handleSave = () => {
    let sa = {...this.state.selectedAccount}
    if (sa.name === '') {
      alert('Please enter Name.')
      return
    }
    let selectedAccount = {}
    for(var prop in sa) {
      if (sa[prop] !== '') {
        selectedAccount[prop] = sa[prop]
      }
    }
    this.props.triggerSaveAccount(selectedAccount)
  }
  handleAccountDeleteOnClick = () => {
    this.props.triggerAccountDelete(this.props.selectedAccount.id)
  }
  
  render() {
    const selAcct = this.state.selectedAccount
    if (!selAcct) {
      return null
    }
    return (
      <Modal className="AccountEdit" size="lg" show={this.props.showAccountEditModal} onHide={this.handleClose} >
        <Modal.Header closeButton >
          <Modal.Title>
            <h5 className="account-edit-title">{this.props.isNew ? 'New Account' : selAcct.name}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="top">
            <Row className="tools-wrap d-flex justify-content-end">
            </Row>
            <Row className="account-edit-panel">
              <Col className="account-edit-fields">
                <Table size="sm">
                  <tbody>
                    <tr>
                      <td className="field-label">Name</td>
                      <td colSpan="3">
                        <Form.Control required size="sm" type="text" placeholder="Name" value={selAcct.name} onChange={(e) => this.handleOnChange(e, 'name')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Brand</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Brand" value={selAcct.brand} onChange={(e) => this.handleOnChange(e, 'brand')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Industry</td>
                      <td colSpan="3">
                        <Form.Control size="sm" as="select" value={selAcct.industry} onChange={(e) => this.handleOnChange(e, 'industry')} >
                          {industries.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Status</td>
                      <td colSpan="3">
                        <Form.Control size="sm" as="select" value={selAcct.status} onChange={(e) => this.handleOnChange(e, 'status')} >
                          {statuses.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Office Phone</td>
                      <td>
                        <Form.Control size="sm" type="text" placeholder="Office Phone" value={selAcct.officePhone} onChange={(e) => this.handleOnChange(e, 'officePhone')} />
                      </td>
                      <td className="field-label">Office Fax</td>
                      <td>
                        <Form.Control size="sm" type="text" placeholder="Office Fax" value={selAcct.officeFax} onChange={(e) => this.handleOnChange(e, 'officeFax')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Website</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Website" value={selAcct.website} onChange={(e) => this.handleOnChange(e, 'website')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Shipping Address</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Street 1" value={selAcct.shippingAddressStreet1} onChange={(e) => this.handleOnChange(e, 'shippingAddressStreet1')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="Street 2" value={selAcct.shippingAddressStreet2} onChange={(e) => this.handleOnChange(e, 'shippingAddressStreet2')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="City" value={selAcct.shippingAddressCity} onChange={(e) => this.handleOnChange(e, 'shippingAddressCity')} />
                        <div className="spacer" />
                        <Row>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="State" value={selAcct.shippingAddressState} onChange={(e) => this.handleOnChange(e, 'shippingAddressState')} />
                          </Col>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="Postal Code" value={selAcct.shippingAddressPostalCode} onChange={(e) => this.handleOnChange(e, 'shippingAddressPostalCode')} />
                          </Col>
                        </Row>
                        <div className="spacer" />
                        <Form.Control size="sm" as="select" value={selAcct.shippingAddressCountry} onChange={(e) => this.handleOnChange(e, 'shippingAddressCountry')} >
                          {countries.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Description</td>
                      <td colSpan="3">
                        <Form.Control size="sm" as="textarea" rows="10" placeholder="Description" value={selAcct.description} onChange={(e) => this.handleOnChange(e, 'description')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Billing Address</td>
                      <td colSpan="3">
                        <Form.Control size="sm" type="text" placeholder="Street 1" value={selAcct.billingAddressStreet1} onChange={(e) => this.handleOnChange(e, 'billingAddressStreet1')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="Street 2" value={selAcct.billingAddressStreet2} onChange={(e) => this.handleOnChange(e, 'billingAddressStreet2')} />
                        <div className="spacer" />
                        <Form.Control size="sm" type="text" placeholder="City" value={selAcct.billingAddressCity} onChange={(e) => this.handleOnChange(e, 'billingAddressCity')} />
                        <div className="spacer" />
                        <Row>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="State" value={selAcct.billingAddressState} onChange={(e) => this.handleOnChange(e, 'billingAddressState')} />
                          </Col>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="Postal Code" value={selAcct.billingAddressPostalCode} onChange={(e) => this.handleOnChange(e, 'billingAddressPostalCode')} />
                          </Col>
                        </Row>
                        <div className="spacer" />
                        <Form.Control size="sm" as="select" value={selAcct.billingAddressCountry} onChange={(e) => this.handleOnChange(e, 'billingAddressCountry')} >
                          {countries.map((item, key) => {
                            return <option key={key} value={item}>{item}</option>
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="account-delete">
            {!this.props.isNew &&
              <Button variant="light" size="sm" onClick={this.handleAccountDeleteOnClick}>
                <FaRegTimesCircle /> Delete
              </Button>
            }
          </ButtonToolbar>
          <ButtonToolbar>
            <Button className="cancel-save" variant="secondary" size="sm" onClick={this.handleClose}>Cancel</Button>
            <Button className="cancel-save" variant="primary" size="sm" onClick={this.handleSave}>Save</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}


