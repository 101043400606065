import React, { Component } from "react";
import "./Home.css";
import logo from '../logo.svg';
import { Container, Row, Col }  from 'react-bootstrap';
import { 
  FaUserAstronaut, FaUserTie, FaUserNinja, FaToolbox, FaFileInvoiceDollar, 
  FaSuitcase, FaTruck, FaSeedling, FaLightbulb, FaUserLock, FaMoneyCheckAlt, 
  FaWarehouse, FaRoad, FaCalendar , FaGamepad
} from 'react-icons/fa';
import {
  GoDatabase
} from 'react-icons/go'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleCatOnClick = (e) => {
    this.props.history.push("/" + e.currentTarget.id);
  }
  render() {
    return (
      <div className="Home">
        <header className="App-header">
          <Container className="main-cats">
            <Row>
              <Col id="marketing" onClick={this.handleCatOnClick} className="cat"><FaUserAstronaut className="icon" /><div className="label">Marketing</div></Col>
              <Col id="sales" onClick={this.handleCatOnClick} className="cat"><FaUserTie className="icon" /><div className="label">Sales</div></Col>
              <Col id="invoicingar" className="cat" onClick={this.handleCatOnClick}><FaFileInvoiceDollar className="icon" /><div className="label">Invoicing</div></Col>
              <Col id="crm" className="cat" onClick={this.handleCatOnClick}><FaSuitcase className="icon" /><div className="label">CRM</div></Col>
            </Row>
            <Row>
              <Col id="purchasing" className="cat" onClick={this.handleCatOnClick}><FaUserLock className="icon" /><div className="label">Purchasing</div></Col>
              <Col id="calendar" className="cat" onClick={this.handleCatOnClick}><FaCalendar className="icon" /><div className="label">Calendar</div></Col>
              <Col id="products" className="cat" onClick={this.handleCatOnClick}><GoDatabase className="icon" /><div className="label">Products</div></Col>
              <Col id="cuatrocadmin" className="cat" onClick={this.handleCatOnClick}><FaGamepad className="icon" /><div className="label">Cuatro</div></Col>
            </Row>
            <Row>
              <Col className="cat disabled"><FaUserNinja className="icon" /><div className="label">Systems</div></Col>
              <Col className="cat disabled"><FaToolbox className="icon" /><div className="label">Grounds</div></Col>
              <Col className="cat disabled"><FaSeedling className="icon" /><div className="label">Projects</div></Col>
              <Col id="deliveries" className="cat disabled"><FaTruck className="icon" /><div className="label">Deliveries</div></Col>
            </Row>
            <Row>
              <Col className="cat disabled"><FaWarehouse className="icon" /><div className="label">Inventory</div></Col>
              <Col className="cat disabled"><FaLightbulb className="icon" /><div className="label">Ideas</div></Col>
              <Col id="traffic" className="cat" onClick={this.handleCatOnClick} ><FaRoad className="icon" /><div className="label">Traffic</div></Col>
              <Col className="cat disabled"><FaMoneyCheckAlt className="icon" /><div className="label">Pay</div></Col>
            </Row>
          </Container>
          <hr />
          <Container>
            <div className="logo-wrap">
              <img src={logo} className="App-logo" alt="logo" />
              <h1>Geedop Hub</h1>
            </div>
          </Container>
          <hr />
          <Container className="external-links">
            <Row>
              <Col><a className="App-link" href="http://www.geedop.com" target="_blank" rel="noopener noreferrer">geedop</a></Col>
              <Col><a className="App-link" href="http://industrial.geedop.com" target="_blank" rel="noopener noreferrer">industrial</a></Col>
              <Col><a className="App-link" href="http://geospatial.geedop.com" target="_blank" rel="noopener noreferrer">geospatial</a></Col>
              <Col><a className="App-link" href="http://www.cuatro.us" target="_blank" rel="noopener noreferrer">cuatro</a></Col>
              <Col><a className="App-link" href="https://ldp.geedop.com" target="_blank" rel="noopener noreferrer">ldp</a></Col>
            </Row>
          </Container>
        </header>
      </div>
    );
  }
}

export default Home;



