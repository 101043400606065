import React, { Component } from 'react';
import './App.css';
import Routes from "./Routes";
import { Button, Navbar } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import logo from './logo.svg';
import Cookies from "js-cookie";
import { FaDownload } from 'react-icons/fa'
import moment from 'moment'
import { xDateStrToUTCOdooString } from './components/XFunctions';

// const linkedInRedirect = 'http://localhost:3001/linkedin'
const linkedInRedirect = 'https://hub.geedop.com/linkedin'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userName: null,
      liCode: null,
      liErrorMessage: null,
      liAccessToken: null,
      liExpiresIn: null,
      liPicUrl0: null,
      liPicUrl1: null,
      liPicUrl2: null,
      liPicUrl3: null,
      monthSalesSum: '$0.00',
      leaderboardStats: [[0, "NA", 0, 0, 0]],
      version: null,
      showNewVersionButton: false
    };
  }
  executeOnTimer = () => {
    this.getAppVersion()
  }
  getAppVersion = async () => {
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'version'
      }
    }).then(response => response).catch(error => null)
    if (res && res.Item && res.Item.value) {
      let version = res.Item.value
      if (this.state.version && this.state.version !== version) {
        this.setState({ showNewVersionButton: true })
      } else {
        this.setState({ version })
      }
    }
  }
  handleNewVersionLoad = () => {
    window.location.reload(true)
  }
  async componentDidMount() {
    this.executeOnTimer()
    this.timer = setInterval(() => { this.executeOnTimer() }, 30000);
    let liCookie = Cookies.getJSON('linkedin')
    if (liCookie) {
      this.setState({ liAccessToken: liCookie.liAccessToken, liExpiresIn: liCookie.liExpiresIn }, () => {})
    }
    try {
      await Auth.currentSession();
      // Get the User ID and assign it to the state
      Auth.currentUserInfo().then(res => {
        this.setState({ userName: res.attributes.email })
        if (this.state.liAccessToken) {
          this.getLinkedInProfilePicture()
        }
      })
      this.userHasAuthenticated(true);
    }
    catch(e) {
      this.setState({ userName: null })
      if (e !== 'No current user') {
        alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }
  loadUserName = userName => {
    this.setState({ userName })
    this.getLinkedInProfilePicture()
  }
  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }
  UNSAFE_componentWillUnmount() {
    window.removeEventListener('message', this.receiveLinedInMessage, false);
    if (this.popup && !this.popup.closed) this.popup.close();
  }
  loginToLinkedIn = () => {
    const redirectUri = linkedInRedirect
    const clientId = '7780ms6p79d42c'
    const scope = 'rw_company_admin r_emailaddress w_share r_basicprofile r_liteprofile w_member_social'
    const state = ''
    const scopeParam = (scope) ? `&scope=${encodeURI(scope)}` : ''
    const linkedInAuthenLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${state}`
    this.popup = window.open(linkedInAuthenLink, '_blank', 'width=600,height=600');
    window.removeEventListener('message', this.receiveLinedInMessage, false);
    window.addEventListener('message', this.receiveLinedInMessage, false);
  }
  receiveLinedInMessage = (event) => {
    if (event.origin === window.location.origin) {
      if (event.data.errorMessage && event.data.from === 'Linked In') {
        this.handleLinkedInFailure(event.data);
        this.popup && this.popup.close();
      } else if (event.data.code && event.data.from === 'Linked In') {
        this.handleLinkedInSuccess({ code: event.data.code });
        this.popup && this.popup.close();
      }
    }
  }
  handleLinkedInFailure = (error) => {
    this.setState({ liCode: null, liErrorMessage: error.errorMessage })
  }
  handleLinkedInSuccess = async (data) => {
    this.setState({ liCode: data.code, liErrorMessage: null })
    let res = await API.post('base', 'litoken', {
      body: {type: 'litoken', code: data.code, redirect_uri: linkedInRedirect, client_id: '7780ms6p79d42c'}
    }).then(response => response).catch(error => null)
    if (res) {
      let liAccessToken = res.access_token
      let liExpiresIn = res.expires_in
      Cookies.set('linkedin', {liAccessToken: liAccessToken, liExpiresIn: liExpiresIn})
      this.setState({ liAccessToken, liExpiresIn }, () => {
        this.getLinkedInProfilePicture()
      })
    }
  }
  getLinkedInProfilePicture = async () => {
    let res = await API.post('base', 'litoken', {
      body: {type: 'liprofilepic', token: this.state.liAccessToken}
    }).then(response => response).catch(error => null)
    if (res) {
      this.setState({ liPicUrl0: res.picUrl0, liPicUrl1: res.picUrl1, liPicUrl2: res.picUrl2, liPicUrl3: res.picUrl3 })
    }
  }
  getOdooSalesStats = async () => {
    // const startOfMonth = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
    // const endOfMonth   = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
    // let res = await API.post('base', 'litoken', {
    //   body: {
    //     type: 'leaderboard', 
    //     frDate: xDateStrToUTCOdooString(startOfMonth + 'Z'),
    //     toDate: xDateStrToUTCOdooString(endOfMonth + 'Z'),
    //   }
    // }).then(response => response).catch(error => null)
    // if (res) {
    //   this.setState({ monthSalesSum: res.salesSum, leaderboardStats: res.stats })
    // }
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      loadUserName: this.loadUserName,
      getOdooSalesStats: this.getOdooSalesStats,
      monthSalesSum: this.state.monthSalesSum,
      leaderboardStats: this.state.leaderboardStats,
    };
    var userName = 'user'
    if (this.state.userName) {
      userName = this.state.userName.substring(0, this.state.userName.indexOf("@"))
    }
    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <Navbar>
          <Navbar.Brand><Link to="/"><img src={logo} className="icon logo" alt="logo" />Geedop Hub <span className="version">{this.state.version ? 'v' + String(this.state.version) : null}</span></Link></Navbar.Brand>
          <Navbar.Toggle />
          {this.state.showNewVersionButton &&
            <div className="new-version-reload"><Button size="sm" variant="warning" onClick={this.handleNewVersionLoad}><FaDownload /> Load new version</Button></div>
          }
          <Navbar.Collapse className="justify-content-end">
            {this.state.isAuthenticated
            ?
              <div className="user-menu-wrap">
                <Button className="user-menu" size="sm" variant="outline-light" onClick={this.handleLogout}>{'Log out: ' + userName}</Button>
              </div>
            :
              <div>
                {/*<Link to="/login">Log in</Link>*/}
              </div>
            }
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);
