export default {
  apiBase: {
    REGION: "us-east-1",
    URL: "https://vw9rznl0ld.execute-api.us-east-1.amazonaws.com/dev/"
  },
  apiCRMEmails: {
    REGION: "us-east-1",
    URL: "https://vw9rznl0ld.execute-api.us-east-1.amazonaws.com/dev/"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_dYadWX27a",
    APP_CLIENT_ID: "7s8abs8uolt06nmurmh37ndqaj",
    IDENTITY_POOL_ID: "us-east-1:14fe029c-d4da-4b44-afc2-6fe7a78db1aa"
  },
  storage: {
    REGION: "us-east-1",
    BUCKET: "geedop-hub-resources",
  },
};

// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username rnava@geedop.com \
//   --password Geedop2019!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username rnava@geedop.com


// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username laura.gonzalez@geedop.com \
//   --password Geedop2019!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username laura.gonzalez@geedop.com


// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username katia.benavidez@geedop.com \
//   --password Geedop2019!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username katia.benavidez@geedop.com


// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username jean.decastro@geedop.com \
//   --password Geedop2019!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username jean.decastro@geedop.com


// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username ricardo.nava@geedop.com \
//   --password Geedop2019!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username ricardo.nava@geedop.com

// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username dnava@geedop.com \
//   --password Geedop2019!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username dnava@geedop.com

// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username andrea.moreno@geedop.com \
//   --password Geedop2020!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username andrea.moreno@geedop.com

// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username laura.rangel@geedop.com \
//   --password Geedop2020!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username laura.rangel@geedop.com

// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username claudia.deleon@geedop.com \
//   --password Admin2021!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username claudia.deleon@geedop.com

// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 7s8abs8uolt06nmurmh37ndqaj \
//   --username alondra.terrazas@geedop.com \
//   --password Geedop2022!

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_dYadWX27a \
//   --username alondra.terrazas@geedop.com




