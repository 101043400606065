import React, { Component } from "react";
import "./CRM.css";
import { Container, Row, Col, Form, Button, Table, ListGroup, Modal, Tabs, Tab }  from 'react-bootstrap';
import TopDashboard from '../components/TopDashboard';
import { FaPlusCircle, FaEdit, FaArrowRight, FaSearch } from 'react-icons/fa';
import { IoMdCalendar } from 'react-icons/io'
import { API } from "aws-amplify";
import ContactDetails from '../components/ContactDetails';
import AccountEdit from '../components/AccountEdit';
import ContactEdit from '../components/ContactEdit';
import NoteEdit from '../components/NoteEdit';
import { xToLocalDate, xToTimestamp } from '../components/XFunctions';
import Autosuggest from 'react-autosuggest';

class CRM extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      searchResults: null,
      searchSuggestions: [],
      searchValue: '',
      searchTyping: false,
      searchTypingTimeout: 0,
      selectedAccount: null,
      isAccountSelected: false,
      searchIndex: 0,
      accountContacts: [],
      accountNotes: [],
      searchActive: false,
      selectedContact: null,
      selectedNote: null,
      showWorkingMsg: false,
      showAccountEditModal: false,
      showContactEditModal: false,
      showNoteEditModal: false,
      isNew: false,
      isContactNew: false,
      isNoteNew: false,
      emailConversations: [],
      accountIdToImport: '',
      accountReadyForImport: false,
      importAccountFindStatus: null,
      importAccountStatus: null,
      workingMessage: [],
      accountActivityTab: 'emails',
      c2LC: [],
      c2LCInd: [],
    }
  }
  doGetAccountByID = async (id) => {
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'getaccountbyid',
        params: {
          id: id
        }
      }
    }).then(response => response).catch(error => null)
    if (res.result && res.result.Item) {
      let selectedAccount = res.result.Item
      this.setState({ selectedAccount })
    }
  }
  doGetContactsByAccountID = async (id) => {
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'getcontactsbyaccountid',
        params: {
          id: id
        }
      }
    }).then(response => response).catch(error => null)
    if (res.result.Count > 0) {
      let accountContacts = res.result.Items
      accountContacts.sort((a, b) => (a.modifiedDateTime < b.modifiedDateTime) ? 1 : -1)
      this.setState({ accountContacts }, () => {
        accountContacts.forEach((aContact) => {
          if (aContact.primaryEmail) {
            this.doGetEmailsByEmailTo(aContact.primaryEmail.toLowerCase())
          }
        }) 
      })
    }    
  }
  doGetNotesByAccountID = async (id) => {
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'getnotesbyaccountid',
        params: {
          id: id
        }
      }
    }).then(response => response).catch(error => null)
    if (res.result.Count > 0) {
      let accountNotes = res.result.Items
      accountNotes.sort((a, b) => (xToTimestamp(a.modifiedDateTime) < xToTimestamp(b.modifiedDateTime)) ? 1 : -1)
      this.setState({ accountNotes })
    }    
  }
  handleAccountContactOnClick = (id) => {
    this.setState({ showWorkingMsg: true })
    this.doGetContactByID(id)
  }
  handleWorkingMsgOnHide = () => {
    this.setState({ workingMessage: [], showWorkingMsg: false })
  }
  doGetContactByID = async (id) => {
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'getcontactbyid',
        params: {
          id: id
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let selectedContact = res.result.Item
      let showContactDetailsModal = true
      let showWorkingMsg = false
      this.setState({ selectedContact, showWorkingMsg, showContactDetailsModal })
    }
  }
  handleContactDetailsModalClose = () => {
    this.setState({ showContactDetailsModal: false })
  }
  handleAccountEditOnClick = () => {
    this.setState({ isNew: false }, () => {
      this.setState({ showAccountEditModal: true  })
    })
  }
  handleAccountEditModalColse = () => {
    this.setState({ showAccountEditModal: false })
    if (this.state.isNew && (this.state.selectedAccount && !this.state.selectedAccount.id)) {
      this.setState({ selectedAccount: null, isNew: false })
    }
  }
  handleSaveAccount = async (account) => {
    this.setState({ showWorkingMsg: true })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: (this.state.isNew ? 'addaccount' : 'updateaccount'),
        params: {
          account: account
        }
      }
    }).then(response => response).catch(error => null)
    this.setState({ showWorkingMsg: false })
    if (res && res.result && res.result.Attributes) {
      let selectedAccount = res.result.Attributes
      this.setState({ selectedAccount, showAccountEditModal: false, isNew: false })
    } else if (res && res.result) {
      let selectedAccount = res.result
      this.setState({ selectedAccount, showAccountEditModal: false, isNew: false })
    }
  }
  handleAddAccount = () => {
    this.setState({ selectedAccount: null, isNew: true, accountContacts: [], accountNotes: [], emailConversations: [] }, () => {
      this.setState({ showAccountEditModal: true })
    })
  }
  handleContactEditTrigger = () => {
    this.setState({ isContactNew: false }, () => {
      this.setState({ showContactEditModal: true  })
    })
  }
  handleContactEditModalColse = () => {
    this.setState({ showContactEditModal: false })
    if (this.state.isContactNew && (this.state.selectedContact && !this.state.selectedContact.id)) {
      this.setState({ selectedContact: null, isContactNew: false })
    }
  }
  handleSaveContact = async (contact) => {
    this.setState({ showWorkingMsg: true })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: (this.state.isContactNew ? 'addcontact' : 'updatecontact'),
        params: { contact }
      }
    }).then(response => response).catch(error => null)
    this.setState({ showWorkingMsg: false })
    if (res && res.result && res.result.Attributes) {
      let selectedContact = res.result.Attributes
      this.setState({ selectedContact, showContactEditModal: false, isContactNew: false, emailConversations: [] }, () => {
        this.doGetContactsByAccountID(selectedContact.accountId)
      })
    } else if (res && res.result) {
      let selectedContact = res.result
      this.setState({ selectedContact, showContactEditModal: false, isContactNew: false, emailConversations: [] }, () => {
        this.doGetContactsByAccountID(selectedContact.accountId)
      })
    }
  }
  handleSaveNote = async (note) => {
    this.setState({ showWorkingMsg: true })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: (this.state.isNoteNew ? 'addnote' : 'updatenote'),
        params: { note }
      }
    }).then(response => response).catch(error => null)
    this.setState({ showWorkingMsg: false })
    if (res && res.result && res.result.Attributes) {
      let selectedNote = res.result.Attributes
      this.setState({ selectedNote, showNoteEditModal: false, isNotetNew: false, accountNotes: [] }, () => {
        this.doGetNotesByAccountID(selectedNote.accountId)
      })
    } else if (res && res.result) {
      let selectedNote = res.result
      this.setState({ selectedNote, showNoteEditModal: false, isNoteNew: false, accountNotes: [] }, () => {
        this.doGetNotesByAccountID(selectedNote.accountId)
      })
    }
  }
  handleAddContact = () => {
    this.setState({ selectedContact: null, isContactNew: true }, () => {
      this.setState({ showContactEditModal: true })
    })
  }
  handleAddNote = async (note) => {
    this.setState({ selectedNote: null, isNoteNew: true }, () => {
      this.setState({ showNoteEditModal: true })
    })
  }
  doGetEmailsByEmailTo = async (emailTo) => {
    let res = await API.post('crmEmails', 'queryemails', {
      body: {
        emailTo,
      }
    }).then(response => response).catch(error => null)
    let { result } = res
    if (result && result.Count && result.Count > 0) {
      let emailConversations = [...this.state.emailConversations, ...result.Items ]
      emailConversations.sort((a, b) => (a.date < b.date) ? 1 : -1)
      this.setState({ emailConversations }, () => {

      })
    }
  }
  toggleAccountHistoryEmailBody = (idx) => {
    let emailConversations = [...this.state.emailConversations]
    emailConversations[idx]['showmore'] = emailConversations[idx].showmore ? false : true
    this.setState({ emailConversations })
  }
  handleImportAccountNumberOnChange = (e) => {
    let accountIdToImport = e.target.value
    this.setState({ accountIdToImport, selectedAccount: null, accountReadyForImport: false })
  }
  handleImportAccountNextOnClick = () => {
    let { accountIdToImport } = this.state
    accountIdToImport = String(parseInt(accountIdToImport) + 1)
    this.setState({ workingMessage: [], showWorkingMsg: false, accountIdToImport, selectedAccount: null, accountReadyForImport: false }, () => {
      this.handleImportAccountOnClick()
    })
  }
  handleImportAccountOnClick = async () => {
    // ACCOUNT
    let importAccountFindStatus = [ 'Finding Zurmo account ...']
    this.setState({ importAccountFindStatus })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'importaccount',
        params: { accountId: this.state.accountIdToImport }
      }
    }).then(response => response).catch(error => null)
    if (res.result.error) {
      alert(res.result.error)
      console.log('[' + this.state.accountIdToImport + ']: ' + res.result.error)
      this.setState({ importAccountFindStatus: null })
      // this.handleImportAccountNextOnClick()
      return
    }
    let selectedAccount = res.result
    // Contacts
    importAccountFindStatus.push('Found', 'Searching for Contacts ...')
    this.setState({ importAccountFindStatus })
    let accountContacts = await this.importAccountGetContacts(selectedAccount.name)
    // Notes
    importAccountFindStatus.push(accountContacts.length + ' Contacts found', 'Searching for Notes ...')
    this.setState({ importAccountFindStatus })
    let accountNotes = await this.importAccountGetNotes()
    // Tasks
    importAccountFindStatus.push(accountNotes.length + ' Notes found', 'Searching for Tasks to converto to Notes ...')
    this.setState({ importAccountFindStatus })
    let accountTasks = await this.importAccountGetTasks()
    // Merge tasks with notes
    importAccountFindStatus.push(accountTasks.length + ' Tasks found', 'Merging Tasks with Notes ...')
    this.setState({ importAccountFindStatus })
    accountNotes.push(...accountTasks)
    // Order notes from recent to older
    importAccountFindStatus.push('Ordering Notes by date ...')
    this.setState({ importAccountFindStatus })
    accountNotes.sort((a, b) => (a.occurredOnDateTime < b.occurredOnDateTime) ? 1 : -1)
    // Update state
    this.setState({ selectedAccount, accountContacts, accountNotes, accountReadyForImport: true, importAccountFindStatus: null }, () => {
      // this.handleImportAccountSaveOnClick()
    })
  }
  importAccountGetContacts = async (selectedAccountName) => {
    // CONTACTS
    let contactsRes = await API.post('base', 'querycrm', {
      body: {
        op: 'importaccountgetcontacts',
        params: { accountId: this.state.accountIdToImport, accountName: selectedAccountName }
      }
    }).then(response => response).catch(error => null)
    // console.log('Zurmo contacts: ', contactsRes)
    return contactsRes.result
  }
  importAccountGetNotes = async (accountId) => {
    // NOTES
    let notesRes = await API.post('base', 'querycrm', {
      body: {
        op: 'importaccountgetnotes',
        params: { accountId: this.state.accountIdToImport }
      }
    }).then(response => response).catch(error => null)
    // console.log('Zurmo notes: ', notesRes)
    return notesRes.result
  }
  importAccountGetTasks = async (accountId) => {
    // TASKS
    let tasksRes = await API.post('base', 'querycrm', {
      body: {
        op: 'importaccountgettasks',
        params: { accountId: this.state.accountIdToImport }
      }
    }).then(response => response).catch(error => null)
    // console.log('Zurmo tasks: ', tasksRes)
    return tasksRes.result
  }
  handleImportAccountSaveOnClick = async () => {
    let workingMessage = []
    // Account save
    this.setState({ showWorkingMsg: true })
    let accountCall = await API.post('base', 'querycrm', {
      body: {
        op: 'addaccount',
        params: {
          account: this.state.selectedAccount,
          imported: true,
        }
      }
    }).then(response => response).catch(error => null)
    this.setState({ workingMessage })
    // Contacts save
    if (this.state.accountContacts.length > 0) {
      let gotContacts = 0
      for (const contact of this.state.accountContacts) {
        let contactCall = await API.post('base', 'querycrm', {
          body: {
            op: 'addcontact',
            params: {
              contact,
              imported: true,
            }
          }
        }).then(response => response).catch(error => null)
        let resId = contactCall.result.id
        gotContacts = gotContacts + 1
        workingMessage.push(resId)
        this.setState({ workingMessage })
      }
      workingMessage.push('Contacts (' + gotContacts + ')')
    }
    // Notes save
    if (this.state.accountNotes.length > 0) {
      let gotNotes = 0
      for (const note of this.state.accountNotes) {
        let noteCall = await API.post('base', 'querycrm', {
          body: {
            op: 'addnote',
            params: {
              note,
              imported: true,
            }
          }
        }).then(response => response).catch(error => null)
        let resId = noteCall.result.id
        gotNotes = gotNotes + 1
        workingMessage.push(resId)
        this.setState({ workingMessage })
      }
      workingMessage.push('Notes (' + gotNotes + ')')
    }
    workingMessage.push('--------------------')
    workingMessage.push(accountCall.result.name)
    this.setState({ selectedAccount: null, accountReadyForImport: false }, () => {
      console.log('[' + this.state.accountIdToImport + ']: Imported')
      // this.handleImportAccountNextOnClick()
    })
  }
  searchOnSuggestionsFetchRequested = async ({ value }) => {
    if (this.state.searchTypingTimeout) {
      clearTimeout(this.state.searchTypingTimeout)
    }
    this.setState({ 
      searchTyping: false,
      searchTypingTimeout: setTimeout(() => {
        this.searchGo(value)
      }, 700)
    });
  }
  searchGo = async (value) => {
    if (this.state.isAccountSelected) {
      return
    }
    this.setState({ searchActive: true })
    let res = await API.post('base', 'search', {
      body: {
        searchIndex: this.state.searchIndex,
        searchText: value
      }
    }).then(response => response).catch(error => null)
    this.setState({ searchActive: false })
    if (res && res.result && res.result.body) {
      const results = res.result.body.hits.hits.map(h => h._source)
      this.setState({ searchSuggestions: results })
    }
  }
  searchOnSuggestionsClearRequested = () => {
    this.setState({ searchSuggestions: [] })
  }
  searchRenderSuggestion = suggestion => {
    if (this.state.searchIndex === 0) {
      return (
        <div onClick={(e) => this.searchHitHandleOnClick(suggestion.id, null)} >
          <div className="hit-name">{suggestion.name}</div>
          {/*<div dangerouslySetInnerHTML={{__html: hlighttxt}} />*/}
        </div>
      )
    }
    if (this.state.searchIndex === 1) {
      return(
        <div onClick={(e) => this.searchHitHandleOnClick(suggestion.accountId, suggestion.id)} >
          <div className="hit-name">{suggestion.firstName + ' ' + suggestion.lastName}<span className="contact-account">{' | ' + suggestion.accountName}</span></div>
          {/*<div dangerouslySetInnerHTML={{__html: hlighttxt}} />*/}
        </div>
      )
    }
  }
  searchOnChange = (event, { newValue }) => {
    if (this.state.searchValue !== newValue) {
      this.clearAccountSelection()
      this.setState({ searchValue: newValue });
    }
  }
  searchHitHandleOnClick = (id, contactId) => {
    this.setState({ accountContacts: [], accountNotes: [], emailConversations: [], isAccountSelected: true }, () => {
      this.doGetAccountByID(id)
      this.doGetContactsByAccountID(id)
      this.doGetNotesByAccountID(id)
      if (this.state.searchIndex === 1) {
        this.doGetContactByID(contactId)
      }
    })
  }
  searchIndexHandleChange = (e) => {
    let isAccountSelected = false
    let selectedAccount = null
    let searchIndex = parseInt(e.target.value)
    let searchValue = ''
    let searchResults = null
    let searchSuggestions = []
    this.setState({ searchIndex, selectedAccount, searchValue, searchResults, isAccountSelected, searchSuggestions })
  }
  clearAccountSelection = () => {
    this.setState({ 
      searchSuggestions: [],
      isAccountSelected: false, selectedAccount: null, 
      accountContacts: [], accountNotes: [],
      emailConversations: [],
    })
  }
  handleActivityTabOnChange = (accountActivityTab) => {
    this.setState({ accountActivityTab })
  }
  searchGetSuggestionValue = (suggestion) => {
    // if (this.state.searchIndex === 0) {
    //   return suggestion.name
    // } else {
    //   return suggestion.firstName + ' ' + suggestion.lastName
    // }
    return this.state.searchValue
  }
  contactsToLowercase = async () => {
    let c2LC = []
    let res = await this.doC2LC(false)
    c2LC.push(...res.Items)
    if (res.LastEvaluatedKey) {
      while (true) {
        res = await this.doC2LC(res.LastEvaluatedKey)
        if (!res.LastEvaluatedKey) {
          c2LC.push(...res.Items)
          break
        } else {
          c2LC.push(...res.Items)
        }
      }
    }
    let lastIndex = c2LC.findIndex((item) => item.id === "cnctid_a03341d0-3dff-11ea-9fcc-e14bd7de3442")
    console.log('lastIndexL ', lastIndex)
    for (var i = lastIndex; i >= 0; i--) {
      let item = c2LC[i]
      let changeCall = await this.doC2LCEmail(item.id)
      let contact = changeCall.Attributes
      let c2LCInd = [...this.state.c2LCInd]
      c2LCInd.push(String(i) + ' - ' + contact.id + ' -- ' + contact.primaryEmail + ' -- ' + contact.secondaryEmail)
      this.setState({ c2LCInd })
      await new Promise(r => setTimeout(r, 1000));
    }
  }
  doC2LC = async (exclusiveStartKey) => {
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'contactemailstolowercase',
        params: {
          exclusiveStartKey
        }
      }
    }).then(response => response).catch(error => null)
    return res.result
  }
  doC2LCEmail = async (contactId) => {
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'updateemailtolowercase',
        params: {
          contactId 
        }
      }
    }).then(response => response).catch(error => null)
    return res.result
  }
  handleContactDeleteTrigger = async (contactId) => {
    var confirmation = window.confirm("Are you sure you want to delte contact?");
    if (!confirmation) {
      return
    }
    this.setState({ showWorkingMsg: true })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'deletecontact',
        params: { contactId }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result) {
      this.setState({ showWorkingMsg: false, showContactDetailsModal: false, emailConversations: [] }, () => {
        alert('Successfuly deleted contact.')
        this.doGetContactsByAccountID(this.state.selectedAccount.id)
      })
    } else {
      this.setState({ showWorkingMsg: false })
    }
  }
  handleAccountNoteOnClick = async (noteId) => {
    this.setState({ showWorkingMsg: true })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'getnotebyid',
        params: {
          id: noteId
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let selectedNote = res.result.Item
      this.setState({ selectedNote, isNoteNew: false, showWorkingMsg: false }, () => {
        this.setState({ showNoteEditModal: true })
      })
    }
  }
  handleNoteEditModalColse = () => {
    this.setState({ showNoteEditModal: false })
    if (this.state.isNoteNew && (this.state.selectedNote && !this.state.selectedNote.id)) {
      this.setState({ selectedNote: null, isNoteNew: false })
    }
  }
  handleNoteDeleteTrigger = async (noteId) => {
    var confirmation = window.confirm("Are you sure you want to delte note?");
    if (!confirmation) {
      return
    }
    this.setState({ showWorkingMsg: true })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'deletenote',
        params: { noteId }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result) {
      this.setState({ showWorkingMsg: false, showNoteEditModal: false, accountNotes: [] }, () => {
        alert('Successfuly deleted note.')
        this.doGetNotesByAccountID(this.state.selectedAccount.id)
      })
    } else {
      this.setState({ showWorkingMsg: false })
    }
  }
  handleAccountDeleteTrigger = async (accountId) => {
    var confirmation = window.confirm("WARNING!\nAre you sure you want to delte the account?\n\nThis operation cannot be undone!");
    if (!confirmation) {
      return
    }
    this.setState({ showWorkingMsg: true })
    let res = await API.post('base', 'querycrm', {
      body: {
        op: 'deleteaccount',
        params: { accountId }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result) {
      let message = res.result
      this.setState({ showWorkingMsg: false, showAccountEditModal: false, selectedAccount: null, accountContacts: [], accountNotes: [], emailConversations: [] }, () => {
        alert('Successfuly deleted account.\nContacts deleted: ' + message.contactsDeleted + ' of ' + message.contactsFound + '\nNotes deleted: ' + message.notesDeleted + ' of ' + message.notesFound + '\nDeleted account:\n' + message.accountDeleted)
      })
    } else {
      this.setState({ showWorkingMsg: false })
    }
  }

  render() {
    let selAcct = this.state.selectedAccount
    return (
      <div className="CRM">
        <TopDashboard 
          {...this.props}
        />
        <Container fluid className="main">
          {/*{this.state.c2LC.map((item, idx) => (
            <p key={idx} className="update-email">{String(idx) + ' - ' + item.id}</p>
          ))}
          {this.state.c2LCInd.map((item, idx) => (
            <p key={idx} className="updated-email">{item}</p>
          ))}*/}
          <Row className="section-title">
            <Col sm="3">
              <Form>
                <Form.Group as={Row} controlId="formHoriz" className="search-index-wrap">
                  <Form.Label column sm={2} className="search-icon"><FaSearch /></Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" size="sm" value={this.state.searchIndex} onChange={this.searchIndexHandleChange}>
                      <option value={0} >Accounts</option>
                      <option value={1} >Contacts</option>
                    </Form.Control>
                  </Col>
                  <Col sm={2}>
                    <div className="search-active">
                      {this.state.searchActive ? <div className="spinner"></div> : null}
                    </div>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
            <Col sm="6">
              <Autosuggest
                suggestions={this.state.searchSuggestions}
                onSuggestionsFetchRequested={this.searchOnSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.searchOnSuggestionsClearRequested}
                getSuggestionValue={this.searchGetSuggestionValue}
                renderSuggestion={this.searchRenderSuggestion}
                inputProps={{ placeholder: this.state.searchIndex === 0 ? 'Account' : 'Contact' , value: this.state.searchValue, onChange: this.searchOnChange, className: "form-control form-control-sm autosuggest", autoComplete: "off"}}
                focusInputOnSuggestionClick={false}
              />
            </Col>
            <Col sm="3">
              <Row>
                <Col className="text-right">
                  <Button variant="primary" size="sm" onClick={this.handleAddAccount}>New Account</Button>
                  {/*<Button variant="secondary" size="sm" onClick={this.contactsToLowercase}>lowercase(contacts)</Button>*/}
                </Col>
                {/*<Col>
                  <InputGroup>
                    <Form.Control
                      className="import-acct-num"
                      placeholder="Acct #"
                      size="sm" 
                      type="text"
                      value={this.state.accountIdToImport}
                      onChange={this.handleImportAccountNumberOnChange}
                    />
                    <InputGroup.Append>
                      <Button variant="secondary" size="sm" onClick={this.handleImportAccountOnClick}>Find Zurmo</Button>
                      <Button variant="warning" size="sm" onClick={this.handleImportAccountNextOnClick}><FaAngleRight /></Button>
                    </InputGroup.Append>
                    {this.state.accountReadyForImport &&
                      <InputGroup.Append>
                        <Button variant="danger" size="sm" onClick={this.handleImportAccountSaveOnClick}>Save</Button>
                      </InputGroup.Append>
                    }
                  </InputGroup>
                </Col>*/}
              </Row>
            </Col>
          </Row>
          {this.state.importAccountFindStatus &&
            <Row className="text-center">
              <Col>
                {
                  this.state.importAccountFindStatus.map((status, idx) => {
                    return( <p key={idx}>{status}</p> )
                  })
                }
              </Col>
            </Row>
          }
          {selAcct &&
            <Row className="account-panel">
              <Col md="3">
                <Row className="account-contacts">
                  <Col>
                    <h5 className="contact section-header">{'Contacts (' + this.state.accountContacts.length + ')'}</h5>
                    <Button className="add-contact" variant="success" size="sm" onClick={this.handleAddContact}>
                      <FaPlusCircle /> Contact
                    </Button>
                    {(this.state.accountContacts.length > 0) &&
                      <ListGroup className="contact-list">
                        {this.state.accountContacts.map((item, idx) => {
                          return (
                            <ListGroup.Item key={idx} onClick={(e) => this.handleAccountContactOnClick(item.id, item.accountId)} >
                              <div><strong>{(item.firstName !== 'NULL_STRING' ? item.firstName : '') + ' ' + (item.lastName !== 'NULL_STRING' ? item.lastName : '')}</strong></div>
                              <div><i>{item.jobTitle && item.jobTitle !== 'NULL_STRING' ? item.jobTitle : ''}</i></div>
                              <div>{(item.primaryEmail && item.primaryEmail !== 'NULL_STRING' ? item.primaryEmail : '')}</div>
                            </ListGroup.Item>
                          )
                        })}
                      </ListGroup>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md="4" className="account-fields">
                <div className="bordered-container">
                  <h5 className="section-header">{selAcct.name}</h5>
                  <Button className="account-edit" variant="light" size="sm" onClick={this.handleAccountEditOnClick}>
                    <FaEdit /> Edit
                  </Button>
                  <div className="metadata">
                    <p>{'Last Modified: ' + (selAcct.modifiedDateTime !== 'NULL_STRING' ? xToLocalDate(selAcct.modifiedDateTime) : '[unknown]') + ' by ' + selAcct.modifiedByUser.userName}</p>
                  <p>{'Created: ' + (selAcct.createdDateTime !== 'NULL_STRING' ? xToLocalDate(selAcct.createdDateTime) : '[unknown]') + ' by ' + selAcct.createdByUser.userName}</p> 
                  <p>{'Owner: ' + selAcct.ownerUser.userName}</p>
                  <p>{'Latest Activity: ' +  (selAcct.latestActivityDateTime !== 'NULL_STRING' ? xToLocalDate(selAcct.latestActivityDateTime) : '[unknown]')}</p>
                  </div>
                  <Table size="sm">
                    <tbody>
                      <tr>
                        <td className="field-label">Name</td>
                        <td colSpan="3">{selAcct.name}</td>
                      </tr>
                      <tr>
                        <td className="field-label">Brand</td>
                        <td colSpan="3">{((selAcct.brand && selAcct.brand !== 'NULL_STRING') ? selAcct.brand : '')}</td>
                      </tr>
                      <tr>
                        <td className="field-label">Industry</td>
                        <td colSpan="3">{((selAcct.industry && selAcct.industry !== 'NULL_STRING') ? selAcct.industry : '')}</td>
                      </tr>
                      <tr>
                        <td className="field-label">Status</td>
                        <td colSpan="3">{((selAcct.status && selAcct.status !== 'NULL_STRING') ? selAcct.status : '')}</td>
                      </tr>
                      <tr>
                        <td className="field-label">Office Phone</td>
                        <td>{((selAcct.officePhone && selAcct.officePhone !== 'NULL_STRING') ? selAcct.officePhone : '')}</td>
                        <td className="field-label">Office Fax</td>
                        <td>{((selAcct.officeFax && selAcct.officeFax !== 'NULL_STRING') ? selAcct.officeFax : '')}</td>
                      </tr>
                      <tr>
                        <td className="field-label">Website</td>
                        <td colSpan="3">{((selAcct.website && selAcct.website !== 'NULL_STRING') ? selAcct.website : '')}</td>
                      </tr>
                      <tr>
                        <td className="field-label">Shipping Address</td>
                        <td colSpan="3">
                          <div>{((selAcct.shippingAddressStreet1 && selAcct.shippingAddressStreet1 !== 'NULL_STRING') ? selAcct.shippingAddressStreet1 : '')}</div>
                          <div>{((selAcct.shippingAddressStreet2 && selAcct.shippingAddressStreet2 !== 'NULL_STRING') ? selAcct.shippingAddressStreet2 : '')}</div>
                          <div>{((selAcct.shippingAddressCity && selAcct.shippingAddressCity !== 'NULL_STRING') ? selAcct.shippingAddressCity : '') + ((selAcct.shippingAddressState && selAcct.shippingAddressState !== 'NULL_STRING') ? ', ' + selAcct.shippingAddressState : '') + ((selAcct.shippingAddressPostalCode && selAcct.shippingAddressPostalCode !== 'NULL_STRING') ? ', ' + selAcct.shippingAddressPostalCode : '')}</div>
                          <div>{((selAcct.shippingAddressCountry && selAcct.shippingAddressCountry !== 'NULL_STRING') ? selAcct.shippingAddressCountry : '')}</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Description</td>
                        <td colSpan="3">
                          {(selAcct.description && selAcct.description !== 'NULL_STRING') &&
                            <div dangerouslySetInnerHTML={{__html: selAcct.description.replace(/\r?\n/g, '<br />')}} />
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Billing Address</td>
                        <td colSpan="3">
                          <div>{((selAcct.billingAddressStreet1 && selAcct.billingAddressStreet1 !== 'NULL_STRING') ? selAcct.billingAddressStreet1 : '')}</div>
                          <div>{((selAcct.billingAddressStreet2 && selAcct.billingAddressStreet2 !== 'NULL_STRING') ? selAcct.billingAddressStreet2 : '')}</div>
                          <div>{((selAcct.billingAddressCity && selAcct.billingAddressCity !== 'NULL_STRING') ? selAcct.billingAddressCity : '') + ((selAcct.billingAddressState && selAcct.billingAddressState !== 'NULL_STRING') ? ', ' + selAcct.billingAddressState : '') + ((selAcct.billingAddressPostalCode && selAcct.billingAddressPostalCode !== 'NULL_STRING') ? ', ' + selAcct.billingAddressPostalCode : '')}</div>
                          <div>{((selAcct.billingAddressCountry && selAcct.billingAddressCountry !== 'NULL_STRING') ? selAcct.billingAddressCountry : '')}</div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md="5">
                <Tabs id="account-activity-tabs" activeKey={this.state.accountActivityTab} onSelect={this.handleActivityTabOnChange}>
                  <Tab eventKey="emails" title={"Emails (" + this.state.emailConversations.length  + ")"}>
                     <Row className="account-emails">
                      <Col>
                      {(this.state.emailConversations.length > 0) &&
                        <ListGroup className="account-history">
                          {this.state.emailConversations.map((item, idx) => {
                            let fromField = JSON.parse(item.from)
                            let toField = JSON.parse(item.to)
                            return (
                              <ListGroup.Item key={idx} >
                                <div className="metadata-fields">
                                  <div className="calendar-field">
                                    <IoMdCalendar />&nbsp;{xToLocalDate(item.date)}
                                  </div>
                                  <div className="from-field">
                                    <div className="fromEmail">{fromField.value[0].address}</div>
                                  </div>
                                  <div className="arrow-field">
                                    <FaArrowRight />
                                  </div>
                                  <div className="to-field">
                                    <div className="toEmail">{toField.value[0].address}</div>
                                  </div>
                                  <div className="subject-field">
                                    {item.subject}
                                  </div>
                                </div>
                                <div className={"body-field" + (item.showmore ? " show" : "")} onClick={(e) => this.toggleAccountHistoryEmailBody(idx)}>
                                  <div className="content" dangerouslySetInnerHTML={{__html: item.bodyTextAsHtml}} />
                                  <div className="showmore">Click to show more</div>
                                </div>
                              </ListGroup.Item>
                            )
                          })}
                        </ListGroup>
                      }
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="notes" title={"Notes (" + this.state.accountNotes.length  + ")"}>
                    <Row className="account-notes">
                      <Col>
                        <Button className="add-note" variant="success" size="sm" onClick={this.handleAddNote}>
                          <FaPlusCircle /> Note
                        </Button>
                        {(this.state.accountNotes.length > 0) &&
                          <ListGroup className="note-list">
                            {this.state.accountNotes.map((item, idx) => {
                              return (
                                <ListGroup.Item key={idx} onClick={(e) => this.handleAccountNoteOnClick(item.id, item.accountId)} >
                                  <div><strong>{xToLocalDate(item.modifiedDateTime)}</strong><span className="metadata">{'by ' + item.createdByUser.userName}</span></div>
                                  <div dangerouslySetInnerHTML={{__html: item.description.replace(/\r?\n/g, '<br />')}} />
                                </ListGroup.Item>
                              )
                            })}
                          </ListGroup>
                        }
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          }  {/*IF selected account*/}
        </Container>
        {this.state.selectedContact &&
          <ContactDetails 
            selectedContact={this.state.selectedContact} 
            showContactDetailsModal={this.state.showContactDetailsModal}
            triggerCloseModal={this.handleContactDetailsModalClose}
            triggerContactEdit={this.handleContactEditTrigger}
            triggerContactDelete={this.handleContactDeleteTrigger}
          />
        }
        {(this.state.showContactEditModal || this.state.isContactNew) &&
          <ContactEdit
            selectedContact={this.state.selectedContact}
            selectedAccount={this.state.selectedAccount}
            showContactEditModal={this.state.showContactEditModal}
            triggerCloseModal={this.handleContactEditModalColse}
            triggerSaveContact={this.handleSaveContact}
          />
        }
        {(this.state.showNoteEditModal || this.state.isNoteNew) &&
          <NoteEdit
            selectedNote={this.state.selectedNote}
            isNoteNew={this.state.isNoteNew}
            selectedAccount={this.state.selectedAccount}
            showNoteEditModal={this.state.showNoteEditModal}
            triggerCloseModal={this.handleNoteEditModalColse}
            triggerSaveNote={this.handleSaveNote}
            triggerNoteDelete={this.handleNoteDeleteTrigger}
          />
        }
        {(this.state.showAccountEditModal || this.state.isNew) &&
          <AccountEdit
            selectedAccount={this.state.selectedAccount}
            isNew={this.state.isNew}
            showAccountEditModal={this.state.showAccountEditModal}
            triggerCloseModal={this.handleAccountEditModalColse}
            triggerSaveAccount={this.handleSaveAccount}
            triggerAccountDelete={this.handleAccountDeleteTrigger}
          />
        }
        <Modal className="working-msg" size="md" show={this.state.showWorkingMsg} onHide={this.handleWorkingMsgOnHide} >
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <p>Please wait...</p>
            {this.state.workingMessage.map((val, idx) => {
              return(
                <p key={idx}>{val}</p>
              )
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="sm" onClick={this.handleWorkingMsgOnHide}>Dismiss</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CRM;



