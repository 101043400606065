import React, { Component } from "react";
import "./Sales.css";
import TopDashboard from '../components/TopDashboard';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Container, ButtonToolbar, ToggleButtonGroup,
         ToggleButton, ButtonGroup, Button, Modal, Form, InputGroup
       } from "react-bootstrap";
import { API } from "aws-amplify";
import moment from 'moment';
import { xDateStrToUTCOdooString, xFormatMoney, xToLocalDate } from '../components/XFunctions';
import { FaCheck, FaFilter, FaSync, 
         FaRegStickyNote, FaSearch
       } from 'react-icons/fa';
import SaleDetails from '../components/SaleDetails';

export default class Sales extends Component {
  constructor(props) {
    super(props)
    this.state = {
      salesList: [],
      salesFilter: 4,
      showSaleOrderModal: false,
      tableFilterShow: false,
      selectedSaleOrder: null,
      showWorkingMsg: false,
      sorderTotalCount: null,
      searchByNumVal: ''
    }
  }
  componentDidMount = () => {
    // this.getSales()
  }
  getColumnWidth = (data, accessor) => {
    const cellLength = Math.max(...data.map(row => {
      let value = row[accessor]
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value).width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 22, 50)
  }
  getColumnWidthArray = (data, accessor) => {
    const cellLength = Math.max(...data.map(row => {
      let value = row[accessor][0]
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value + '.(0).').width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 20, 50)
  }
  getSales = () => {
    if (this.state.salesFilter === 4) {
      this.getActiveSales()
    } else if (this.state.salesFilter === 5) {
      this.getOdooSalesByNum()
    } else {
      this.getOdooSales()
    }
  }
  getActiveSales = async () => {
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'activesalesorders', 
      }
    }).then(response => response).catch(error => null)
    if (res) {
      this.loadSalesIntoTable(res.sorders)
    }
  }
  getOdooSales = async () => {
    let startOfMonth, endOfMonth
    switch(this.state.salesFilter) {
      case 0:
        startOfMonth = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        break
      case 1:
        startOfMonth = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
        break
      case 2:
        startOfMonth = moment().subtract(1,'month').startOf('month').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().subtract(1,'month').endOf('month').format('YYYY-MM-DDTHH:mm:ss')
        break
      case 3:
        startOfMonth = moment().subtract(2,'months').startOf('month').format('YYYY-MM-DDTHH:mm:ss')
        endOfMonth   = moment().subtract(2,'months').endOf('month').format('YYYY-MM-DDTHH:mm:ss')
        break
      default:
    }
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'salesorders', 
        frDate: xDateStrToUTCOdooString(startOfMonth + 'Z'),
        toDate: xDateStrToUTCOdooString(endOfMonth + 'Z'),
      }
    }).then(response => response).catch(error => null)
    if (res) {
      // console.log(res)
      this.loadSalesIntoTable(res.sorders)
    }
  }
  getOdooSalesByNum = async () => {
    let res = await API.post('base', 'litoken', {
      body: {
        type: 'salesordersbynum', 
        number: this.state.searchByNumVal
      }
    }).then(response => response).catch(error => null)
    if (res) {
      this.loadSalesIntoTable(res.sorders)
    }
  }
  loadSalesIntoTable = (sorders) => {
    let salesList = []
    sorders.forEach(function(el) {
      let userSplit = el.user_id[1].split(' ')
      let userAbb = userSplit[0].charAt(0) + userSplit[1].charAt(0)
      let clientSp = el.partner_id[1].split(' ')
      let client = clientSp[0].replace(',', '')
      let po = ''
      if (el.client_order_ref) {
        po = el.client_order_ref
          .replace('PO','')
          .replace('Number:', '')
          .replace('Number', '')
          .replace('number:', '')
          .replace('number', '')
          .replace('NUMBER:', '')
          .replace('NUMBER', '')
          .replace('Purchase', '')
          .replace('Order', '')
          .replace('No.', '').trim()
      }
      let slen = el.shipments.length
      let nozero = slen > 0
      // let stli = slen > 0 ? slen - 1 : 0
      let stli = 0
      if (nozero) {
        el.shipments.forEach((ship, si) => {
          if (el.shipments[si].tracking === undefined) {
            el.shipments[si].tracking = []
          }
          if (el.shipments[si].invoices === undefined) {
            el.shipments[si].invoices = []
          }
        })
      } else {
        if (el.tracking === undefined) {
         el.tracking = []
        }
        if (el.invoices === undefined) {
          el.invoices = []
        }
      }
      salesList.push({
        id: el.id,
        confirmed: el.confirmation_date,
        poc: userAbb,
        number: el.display_name,
        amount: el.amount_total,
        currency: el.currency_id[1],
        client: client,
        client_full: el.partner_id[1],
        po: po,
        received_confirmation: el.received_confirmation,
        destination: el.partner_shipping_id.city + ', ' + el.partner_shipping_id.state,
        company: el.company_id[1],
        notes: el.notes,
        shipments: el.shipments,
        ship_via: nozero ? el.shipments[stli].ship_via : 'N/A',
        ship_date: nozero ? el.shipments[stli].ship_date : '0000-00-00',
        ship_shipped: nozero ? el.shipments[stli].ship_shipped : true,
        tracking: nozero ? el.shipments[stli].tracking : ['N/A'],
        ship_confirmation: nozero ? el.shipments[stli].ship_confirmation : true,
        deliver_date: nozero ? el.shipments[stli].deliver_date : el.deliver_date,
        deliver_delivered: nozero ? el.shipments[stli].deliver_delivered : el.deliver_delivered,
        deliver_confirmation: nozero ? el.shipments[stli].deliver_confirmation : el.deliver_confirmation,
        invoices: nozero ? el.shipments[stli].invoices : el.invoices,
        invoice_confirmation: nozero ? el.shipments[stli].invoice_confirmation : el.invoice_confirmation,
      })
    })
    this.setState({ salesList, sorderTotalCount: salesList.length, showWorkingMsg: false })
  }
  handleFilterChange = (e) => {
    this.setState({ searchByNumVal: '', sorderTotalCount: null, salesFilter: e, salesList: [] }, () => {
      this.getSales()
    })
  }
  handleSaleOrderModalClose = () => {
    this.setState({ selectedSaleOrder: null, showSaleOrderModal: false })
  }
  handleTableFilterShowClick = (e) => {
    this.setState({ tableFilterShow: e.target.checked })
  }
  handleTableRefreshClick = () => {
    this.setState({ sorderTotalCount: null, salesList: [] }, () => {
      this.getSales()
    })
  }
  handleSearchByNumOnChange = (e) => {
    this.setState({ searchByNumVal: e.target.value })
  }
  handleSearchByNumOnClick = () => {
    this.setState({ sorderTotalCount: null, salesFilter: 5, salesList: [] }, () => {
      this.getSales()
    })
  }
  saveSalesOrder = async (order) => {
    this.setState({ showWorkingMsg: true })
    await API.post('base', 'litoken', {
      body: { 
        ...order,
        type: 'sordersave'
      }
    }).then(response => {
      this.getSales()
    }).catch(error => error)
  }
  checkIfReady = (rowInfo, column) => {
    if  (column && rowInfo) {
      switch (column.id) {
        case 'ship_via':
          if (!rowInfo.original.ship_via) {
            return 'notready'
          } 
          break
        case 'tracking':
          if (rowInfo.original.tracking.length === 0) {
            return 'notready'
          }
          break
        case 'ship_date':
          if ((!rowInfo.original.ship_shipped) || (!rowInfo.original.ship_shipped && rowInfo.original.ship_date)) {
            return 'notready'
          } 
          break
        case 'deliver_date':
          if ((!rowInfo.original.deliver_delivered) || (!rowInfo.original.deliver_delivered && rowInfo.original.deliver_date)) {
            return 'notready'
          } 
          break
        case 'received_confirmation':
          if (!rowInfo.original.received_confirmation) {
            return 'notready'
          }
          break
        case 'ship_confirmation':
          if (!rowInfo.original.ship_confirmation) {
            return 'notready'
          }
          break
        case 'invoice_confirmation':
          if (!rowInfo.original.invoice_confirmation) {
            return 'notready'
          }
          break
        case 'deliver_confirmation':
          if (!rowInfo.original.deliver_confirmation) {
            return 'notready'
          }
          break
        case 'invoices':
          if (rowInfo.original.invoices.length === 0) {
            return 'notready'
          }
          break
        default:
      }
    }
    return null
  }

  render() {
    const columns = [
      {
        columns: [
          {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: (row) => {
              return row.index + 1
            },
            style: { textAlign: 'right', backgroundColor: '#e4e4e4' },
            width: 34,
          },
          {
            Header: "Entered",
            accessor: "confirmed",
            style: { textAlign: 'left', fontFamily: 'Monospace' },
            width: 145,
            Cell: props => xToLocalDate(props.value).replace(/\s/g, ''),
            filterable: false,
          },
          {
            Header: "POC",
            accessor: "poc",
            style: { textAlign: 'center', fontFamily: 'Monospace' },
            width: 36
          },
          {
            Header: "Sales Number",
            accessor: "number",
            style: { textAlign: 'center', fontFamily: 'Monospace' },
            width: this.getColumnWidth(this.state.salesList, 'number')
          },
          {
            Header: "Client",
            accessor: "client",
            width: this.getColumnWidth(this.state.salesList, 'client')
          },
          {
            Header: "Amount",
            accessor: "amount",
            width: 74,
            style: { textAlign: 'right' },
            Cell: props => xFormatMoney(props.value)
          },
          {
            Header: "PO Number",
            accessor: "po",
            style: { textAlign: 'center' },
            width: this.getColumnWidth(this.state.salesList, 'po'),
          },
          {
            Header: "Destination",
            accessor: "destination",
            width: this.getColumnWidth(this.state.salesList, 'destination')
          },
          {
            Header: "Shipping Carrier",
            accessor: "ship_via",
            width: this.getColumnWidth(this.state.salesList, 'ship_via'),
          },
          {
            Header: "Tracking IDs",
            accessor: "tracking",
            width: this.getColumnWidthArray(this.state.salesList, 'tracking'),
             Cell: (props) => {
              switch(props.value.length) {
                case 0:
                  return null
                default:
                  return <div><span className="multi-count" >{'(' + String(props.value.length) + ') '}</span>{String(props.value[0])}</div>
              }
            }
          },
          {
            Header: "Ship Date",
            accessor: "ship_date",
            style: { textAlign: 'center' },
            width: this.getColumnWidth(this.state.salesList, 'ship_date'),
            filterable: false,
          },
          {
            Header: "Delivered On",
            accessor: "deliver_date",
            width: this.getColumnWidth(this.state.salesList, 'deliver_date'),
            style: { textAlign: 'center' },
            filterable: false,
          },
          {
            Header: "Received Confirm",
            accessor: "received_confirmation",
            width: 65,
            style: { textAlign: 'center' },
            filterable: false,
            Cell: props => (props.value ? <FaCheck /> : null ),

          },
          {
            Header: "Shipped Confirm",
            accessor: "ship_confirmation",
            style: { textAlign: 'center' },
            width: 60,
            Cell: props => (props.value ? <FaCheck /> : null ),
            filterable: false,
          },
          {
            Header: "Invoice Confirm",
            accessor: "invoice_confirmation",
            style: { textAlign: 'center' },
            width: 60,
            Cell: props => (props.value ? <FaCheck /> : null ),
            filterable: false,
          },
          {
            Header: "Delivered Confirm",
            accessor: "deliver_confirmation",
            style: { textAlign: 'center' },
            width: 70,
            Cell: props => (props.value ? <FaCheck /> : null ),
            filterable: false,
          },
          {
            Header: "Invoice Numbers",
            accessor: "invoices",
            width: this.getColumnWidthArray(this.state.salesList, 'invoices'),
            Cell: (props) => {
              switch(props.value.length) {
                case 0:
                  return null
                default:
                  return <div><span className="multi-count" >{'(' + String(props.value.length) + ') '}</span>{String(props.value[0])}</div>
              }
            }
          },
          {
            Header: "Notes",
            accessor: "notes",
            style: { textAlign: 'center' },
            width: 40,
            Cell: props => (props.value ? <span className="notes"><FaRegStickyNote /></span> : '' ),
            filterable: false,
          },
        ]
      },
    ]
    const { salesFilter, tableFilterShow, salesList, showSaleOrderModal, selectedSaleOrder } = this.state
    return (
      <div className="Sales">
        <TopDashboard 
          {...this.props}
        />
        <Container fluid={true} className="sales-ribbon">
          <div className="sales-filters">
            <h4 className="section-title">Sales Orders</h4><div className="sorder-total-count">{this.state.sorderTotalCount !== null ? this.state.sorderTotalCount : <div className="spinner"></div>}</div>
            <ButtonToolbar className="filter-toolbar">
              <ToggleButtonGroup size="sm" type="radio" name="options" value={salesFilter} onChange={this.handleFilterChange}>
                <ToggleButton value={4} variant="light">Active</ToggleButton>
                <ToggleButton value={0} variant="light">Today</ToggleButton>
                <ToggleButton value={1} variant="light">This month</ToggleButton>
                <ToggleButton value={2} variant="light">{moment().subtract(1,'month').format('MMMM')}</ToggleButton>
                <ToggleButton value={3} variant="light">{moment().subtract(2,'month').format('MMMM')}</ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
            <Form className="search-by-num">
              <Form.Group controlId="searchByNumber" className="">
                <InputGroup className="">
                  <Form.Control placeholder="Sales/PO number" onChange={this.handleSearchByNumOnChange} size="sm" value={this.state.searchByNumVal} />
                  <InputGroup.Append>
                    <Button onClick={this.handleSearchByNumOnClick} size="sm" variant="light"><FaSearch /></Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
            <Button className="table-refresh" size="sm" variant="light" onClick={this.handleTableRefreshClick}><FaSync /></Button>
            <ButtonGroup toggle className="table-filter-show" >
              <ToggleButton 
                type="checkbox" 
                size="sm" 
                variant="light" 
                checked={tableFilterShow}
                onChange={this.handleTableFilterShowClick}><FaFilter /></ToggleButton>
            </ButtonGroup>
          </div>
        </Container>
        <ReactTable
          defaultSorted={[{id: 'confirmed', desc: true}]}
          pageSizeOptions={[50, 100]}
          data={salesList}
          columns={columns}
          defaultPageSize={100}
          className="-highlight"
          filterable={tableFilterShow}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (rowInfo) {
                  this.setState({ selectedSaleOrder: rowInfo.original, showSaleOrderModal: true })
                }
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal();
                }
              },
              className: this.checkIfReady(rowInfo, column)
            };
          }}
        />
        {selectedSaleOrder &&
          <SaleDetails 
            selectedSaleOrder={JSON.parse(JSON.stringify(selectedSaleOrder))} 
            showSaleOrderModal={showSaleOrderModal}
            triggerCloseModal={this.handleSaleOrderModalClose}
            saveSalesOrder={this.saveSalesOrder}
          />
        }
        <Modal className="working-msg" size="sm" show={this.state.showWorkingMsg} >
          <Modal.Header></Modal.Header>
          <Modal.Body>Please wait...</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }
}




































