import moment from 'moment';

export function xGenerateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

export function xDateStrToUTCOdooString(dateStr) {
  let date = new Date(dateStr)
  let dateUTC = new Date( date.getTime() + ( date.getTimezoneOffset() * 60000 ) )
  return dateUTC.getUTCFullYear() +
      '-' + xZeroPad(dateUTC.getUTCMonth() + 1) +
      '-' + xZeroPad(dateUTC.getUTCDate()) +
      ' ' + xZeroPad(dateUTC.getUTCHours()) +
      ':' + xZeroPad(dateUTC.getUTCMinutes()) +
      ':' + xZeroPad(dateUTC.getUTCSeconds())
}

export function xZeroPad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}

export function xFormatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
    return '0.00';
  }
}
export function xToLocalDate(utcStr) {
  return moment.utc(utcStr).local().format('YYYY-MM-DD, hh:mm a')
}
export function xToYYYYMMDD(str) {
  return moment(str).format('YYYY-MM-DD')
}

export function xDateStringIsValid(str) {
  return moment(str).isValid()
}

export function xToTimestamp(str) {
  return moment(str).valueOf()
}

export const xShippingCarriers = [
  'TresGuerras', 
  'A2B Express', 
  'FedEx Ground', 
  'FedEx Express', 
  'UPS Ground', 
  'UPS Express', 
  'DHL'
]

export const xProductCategories = [
  "",
  "Test",
  "Industrial",
  "Sigilight",
  "Cuatro",
  "Nrab"
]
