import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Modal, DropdownButton, Dropdown }  from 'react-bootstrap';
import "./Products.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { API } from "aws-amplify";
import { xFormatMoney, xToLocalDate  } from '../components/XFunctions';
import ProductEdit from '../components/ProductEdit';
// import Autosuggest from 'react-autosuggest';

var productsTempCount = 0
var productsTemp = []

export default class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      searchResults: null,
      searchSuggestions: [],
      searchValue: '',
      searchTyping: false,
      searchTypingTimeout: 0,
      searchIndex: 0,
      selectedProduct: null,
      isNewProduct: false,
      showProductEditModal: false,
      products: [],
      productCount: 0,
      showInventory: false,
      showArchived: false,
      showSpanish: false,
      showShipping: false,
      showVendor: false,
      showMetadata: false
    }
  }
  componentDidMount = async () => {
    this.updateProducts()
  }
  getColumnWidth = (data, accessor) => {
    const cellLength = Math.max(...data.map(row => {
      let value = row[accessor]
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value).width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 22, 50)
  }
  getColumnWidthProp = (data, accessor, prop) => {
    const cellLength = Math.max(...data.map(row => {
      let value = row[accessor][prop]
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value).width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 22, 50)
  }
  getColumnWidthArray = (data, accessor) => {
    const cellLength = Math.max(...data.map(row => {
      let value = row[accessor][0]
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value + '.(0).').width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 20, 50)
  }
  getColumnWidthArrayProp = (data, accessor, prop) => {
    const cellLength = Math.max(...data.map(row => {
      if (row[accessor].length < 1) {
        return 0
      }
      let value = row[accessor][0][prop]
      if (typeof value === 'string' || value instanceof String) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";        
        var width = ctx.measureText(value + '.(0).').width;
        return width
      } else {
        return 0
      }
    }))
    return Math.max(cellLength + 20, 50)
  }
  getProductsByCat = async (cat) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getproductsbycat',
        params: {
          cat: cat
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let products = res.result.Items
      return products
    } else {
      return false
    }
  }
  getProductsAll = async () => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getproductsall',
        params: {}
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Items) {
      let products = res.result.Items
      return products
    } else {
      return false
    }
  }
  updateProducts = async () => {
    let { showArchived } = this.state
    let products = await this.getProductsAll()
    let productCount = products.length
    if (!showArchived) {
      products = products.filter((product) => {
        let productArchived = (typeof product.archived === 'undefined' || product.archived === false) ? false : true
        return productArchived === false
      })
      productCount = products.length
    }
    this.setState({ products, productCount, showWorkingMsg: false })
  }
  // getProductsAllNonArchived = async () => {
  //   let productsUnfiltered = await this.getProductsAll()
  //   let products = productsUnfiltered.filter((product) => {
  //     return product.archived !== true
  //   })
  // }
  getProductBySku = async (sku) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getproductbysku',
        params: {
          sku: sku
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let product = res.result.Item
      return product
    } else {
      return false
    }
  }
  getUniqueSequentialProductSku = async (id) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getuniqseqproductid',
        params: {
          id: id
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Attributes) {
      let { value } = res.result.Attributes
      return value
    } else {
      return false
    }
  }
  getVendorById = async (id) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getvendorbyid',
        params: {
          id: id
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let vendor = res.result.Item
      return vendor
    } else {
      return false
    }
  }
  searchIndexHandleChange = (e) => {
    let isProductSelected = false
    let selectedProduct = null
    let searchIndex = parseInt(e.target.value)
    let searchValue = ''
    let searchResults = null
    let searchSuggestions = []
    this.setState({ searchIndex, selectedProduct, searchValue, searchResults, isProductSelected, searchSuggestions })
  }
  handleAddProduct = () => {
    this.setState({ selectedProduct: null, isNewProduct: true }, () => {
      this.setState({ showProductEditModal: true })
    })
  }
  handleProductEditModalClose = () => {
    this.setState({ selectedProduct: null, isNewProduct: false, showProductEditModal: false })
  }
  handleSaveProduct = async (product) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'addproduct',
        params:{
          product
        }
      }
    }).then(response => response).catch(error => null)
    if (!(res && res.result)) {
      alert('There was a problem saving the product.')
    }
    this.setState({ showWorkingMsg: false })
    this.updateProducts()
  }
  checkForStatus = (rowInfo, column) => {
    if  (column && rowInfo) {
      if (rowInfo.original.archived) {
        return 'archived'
      }
    }
    return null
  }
  handleOnChange = (e, id, reload) => {
    this.setState({ showWorkingMsg: reload })
    let value = e.target.checked
    this.setState({ [id]: value }, () => {
      if (reload) {
        this.updateProducts()
      }
    })
  }
  handleShowInventoryOnChange = async (e, id) => {
    let value = e.target.checked
    this.setState({ [id]: value }, async () => {
      this.getProductInventoryAll(value)
    })
  }
  getProductInventoryAll = async (show) => {
    if (show) {
      this.setState({ showWorkingMsg: true })
      let products = JSON.parse(JSON.stringify(this.state.products))
      products.forEach(async (product) => {
        let sku = product.sku
        let inventory = await this.getProductInventory(sku)
        let qty =  inventory ? inventory.qty : '--'
        let index = products.findIndex(x => x.sku === sku)
        productsTempCount = productsTempCount + 1
        products[index].inventory = qty
        productsTemp = products
        if (productsTempCount === this.state.products.length) {
          productsTempCount = 0
          this.setState({ products: productsTemp, showWorkingMsg: false })
        }
      })
    }
  }
  getProductInventoryActive = async (sku) => {
    this.setState({ showWorkingMsg: true })
    let products = JSON.parse(JSON.stringify(this.state.products))
    let index = products.findIndex(x => x.sku === sku)
    let inventory = await this.getProductInventory(sku)
    let qty =  inventory ? inventory.qty : '--'
    products[index].inventory = qty
    this.setState({ products, showWorkingMsg: false })
  }
  getProductInventory = async (sku) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getproductinventory',
        params: {
          sku
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let inventory = res.result.Item
      return inventory
    } else {
      return false
    }
  }
  setProductInventory = async (sku, qty) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'setproductinventory',
        params: {
          sku, qty
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let inventory = res.result.Item
      return inventory
    } else {
      return false
    }
  }
  handleWorkingMsgOnHide = () => {
    this.setState({ showWorkingMsg: false })
  }


  render() {
    const columns = [
      {
        columns: [
          {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: (row) => {
              return row.index + 1
            },
            style: { textAlign: 'right', backgroundColor: '#e4e4e4' },
            width: 34,
          },
        ]
      },
      {
        Header: "Img",
        accessor: "images",
        width: 39,
        style: { textAlign: 'center' },
        Cell: (props) => {
          switch(props.value.length) {
            case 0:
              return '0'
            default:
              return <div><span className="multi-count" >{'(' + String(props.value.length) + ') '}</span></div>
          }
        }
      },
      {
        Header: "SKU",
        accessor: "sku",
        style: { textAlign: 'center' },
        width: this.getColumnWidth(this.state.products, 'sku')
      },
      {
        Header: "Name (EN)",
        accessor: "nameen",
        style: { textAlign: 'left' },
        width: this.getColumnWidth(this.state.products, 'nameen')
      },
      {
        Header: "Nombre (SP)",
        accessor: "namesp",
        style: { textAlign: 'left' },
        width: this.getColumnWidth(this.state.products, 'namesp'),
        show: this.state.showSpanish
      },
      {
        Header: "Price",
        accessor: "price",
        style: { textAlign: 'right' },
        width: 74,
        Cell: props => xFormatMoney(props.value)        
      },
      {
        Header: "Unit Weight (lbs)",
        accessor: "unitweight",
        style: { textAlign: 'right' },
        width: this.getColumnWidth(this.state.products, 'unitweight'),
        show: this.state.showShipping
      },
      {
        Header: "Pkg. Unit Count",
        accessor: "pkgunitcount",
        style: { textAlign: 'center' },
        width: this.getColumnWidth(this.state.products, 'pkgunitcount'),
        show: this.state.showShipping
      },
      {
        Header: "Pkg. Weight (lbs)",
        accessor: "fullpkgweight",
        style: { textAlign: 'right' },
        width: this.getColumnWidth(this.state.products, 'fullpkgweight'),
        show: this.state.showShipping
      },
      {
        Header: "Pkg. SideA (in)",
        accessor: "pkgdimsidea",
        style: { textAlign: 'right' },
        width: this.getColumnWidth(this.state.products, 'pkgdimsidea'),
        show: this.state.showShipping
      },
      {
        Header: "Pkg. SideB (in)",
        accessor: "pkgdimsideb",
        style: { textAlign: 'right' },
        width: this.getColumnWidth(this.state.products, 'pkgdimsideb'),
        show: this.state.showShipping
      },
      {
        Header: "Pkg. Height (in)",
        accessor: "pkgdimheight",
        style: { textAlign: 'right' },
        width: this.getColumnWidth(this.state.products, 'pkgdimheight'),
        show: this.state.showShipping
      },
      {
        Header: "Vendors",
        accessor: "vendors",
        width: 60,
        style: { textAlign: 'center' },
        Cell: (props) => {
          return props.value.length
        },
        show: this.state.showVendor
      },
      {
        Header: "Vendor ID (Main)",
        accessor: "vendors",
        width: this.getColumnWidthArrayProp(this.state.products, 'vendors', 'id'),
        style: { textAlign: 'left' },
        Cell: (props) => {
          switch(props.value.length) {
            case 0:
              return ''
            default:
              return <div>{String(props.value[0].id)}</div>
          }
        },
        show: this.state.showVendor
      },
      {
        Header: "Vendor Code",
        accessor: "vendors",
        width: this.getColumnWidthArrayProp(this.state.products, 'vendors', 'productcode'),
        style: { textAlign: 'center' },
        Cell: (props) => {
          switch(props.value.length) {
            case 0:
              return ''
            default:
              return <div>{String(props.value[0].productcode)}</div>
          }
        },
        show: this.state.showVendor
      },
      {
        Header: "Vendor Price",
        accessor: "vendors",
        width: 74,
        style: { textAlign: 'right' },
        Cell: (props) => {
          switch(props.value.length) {
            case 0:
              return ''
            default:
              return <div>{String(props.value[0].productprice)}</div>
          }
        },
        show: this.state.showVendor
      },
      {
        Header: "Manuf. Code",
        accessor: "manufsku",
        style: { textAlign: 'center' },
        width: this.getColumnWidth(this.state.products, 'manufsku')
      },
      {
        Header: "Assy. Products",
        accessor: "assemblyproducts",
        width: 60,
        style: { textAlign: 'center' },
        Cell: (props) => {
          switch(props.value.length) {
            case 0:
              return '0'
            default:
              return <div><span className="multi-count" >{'(' + String(props.value.length) + ') '}</span></div>
          }
        }
      },
      {
        Header: "Inventory",
        accessor: "inventory",
        style: { textAlign: 'center' },
        width: 74,
        show: this.state.showInventory
      },
      {
        Header: "Created",
        accessor: "createddatetime",
        style: { textAlign: 'center' },
        width: 145,
        Cell: props => xToLocalDate(props.value),
        filterable: false,
        show: this.state.showMetadata
      },
      {
        Header: "Created By",
        accessor: "createdbyuser",
        width: this.getColumnWidthProp(this.state.products, 'createdbyuser', 'userName'),
        style: { textAlign: 'center' },
        Cell: props => props.value.userName,
        show: this.state.showMetadata
      },
      {
        Header: "Updated",
        accessor: "modifieddatetime",
        style: { textAlign: 'center' },
        width: 145,
        Cell: props => xToLocalDate(props.value),
        filterable: false,
        show: this.state.showMetadata
      },
      {
        Header: "Updated By",
        accessor: "modifiedbyuser",
        width: this.getColumnWidthProp(this.state.products, 'modifiedbyuser', 'userName'),
        style: { textAlign: 'center' },
        Cell: props => props.value.userName,
        show: this.state.showMetadata
      },
    ]
    return (
      <div className="Products">
        <Container fluid >
          <Row className="product-ribbon">
            <Col sm="3">
              <Form>
                <Form.Group as={Row} controlId="formHoriz" className="search-index-wrap">
                  {/*<Form.Label column sm={2} className="search-icon"><FaSearch /></Form.Label>*/}
                  <Col sm={6}>
                    {/*<Form.Control as="select" size="sm" value={this.state.searchIndex} onChange={this.searchIndexHandleChange}>
                      <option value={0} >Products</option>
                      <option value={1} >Consumables</option>
                    </Form.Control>*/}
                  </Col>
                  <Col sm={2}>
                    <div className="search-active">
                      {this.state.searchActive ? <div className="spinner"></div> : null}
                    </div>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
            <Col sm="6">
              {/*<Autosuggest
                suggestions={this.state.searchSuggestions}
                onSuggestionsFetchRequested={this.searchOnSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.searchOnSuggestionsClearRequested}
                getSuggestionValue={this.searchGetSuggestionValue}
                renderSuggestion={this.searchRenderSuggestion}
                inputProps={{ placeholder: this.state.searchIndex === 0 ? 'Product' : 'Consumable' , value: this.state.searchValue, onChange: this.searchOnChange, className: "form-control form-control-sm autosuggest", autoComplete: "off"}}
                focusInputOnSuggestionClick={false}
              />*/}
            </Col>
          </Row>
          <Row sm={3} className="product-filters">
            <Col>
              {this.state.searchIndex === 0 ?
                <div><h4 className="section-title">Products</h4><div className="total-count">{this.state.productCount}</div></div>
                :
                <div><h4 className="section-title">Consumables</h4><div className="total-count">{this.state.productCount}</div></div>
              }
              {/*<Form.Control size="sm" as="select" value={this.state.showCategory} onChange={(e) => this.handleOnChange(e, 'category')} >
                {xProductCategories.map((item, key) => {
                  return <option key={key} value={item}>{item}</option>
                })}
              </Form.Control>*/}
              {this.state.searchIndex === 0 ?
                <Button variant="primary" size="sm" className="newproduct" onClick={this.handleAddProduct}>New Product</Button>
                :
                <Button variant="primary" size="sm" onClick={this.handleAddConsumable}>New Consumable</Button>
              }
              <DropdownButton id="columns" title="Columns" size="sm" className="columns" variant="outline-secondary">
                <Dropdown.ItemText size="sm">
                  <Form.Check size="sm" 
                    type="switch"
                    id="inventory-switch"
                    label="Inventory"
                    checked={this.state.showInventory}
                    onChange={(e) => this.handleShowInventoryOnChange(e, 'showInventory')}
                  />
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                  <Form.Check 
                    type="switch"
                    id="spanish-switch"
                    label="Spanish"
                    checked={this.state.showSpanish}
                    onChange={(e) => this.handleOnChange(e, 'showSpanish')}
                  />
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                  <Form.Check 
                    type="switch"
                    id="shipping-switch"
                    label="Shipping"
                    checked={this.state.showShipping}
                    onChange={(e) => this.handleOnChange(e, 'showShipping')}
                  />
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                  <Form.Check 
                    type="switch"
                    id="vendor-switch"
                    label="Vendor"
                    checked={this.state.showVendor}
                    onChange={(e) => this.handleOnChange(e, 'showVendor')}
                  />
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                  <Form.Check 
                    type="switch"
                    id="metadata-switch"
                    label="Metadata"
                    checked={this.state.showMetadata}
                    onChange={(e) => this.handleOnChange(e, 'showMetadata')}
                  />
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                  <Form.Check 
                  type="switch"
                  id="archived-switch"
                  label="Archived"
                  checked={this.state.showArchived}
                  onChange={(e) => this.handleOnChange(e, 'showArchived', true)}
                />
                </Dropdown.ItemText>
              </DropdownButton>
            </Col>
          </Row>
        </Container>
        <ReactTable
          defaultSorted={[{id: 'sku', desc: false}]}
          pageSizeOptions={[50, 100]}
          data={this.state.products}
          columns={columns}
          defaultPageSize={100}
          className="-highlight"
          filterable={true}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (rowInfo) {
                  this.setState({ selectedProduct: rowInfo.original, showProductEditModal: true })
                }
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal();
                }
              },
              className: this.checkForStatus(rowInfo, column)
            };
          }}
        />
        {(this.state.showProductEditModal || this.state.isNewProduct) &&
          <ProductEdit
            selectedProduct={this.state.selectedProduct}
            isNew={this.state.isNewProduct}
            showEditModal={this.state.showProductEditModal}
            triggerCloseModal={this.handleProductEditModalClose}
            triggerSaveProduct={this.handleSaveProduct}
            getVendorById={this.getVendorById}
            getProductBySku={this.getProductBySku}
            getUniqueSequentialProductSku={this.getUniqueSequentialProductSku}
            showWorkingMsg={() => this.setState({ showWorkingMsg: true })}
            hideWorkingMsg={() => this.setState({ showWorkingMsg: false })}
            showInventory={this.state.showInventory}
            setProductInventory={this.setProductInventory}
            getProductInventoryActive={this.getProductInventoryActive}
          />
        }
        <Modal className="working-msg" size="sm" show={this.state.showWorkingMsg} onHide={this.handleWorkingMsgOnHide} >
          <Modal.Header></Modal.Header>
          <Modal.Body>Please wait...</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }
}











































