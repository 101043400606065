import React, { Component } from "react";
import "./Traffic.css";
import { Container, Row, Col }  from 'react-bootstrap';
import ReactPlayer from 'react-player'

class Traffic extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="Traffic">
        <Container fluid >
          <Row className="section-title">
            <Col><h4>Zaragoza Bridge</h4><div className="down-count">{''}</div></Col>
          </Row>
          <Row>
            <Col lg={6}>
              <ReactPlayer playing={true} width="100%" height="auto" url='https://zoocams.elpasozoo.org/BridgeZaragoza1.m3u8' />
            </Col>
            <Col lg={6}>
              <ReactPlayer playing={true} width="100%" height="auto" url='https://zoocams.elpasozoo.org/BridgeZaragoza2.m3u8' />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Traffic;



