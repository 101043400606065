/* global google */
import React, { Component } from "react";
import { Container, Row, Col, Button, Form }  from 'react-bootstrap';
import "./COVIDAdmin.css";
import { hospitals, grocery, pharmacies } from '../components/COVIDData'

const data = [
  {
    id: 'pharmacies',
    data: pharmacies
  },
  {
    id: 'hospitals',
    data: hospitals
  },
  {
    id: 'grocery',
    data: grocery
  }
]
const originLocations = [
  {
    id: 'ELP',
    originLat: 31.762042,
    originLng: -106.486996,
  },
  {
    id: 'JRZ',
    originLat: 31.691471,
    originLng: -106.426240,
  },
  {
    id: 'LCR',
    originLat: 32.320247,
    originLng: -106.773221,
  },
]

export default class COVIDAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      originLat: 31.762042,
      originLng: -106.486996,
      category: 'pharmacies',
      query: '',
      hasNextPage: false,
      currentPage: 0,
      pagination: null,
      placeIds: [],
      placeIndex: 0,
      places: [],
    }
  }
  componentDidMount() {
    let map = new google.maps.Map(document.createElement('div'));
    this.placesService = new google.maps.places.PlacesService(map);
  }
  handleSendOnClick = () => {
    let location = new google.maps.LatLng(parseFloat(this.state.originLat), parseFloat(this.state.originLng));
    let request = {
      location: location,
      radius: '4000',
      query: this.state.query
    };
    this.placesService.textSearch(request, (results, status, pagination) => {
      let currentPage = this.state.currentPage + 1
      this.setState({ currentPage })
      let placeIds = [...this.state.placeIds]
      if (results.length > 0) {
        for (var i = results.length - 1; i >= 0; i--) {
          placeIds.push(results[i].place_id)
        }
      }
      if (pagination.hasNextPage) {
        this.setState({ hasNextPage: true, pagination, placeIds })
      } else {
        this.setState({ hasNextPage: false, pagination: null, placeIds })
      }
    })
  }
  handleNextPageOnClick = () => {
    this.state.pagination.nextPage()
  }
  handleGetPlaceDetailsOnClick = async () => {
    let { placeIds, placeIndex } = this.state
    if (placeIndex + 1 <= placeIds.length) {
      let placeId = placeIds[placeIndex]
      let request = {
        placeId: placeId,
      };
      let places = [...this.state.places]
      // Check for duplicates
      let categoryObj = data.find(obj => {
        return obj.id === this.state.category
      })
      let catData = categoryObj.data
      let isDuplicate = catData.filter(obj => {
        return obj.id === placeId
      })
      if (isDuplicate.length === 0) {
        await this.placesService.getDetails(request, (place, status) => {
          let newPlace = {
            id: place.place_id,
            name: place.name,
            type: place.types[0],
            address: place.formatted_address,
            googlink: 'https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + place.place_id,
            coords: [place.geometry.location.lat(), place.geometry.location.lng()],
            phone: place.formatted_phone_number
          }
          places.push(newPlace)
          let placeIndex = this.state.placeIndex + 1
          this.setState({ placeIndex, places })
        })
      } else {
        console.log('DUPLICATE EXISTS: ', placeId)
      }
    }
  }
  handleQueryTextOnChange = (e) => {
    let query = e.target.value
    this.setState({ query })
  }
  handleOriginOnChange = (e) => {
    let originLocation = e.target.value
    var result = originLocations.find(obj => {
      return obj.id === originLocation
    })
    let { originLat, originLng } = result
    this.setState({ originLat, originLng })
  }
  handleCategoryOnChange = (e) => {
    let category = e.target.value
    this.setState({ category })
  }

  render() {
    return (
      <div className="COVIDAdmin">
        <Container >
          <Row>
            <Col className="mt-5">
              <Form>
                <Form.Group controlId="query">
                  <Form.Label>Origin Longitude</Form.Label>
                  <Form.Control as="select" onChange={this.handleOriginOnChange} defaultValue="ELP">
                    {originLocations.map((item, idx) => {
                      return <option key={idx} >{item.id}</option>
                    })}
                  </Form.Control>
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select" onChange={this.handleCategoryOnChange} defaultValue={this.state.category}>
                    {data.map((item, idx) => {
                      return <option key={idx} >{item.id}</option>
                    })}
                  </Form.Control>
                  <Form.Label>Google Maps Query</Form.Label>
                  <Form.Control type="text" placeholder="Enter query" onChange={this.handleQueryTextOnChange} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={this.handleSendOnClick} variant="dark" size="sm">Send</Button>
              <div>{this.state.currentPage}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.hasNextPage &&
                <Button onClick={this.handleNextPageOnClick} variant="dark" size="sm">Next</Button>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {(!this.state.hasNextPage && this.state.placeIds.length > 0) &&
                <Button onClick={this.handleGetPlaceDetailsOnClick} variant="dark" size="sm">{"Get Place Details (" + this.state.placeIndex + " of " + this.state.placeIds.length + ")" }</Button>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {JSON.stringify(this.state.places, null, 2)}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}