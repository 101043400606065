import React, { Component } from "react";
import "./SaleDetails.css";
import { Container, 
         Modal, Button, Form, Row, Col, Popover, OverlayTrigger, InputGroup,
         ListGroup, ButtonToolbar 
       } from "react-bootstrap";
import { xFormatMoney, xToLocalDate, 
         xToYYYYMMDD
       } from '../components/XFunctions';
import { FaExternalLinkAlt, FaRegCalendarAlt, 
         FaPlus, FaRegStickyNote, FaMinusCircle 
       } from 'react-icons/fa';
import { IoMdArrowRoundForward } from 'react-icons/io';
import mxflag from '../images/mx_3x4.svg';
import usflag from '../images/us_3x4.svg';
import DayPicker from "react-day-picker";
import 'react-day-picker/lib/style.css';
import moment from 'moment';

const initialState = {
  shipDateOnPicker: [undefined],
  shipDatePickerShow: true,
  deliverDateOnPicker: [undefined],
  deliverDatePickerShow: true,
  newTrackIDForShip: [''],
  newInvoiceNumForShip: [''],
  newInvoiceNumNoShip: [''],
  // Checkboxes require that an initial state is present to prevent "controlled" erros
  received_confirmation: false,
  deliver_delivered: false,
  deliver_confirmation: false
}

export default class Sales extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }
  componentDidMount = () => {
    // Add dates from multiple shipments to pickers
    let shipDateOnPicker = []
    let deliverDateOnPicker = []
    let shipments = this.props.selectedSaleOrder.shipments
    if (shipments.length > 0) {
      shipments.forEach((ship, i) => {
        if (ship.ship_date) {
          let sds = ship.ship_date.split('-')
          shipDateOnPicker[i] = new Date(sds[0], parseInt(sds[1]) - 1, sds[2])
        } else {
          shipDateOnPicker[i] = undefined
        }
        if (ship.deliver_date) {
          let dds = ship.deliver_date.split('-')
          deliverDateOnPicker[i] = new Date(dds[0], parseInt(dds[1]) - 1, dds[2])
        } else {
          deliverDateOnPicker[i] = undefined
        }
      })
    }
    // Set the state with the selected sales order passed on by props
    // and set the dates selected on date pickers
    this.setState({
      ...this.props.selectedSaleOrder,
      shipDateOnPicker,
      deliverDateOnPicker,
    })
  }
  handleClose = () => {
    this.props.triggerCloseModal()
  }
  handleShipDatePickerClick = (day, { disabled }, shipi, e) => {
    let shipDateOnPicker = [...this.state.shipDateOnPicker]
    shipDateOnPicker[shipi] = disabled ? undefined : day
    this.setState({ shipDateOnPicker })
    let date = moment(day)
    if (!disabled && date.isValid()) {
      let shipments = [...this.state.shipments]
      shipments[shipi].ship_date = xToYYYYMMDD(day)
      this.setState({ shipments  })
    }
  }
  handleShipDatePickerCloseClick = () => {
    this.setState({ shipDatePickerShow: false }, () => {
      this.setState({ shipDatePickerShow: true })
    })
  }
  handleDeliverDatePickerClick = (day, { disabled }, shipi, e) => {
    let deliverDateOnPicker = [...this.state.deliverDateOnPicker]
    deliverDateOnPicker[shipi] = disabled ? undefined : day
    this.setState({ deliverDateOnPicker })
    let date = moment(day)
    if (!disabled && date.isValid()) {
      let shipments = [...this.state.shipments]
      shipments[shipi].deliver_date = xToYYYYMMDD(day)
      this.setState({ shipments  })
    }
  }
  handleDeliverDateNoShipPickerClick = (day, { disabled }, e) => {
    let deliverDateOnPicker = [...this.state.deliverDateOnPicker]
    deliverDateOnPicker[0] = disabled ? undefined : day
    this.setState({ deliverDateOnPicker })
    let date = moment(day)
    if (!disabled && date.isValid()) {
      this.setState({ deliver_date: xToYYYYMMDD(day) })
    }
  }
  handleDeliverDatePickerCloseClick = () => {
    this.setState({ deliverDatePickerShow: false }, () => {
      this.setState({ deliverDatePickerShow: true })
    })
  }
  handleShipCarrierChange = (shipi, e) => {
    let shipments = [...this.state.shipments]
    shipments[shipi].ship_via = e.target.value
    this.setState({ shipments })
  }
  handleOrderConfirmOnChange = (e) => {
    this.setState({ received_confirmation: e.target.checked })
  }
  handelNotesOnChange = (e) => {
    this.setState({ notes: e.target.value })
  }
  handleShipFlowOnChange = (step, shipi, e) => {
    let shipments = [...this.state.shipments]
    shipments[shipi][step] = e.target.checked
    this.setState({ shipments })
  }
  handleInvoiceConfirmOnChange = (e) => {
    this.setState({ invoice_confirmation: e.target.checked })
  }
  handleDeliverDeliveredOnChange = (e) => {
    this.setState({ deliver_delivered: e.target.checked })
  }
  handleDeliverConfirmationOnChange = (e) => {
    this.setState({ deliver_confirmation: e.target.checked })
  }
  // Tracking IDs
  handleNewTrackIDForShipOnChange = (shipi, e) => {
    let newTrackIDForShip = [...this.state.newTrackIDForShip]
    newTrackIDForShip[shipi] = e.target.value
    this.setState({ newTrackIDForShip })
  }
  handleAddTrackIDToShipOnClick = (shipi, e) => {
    let newTrackID = this.state.newTrackIDForShip[shipi]
    if (newTrackID) {
      let shipments = [...this.state.shipments]
      shipments[shipi].tracking.unshift(newTrackID)
      this.setState({ shipments }, () => {
        this.handleNewTrackIDForShipOnChange(shipi, {target: {value: ''}})
      })
    }
  }
  handleDeleteTrackIDFromShipOnClick = (e, data) => {
    let shipments = [...this.state.shipments]
    shipments[data.shipi].tracking.splice(data.tracki, 1)
    this.setState({ shipments })
  }
  // Invoice Numbers
  handleNewInvoiceNumForShipOnChange = (shipi, e) => {
    let newInvoiceNumForShip = [...this.state.newInvoiceNumForShip]
    newInvoiceNumForShip[shipi] = e.target.value
    this.setState({ newInvoiceNumForShip })
  }
  handleNewInvoiceNumNoShipOnChange = (e) => {
    this.setState({ newInvoiceNumNoShip: e.target.value })
  }
  handleAddInvoiceNumToShipOnClick = (shipi, e) => {
    let newInvoiceNum = this.state.newInvoiceNumForShip[shipi]
    if (newInvoiceNum) {
      let shipments = [...this.state.shipments]
      shipments[shipi].invoices.unshift(newInvoiceNum)
      this.setState({ shipments }, () => {
        this.handleNewInvoiceNumForShipOnChange(shipi, {target: {value: ''}})
      })
    }
  }
  handleAddInvoiceNumOnClick = (e) => {
    let newInvoiceNum = this.state.newInvoiceNumNoShip
    if (newInvoiceNum) {
      let invoices = [...this.state.invoices]
      invoices.unshift(newInvoiceNum)
      this.setState({ invoices }, () => {
        this.handleNewInvoiceNumNoShipOnChange({ target: {value: ''}})
      })
    }
  }
  handleDeleteInvoiceNumFromShipOnClick = (e, data) => {
    let shipments = [...this.state.shipments]
    shipments[data.shipi].invoices.splice(data.invoicei, 1)
    this.setState({ shipments })
  }
  handleDeleteInvoiceNumOnClick = (e, data) => {
    let invoices = [...this.state.invoices]
    invoices.splice(data.invoicei, 1)
    this.setState({ invoices })
  }
  //
  handleDeleteShipDateFromShipOnClick = (e, data) => {
    let shipDateOnPicker = [...this.state.shipDateOnPicker]
    shipDateOnPicker[data.shipi] =  undefined
    this.setState({ shipDateOnPicker })
    let shipments = [...this.state.shipments]
    shipments[data.shipi].ship_date = null
    this.setState({ shipments  })
  }
  handleDeleteDeliverDateFromShipOnClick = (e, data) => {
    let deliverDateOnPicker = [...this.state.deliverDateOnPicker]
    deliverDateOnPicker[data.shipi] =  undefined
    this.setState({ deliverDateOnPicker })
    let shipments = [...this.state.shipments]
    shipments[data.shipi].deliver_date = null
    this.setState({ shipments  })
  }
  popSalesOrderToObject = () => {
    return {
      id: this.state.id,
      shipments: this.state.shipments,
      received_confirmation: this.state.received_confirmation,
      notes: this.state.notes,
      deliver_date: this.state.deliver_date,
      deliver_delivered: this.state.deliver_delivered,
      deliver_confirmation: this.state.deliver_confirmation,
      invoices: this.state.invoices,
      invoice_confirmation: this.state.invoice_confirmation,
      company: this.state.company
    }
  }
  saveOrder = () => {
    let order = this.popSalesOrderToObject()
    this.props.saveSalesOrder(order)
    this.handleClose()
  }





  render() {
    const { shipDatePickerShow, deliverDatePickerShow } = this.state;
    const { showSaleOrderModal } = this.props
    let deliverDateControlClasses = "deliverdate form-control form-control-sm" 
    return (
      <Modal className="sale-details" size="lg" show={showSaleOrderModal} onHide={this.handleClose} >
        <Modal.Header closeButton >
          <Modal.Title>
            {this.state.company === 'Ricardo Alfredo Nava Sáenz'
            ?
              <img src={mxflag} className="flag-icon" alt="MX" />
            :
              <img src={usflag} className="flag-icon" alt="US" />
            }
            {this.state.number}
            {this.state.company !== 'Cuatro LLC' &&
              <a className="external-link" href={"https://geedop.odoo.com/web?#id=" + this.state.id + "&view_type=form&model=sale.order&action=314"} target="_blank" rel="noopener noreferrer">Odoo <FaExternalLinkAlt /></a>
            }
          </Modal.Title>
          <div className="sale-overall-status">
            {/*<Badge variant="danger">PENDING</Badge>*/}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="poc-background">{this.state.poc}</div>
          <Container className="top">
            <Row>
              <Col xs={3} className="record name" >Customer:</Col>
              <Col xs={9} className="record val customer" >{this.state.client_full}</Col>
            </Row>
            <Row>
              <Col xs={3} className="record name" >Destination:</Col>
              <Col xs={9} className="record val" >{this.state.destination}</Col>
            </Row>
            <Row>
              <Col xs={3} className="record name po" >PO #</Col>
              <Col xs={9} className="record val po" >{this.state.po}
                <Form>
                  <Form.Group className={this.state.received_confirmation ? "order-confirmation make-info" : "order-confirmation"} controlId="orderConfirmation">
                    <Form.Check inline 
                      label="Received confirmation" 
                      type="checkbox" id="orderConfirm" 
                      onChange={this.handleOrderConfirmOnChange}
                      checked={this.state.received_confirmation}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={3} className="record name" >Entered:</Col>
              <Col xs={9} className="record val" >{xToLocalDate(this.state.confirmed)}</Col>
            </Row>
            <Row>
              <Col xs={3} className="record name" >Amount:</Col>
              <Col xs={9} className="record val amount" >{xFormatMoney(this.state.amount) + ' (' + this.state.currency + ')'}</Col>
            </Row>
            <Row>
              <Col xs={3} className="record name notes" ><FaRegStickyNote className={this.state.notes ? "enabled" : null} /></Col>
              <Col xs={9} className="record val notes" >
                <Form>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control 
                      className={this.state.notes ? "make-info" : null}
                      size="sm" as="textarea" rows="3" 
                      value={this.state.notes} onChange={this.handelNotesOnChange} 
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>

          {(this.state.shipments && this.state.shipments.length > 0) &&
            this.state.shipments.map((ship, si) => {
              let shipDateControlClasses = "shipdate form-control form-control-sm" 
              if (ship.ship_date) {
                shipDateControlClasses += " make-info"
              }
              if (ship.deliver_date) {
                deliverDateControlClasses += " make-info"
              }
              return(
                <Container key={si} className={"shipment -" + ship.id}>
                  <Row>
                  <Form.Label className="order-process-title">
                    {'Shipment ' + String(this.state.shipments.length - si) + ' '}
                    {this.state.company !== 'Cuatro LLC' &&
                      <a className="external-link" href={"https://geedop.odoo.com/web?#id=" + ship.id + "&view_type=form&model=stock.picking"} target="_blank" rel="noopener noreferrer"><FaExternalLinkAlt /></a>
                    }
                  </Form.Label>
                  <Form className="order-process-form">
                    <Form.Row>
                      <Form.Group className="order-process-confirmations" controlId="orderProcConfirmations">
                        <Form.Check 
                          className={ship.ship_shipped ? "make-info" : null}
                          inline
                          onChange={(e) => this.handleShipFlowOnChange('ship_shipped', si, e)} 
                          label="Shipped" 
                          type="checkbox" 
                          id={"shipShipped-" + si}
                          checked={ship.ship_shipped} />
                        <IoMdArrowRoundForward className="arrow" />
                        <Form.Check 
                          className={ship.ship_confirmation ? "make-info" : null} 
                          inline 
                          onChange={(e) => this.handleShipFlowOnChange('ship_confirmation', si, e)}
                          label="Shipped confirmation" 
                          type="checkbox" 
                          id={"shipComfirm-" + si} 
                          checked={ship.ship_confirmation} />
                        <IoMdArrowRoundForward className="arrow" />
                        <Form.Check 
                          className={ship.deliver_delivered ? "make-info" : null} 
                          inline 
                          onChange={(e) => this.handleShipFlowOnChange('deliver_delivered', si, e)} 
                          label="Delivered" 
                          type="checkbox" 
                          id={"deliverDelivered-" + si} 
                          checked={ship.deliver_delivered} />
                        <IoMdArrowRoundForward className="arrow" />
                        <Form.Check 
                          className={ship.deliver_confirmation ? "make-info" : null} 
                          inline 
                          onChange={(e) => this.handleShipFlowOnChange('deliver_confirmation', si, e)} 
                          label="Delivery confirmation" 
                          type="checkbox" 
                          id={"deliverConfirm-" + si} 
                          checked={ship.deliver_confirmation} />
                        <IoMdArrowRoundForward className="arrow" />
                        <Form.Check 
                          className={ship.invoice_confirmation ? "make-info" : null} 
                          inline 
                          onChange={(e) => this.handleShipFlowOnChange('invoice_confirmation', si, e)} 
                          label="Invoice(s) submitted" 
                          type="checkbox" 
                          id={"invoiceConfirm-" + si} 
                          checked={ship.invoice_confirmation} />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formShippingCarrier">
                        {!ship.ship_via ? <div className="pending-tag"></div> : null }
                        <Form.Label>Carrier:</Form.Label>
                        <Form.Control className={ship.ship_via ? "make-info" : null} onChange={(e) => this.handleShipCarrierChange(si, e)} size="sm" value={ship.ship_via} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formShippedDate">
                        {!ship.ship_date ? <div className="pending-tag"></div> : null }
                        <Form.Label>Ship date:</Form.Label>
                        <InputGroup className="">
                          <div className={shipDateControlClasses}>
                            {(ship.ship_date) ? ship.ship_date : ''}
                            {ship.ship_date &&
                              <div className="delete-shipdate" onClick={(e) => this.handleDeleteShipDateFromShipOnClick(e, { shipi: si })} ><FaMinusCircle /></div>
                            }
                          </div>
                          <InputGroup.Append>
                            {shipDatePickerShow
                              ? <OverlayTrigger trigger="click" overlay={
                                  <Popover className="ship-date-popover" title="Ship date">
                                    <button onClick={this.handleShipDatePickerCloseClick} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                                    <DayPicker 
                                      selectedDays={this.state.shipDateOnPicker[si]}
                                      onDayClick={(day, modifiers, e) => this.handleShipDatePickerClick(day, modifiers, si, e)}
                                      todayButton="Go to Today"
                                      modifiers={{
                                        disabled: { daysOfWeek: [0, 6] },
                                        shipi: { index: si }
                                      }}
                                    />
                                  </Popover>
                                }>
                                  <Button size="sm" variant="outline-secondary"><FaRegCalendarAlt /></Button>
                                </OverlayTrigger>
                              : <Button size="sm" variant="outline-secondary"><FaRegCalendarAlt /></Button>
                            }
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="none"></Form.Group>
                      <Form.Group as={Col} controlId="orderDeliveryDate">
                        {!ship.deliver_date ? <div className="pending-tag"></div> : null }
                        <Form.Label>Delivery date:</Form.Label>
                        <InputGroup className="">
                          <div className={deliverDateControlClasses}>
                            {(ship.deliver_date) ? ship.deliver_date : ''}
                            {ship.deliver_date &&
                              <div className="delete-deliverdate" onClick={(e) => this.handleDeleteDeliverDateFromShipOnClick(e, { shipi: si })} ><FaMinusCircle /></div>
                            }
                          </div>
                          <InputGroup.Append>
                            {deliverDatePickerShow
                              ? <OverlayTrigger trigger="click" overlay={
                                  <Popover className="deliver-date-popover" title="Delivery date">
                                    <button onClick={this.handleDeliverDatePickerCloseClick} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                                    <DayPicker 
                                      selectedDays={this.state.deliverDateOnPicker[si]}
                                      onDayClick={(day, modifiers, e) => this.handleDeliverDatePickerClick(day, modifiers, si, e)}
                                      todayButton="Go to Today"
                                      modifiers={{
                                        disabled: { daysOfWeek: [0, 6] },
                                      }}
                                    />
                                  </Popover>
                                }>
                                  <Button size="sm" variant="outline-secondary"><FaRegCalendarAlt /></Button>
                                </OverlayTrigger>
                              : <Button size="sm" variant="outline-secondary"><FaRegCalendarAlt /></Button>
                            }
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="orderTrackingIDs" className="tracking-group">
                        {(ship.tracking && !ship.tracking.length > 0) ? <div className="pending-tag"></div> : null }
                        <Form.Label>Tracking IDs:</Form.Label>
                        <InputGroup className="">
                          <Form.Control onChange={(e) => this.handleNewTrackIDForShipOnChange(si, e)} size="sm" value={this.state.newTrackIDForShip[si]} />
                          <InputGroup.Append>
                            <Button onClick={(e) => this.handleAddTrackIDToShipOnClick(si, e)} size="sm" variant="outline-secondary"><FaPlus /></Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <ListGroup>
                          {ship.tracking &&
                            ship.tracking.map((trackid, ti) => {
                              return(
                                <ListGroup.Item key={ti} variant="info" className="trackid">
                                  <span>{trackid}</span>
                                  <div className="delete-trackid" onClick={(e) => this.handleDeleteTrackIDFromShipOnClick(e, { shipi: si, tracki: ti})} ><FaMinusCircle /></div>
                                </ListGroup.Item>
                              )
                            })
                          }
                        </ListGroup>
                      </Form.Group>
                      <Form.Group as={Col} controlId="orderInvoices">
                        {(ship.invoices && !ship.invoices.length > 0) ? <div className="pending-tag"></div> : null }
                        <Form.Label>Invoice Numbers:</Form.Label>
                        <InputGroup className="">
                          <Form.Control onChange={(e) => this.handleNewInvoiceNumForShipOnChange(si, e)} size="sm" value={this.state.newInvoiceNumForShip[si]} />
                          <InputGroup.Append>
                            <Button onClick={(e) => this.handleAddInvoiceNumToShipOnClick(si, e)} size="sm" variant="outline-secondary"><FaPlus /></Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <ListGroup>
                          {ship.invoices &&
                            ship.invoices.map((invoicenum, ii) => {
                              return(
                                <ListGroup.Item key={ii} variant="info" className="invoicenum">
                                  <span>{invoicenum}</span>
                                  <div className="delete-invoicenum" onClick={(e) => this.handleDeleteInvoiceNumFromShipOnClick(e, { shipi: si, invoicei: ii})} ><FaMinusCircle /></div>
                                </ListGroup.Item>
                              )
                            })
                          }
                        </ListGroup>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  </Row>
                </Container>
              )
            })
          }
          {(!this.state.shipments || this.state.shipments.length === 0) &&
            <Container className={"shipment --1"}>
              <Row>
              <Form.Label className="order-process-title">
                {'Service Order'}
              </Form.Label>
              <Form className="order-process-form">
                <Form.Row>
                  <Form.Group className="order-process-confirmations" controlId="orderProcConfirmations">
                    <Form.Check 
                      className={this.state.deliver_delivered ? "make-info" : null} 
                      inline 
                      onChange={this.handleDeliverDeliveredOnChange} 
                      label="Delivered" 
                      type="checkbox" 
                      id={"deliverDelivered"} 
                      checked={this.state.deliver_delivered} />
                    <IoMdArrowRoundForward className="arrow" />
                    <Form.Check 
                      className={this.state.deliver_confirmation ? "make-info" : null} 
                      inline 
                      onChange={this.handleDeliverConfirmationOnChange} 
                      label="Delivered confirmation" 
                      type="checkbox" 
                      id={"deliverConfirmation"} 
                      checked={this.state.deliver_confirmation} />
                    <IoMdArrowRoundForward className="arrow" />
                    <Form.Check 
                      className={this.state.invoice_confirmation ? "make-info" : null} 
                      inline 
                      onChange={this.handleInvoiceConfirmOnChange} 
                      label="Invoice(s) submitted" 
                      type="checkbox" 
                      id={"invoiceConfirm"} 
                      checked={this.state.invoice_confirmation} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="orderDeliveryDate">
                    {!this.state.deliver_date ? <div className="pending-tag"></div> : null }
                    <Form.Label>Delivery date:</Form.Label>
                    <InputGroup className="">
                      <div className={this.state.deliver_date ? deliverDateControlClasses + " make-info" : deliverDateControlClasses}>
                        {(this.state.deliver_date) ? this.state.deliver_date : ''}
                        {this.state.deliver_date &&
                          <div className="delete-deliverdate" onClick={(e) => this.handleDeleteDeliverDateFromShipOnClick(e, { shipi: 0 })} ><FaMinusCircle /></div>
                        }
                      </div>
                      <InputGroup.Append>
                        {deliverDatePickerShow
                          ? <OverlayTrigger trigger="click" overlay={
                              <Popover className="deliver-date-popover" title="Delivery date">
                                <button onClick={this.handleDeliverDatePickerCloseClick} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                                <DayPicker 
                                  selectedDays={this.state.deliverDateOnPicker[0]}
                                  onDayClick={(day, modifiers, e) => this.handleDeliverDateNoShipPickerClick(day, modifiers, e)}
                                  todayButton="Go to Today"
                                  modifiers={{
                                    disabled: { daysOfWeek: [0, 6] },
                                  }}
                                />
                              </Popover>
                            }>
                              <Button size="sm" variant="outline-secondary"><FaRegCalendarAlt /></Button>
                            </OverlayTrigger>
                          : <Button size="sm" variant="outline-secondary"><FaRegCalendarAlt /></Button>
                        }
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} controlId="orderInvoices">
                    {(this.state.invoices && !this.state.invoices.length > 0) ? <div className="pending-tag"></div> : null }
                    <Form.Label>Invoice Numbers:</Form.Label>
                    <InputGroup className="">
                      <Form.Control onChange={this.handleNewInvoiceNumNoShipOnChange} size="sm" value={this.state.newInvoiceNumNoShip} />
                      <InputGroup.Append>
                        <Button onClick={this.handleAddInvoiceNumOnClick} size="sm" variant="outline-secondary"><FaPlus /></Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <ListGroup>
                      {this.state.invoices &&
                        this.state.invoices.map((invoicenum, ii) => {
                          return(
                            <ListGroup.Item key={ii} variant="info" className="invoicenum">
                              <span>{invoicenum}</span>
                              <div className="delete-invoicenum" onClick={(e) => this.handleDeleteInvoiceNumOnClick(e, { invoicei: ii})} ><FaMinusCircle /></div>
                            </ListGroup.Item>
                          )
                        })
                      }
                    </ListGroup>
                  </Form.Group>
                </Form.Row>
              </Form>
              </Row>
            </Container>
          }
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button className="cancel-save" variant="secondary" size="sm" onClick={this.handleClose}>
              Close
            </Button>
            <Button className="save-order" variant="primary" size="sm" onClick={this.saveOrder}>
              Save Order
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}


