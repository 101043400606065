import React, { Component } from "react";
import "./ProductEdit.css";
import { Container, Modal, Button, Table, Row, Col, ButtonToolbar, Form, InputGroup, ListGroup, Spinner } from "react-bootstrap";
import { FaRegTimesCircle, FaPlusCircle, FaProjectDiagram, FaRegTrashAlt, FaCaretRight } from 'react-icons/fa';
import { xZeroPad, xFormatMoney, xProductCategories } from '../components/XFunctions';
import FilesDrop from './FilesDrop';
import { Storage } from "aws-amplify"

const path = require('path')

const initialState = {
  selectedProduct: null,
  addVendorActive: false,
  editVendorActive: false,
  editVendorName: false,
  showAssemblyProducts: false,
  editVendor: {
    vendorproductnamesameasinternal: true,
    vendorproductcodesameasinternal: true,
  },
  imagesToUpload: [],
  uploadingImages: false,
  assemblyProductsDetailed: [],
  existingImagesToDelete: [],
  inventoryEdit: false,
  inventoryEditValue: ''
}

export default class ProductEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {...initialState}
  }
  componentDidMount = () => {
    let selectedProduct = this.props.isNew ? {} : {...this.props.selectedProduct}
    let inventoryEditValue = (selectedProduct.inventory && selectedProduct.inventory !== '--') ? selectedProduct.inventory : ''
    this.setState({ selectedProduct, inventoryEditValue })
    if (!this.props.isNew) {
      if (selectedProduct.vendors.length > 0) {
        this.getVendorsInfo()
      }
      let assemblyProducts = selectedProduct.assemblyproducts
      let assemblyProductsDetailed = [...assemblyProducts]
      this.setState({ assemblyProductsDetailed }, () => {
        if (assemblyProducts.length > 0) {
          this.getAssyProductsInfo()
        }
      })
    }
  }
  // UNSAFE_componentWillUnmount = () => {
  //   this.setState({ selectedProduct: null })
  // }
  handleClose = () => {
    this.setState({...initialState})
    this.componentDidMount()
    this.props.triggerCloseModal()
  }
  handleOnChange = (e, id) => {
    let value = e.target.value
    this.setState(prevState => ({
      selectedProduct: {
        ...prevState.selectedProduct,
        [id]: value
      }
    }))
  }
  handleCheckOnChange = (e, id) => {
    let value = e.target.checked
    this.setState(prevState => ({
      selectedProduct: {
        ...prevState.selectedProduct,
        [id]: value
      }
    }))
  }
  handleCategoryOnChange = (e) => {
    let value = e.target.value
    this.setState(prevState => ({
      selectedProduct: {
        ...prevState.selectedProduct,
        category: value
      }
    }))
  }
  handleEditVendorOnChange = (e, id) => {
    let value = e.target.value
    this.setState(prevState => ({
      editVendor: {
        ...prevState.editVendor,
        [id]: value
      }
    }))
  }
  handleEditVendorCheckOnChange = (e, id) => {
    let value = e.target.checked
    this.setState(prevState => ({
      editVendor: {
        ...prevState.editVendor,
        [id]: value
      }
    }))
  }
  handleSave = async () => {
    this.props.showWorkingMsg()
    let selectedProduct = {...this.state.selectedProduct}
    let { category, sku, nameen } = selectedProduct
    if (!category || category === '') {
      this.props.hideWorkingMsg()
      alert('Please select an Internal Category.')
      return
    }
    if (this.props.isNew) {
      let skuGenerator = null
      switch(category) {
        case 'Test':
          skuGenerator = 'test-product-sku'
          break
        case 'Cuatro':
          skuGenerator = 'cuatro-product-sku'
          break
        case 'Industrial':
          skuGenerator = 'industrial-product-sku'
          break
        case 'Sigilight':
          skuGenerator = 'sigilight-product-sku'
          break
        case 'Nrab':
          skuGenerator = 'nrab-product-sku'
          break
        default:
          break
      }
      // Try to get an SKU from server before continue
      sku = await this.props.getUniqueSequentialProductSku(skuGenerator)
      selectedProduct.sku = String(sku)
    }
    if (!sku || sku === '') {
      this.props.hideWorkingMsg()
      alert('Please enter Internal Product Code.')
      return
    }
    if (nameen === '') {
      this.props.hideWorkingMsg()
      alert('Please enter Product Name (EN).')
      return
    }
    this.props.triggerCloseModal()
    this.props.showWorkingMsg()
    // Upload new images to upload
    let { imagesToUpload, existingImagesToDelete } = this.state
    let storedKeys = []
    if (imagesToUpload && imagesToUpload.length > 0) {
      for (const image of imagesToUpload) {
        let file = image
        let fileext = path.extname(file.name)
        let filename = sku + '-' + file.id + fileext
        const stored = await Storage.put(filename, file, {
          contentType: file.type,
          bucket: 'gdp-erp-webmedia'
        });
        storedKeys.push(stored.key)
      }
    }
    // Reset pending images
    this.setState({ imagesToUpload: [] })
    selectedProduct.images.push(...storedKeys)
    // Delete existing images if any pending to delete
    if (existingImagesToDelete.length > 0) {
      for (const image of existingImagesToDelete) {
        // Filter out deleted existing images
        let filteredOut = selectedProduct.images.filter(x => x !== image)
        selectedProduct.images = filteredOut
        await Storage.remove(image, {
          bucket: 'gdp-erp-webmedia'
        });
      }
    }
    this.setState({ selectedProduct })
    // Add product
    await this.props.triggerSaveProduct(selectedProduct)
  }
  handleNewVendorOnClick = () => {
    this.setState({
      addVendorActive: true
    });
  }
  handleVendorOnClick = (e, idx) => {
    e.stopPropagation();
    if (this.state.selectedProduct.archived) {
      alert("Product is archived.")
      return
    }
    this.setState({
      editVendor: this.state.selectedProduct.vendors[idx]
    }, () => {
      this.setState({ editVendorActive: true })
    })
  }
  handleNewVendorCloseClick = () => {
    this.setState({
      editVendor: initialState.editVendor,
      addVendorActive: false,
      editVendorActive: false
    });
  }
  handleVendorEditExternal = () => {

  }
  handleAssemblyProductsShow = () => {
    this.setState({
      showAssemblyProducts: !this.state.showAssemblyProducts
    })
  }
  getVendorsInfo = async () => {
    let vendors = [...this.props.selectedProduct.vendors]
    let vendorsUpdated = []
    for (let vendor of vendors) {
      let vendorInfo = await this.props.getVendorById(vendor.id)
      vendor = {...vendor, ...vendorInfo}
      vendorsUpdated.push(vendor)
    }
    let selectedProduct = { ...this.state.selectedProduct }
    selectedProduct.vendors = vendorsUpdated
    this.setState({ selectedProduct })
  }
  getAssyProductsInfo = async() => {
    let assyProdsDet = this.state.assemblyProductsDetailed
    let assyProdsDetUpdated = []
    for (let product of assyProdsDet) {
      let productInfo = await this.props.getProductBySku(product.sku)
      product = {...product, ...productInfo}
      assyProdsDetUpdated.push(product)
    }
    this.setState({ assemblyProductsDetailed: assyProdsDetUpdated })
  }
  setImagesToUpload = (imagesToUpload) => {
    this.setState({ imagesToUpload })
  }
  setExistingImageToDelete = (existingImageToDelete) => {
    let selectedProduct = {...this.state.selectedProduct}
    let images = selectedProduct.images
    images = images.filter(item => item !== existingImageToDelete)
    selectedProduct.images = images
    this.setState(prevState => ({
      existingImagesToDelete: [...prevState.existingImagesToDelete, existingImageToDelete],
      selectedProduct
    }))
  }
  handleAssemblyProductDeleteOnClick = (e, idx) => {
    if (this.state.selectedProduct.archived) {
      alert("Product is archived.")
      return
    }
  }
  handleArchivedCheckOnChange = (e, id) => {
    let value = e.target.checked
    this.setState(prevState => ({
      selectedProduct: {
        ...prevState.selectedProduct,
        [id]: value
      }
    }), () => {
      if (value === true) {
        this.handleSave()
      }
    })
  }
  handleInentoryEditOnClick = () => {
    this.setState({ inventoryEdit: true })
  }
  handleInventoryEditClose = () => {
    this.setState({ inventoryEdit: false })
  }
  handleInventoryEditValueOnChange = (e) => {
    let inventoryEditValue = e.target.value
    this.setState({ inventoryEditValue })
  }
  handleInventoryEditValueUpdateOnClick = async () => {
    this.props.showWorkingMsg()
    let qty = this.state.inventoryEditValue
    let sku = this.state.selectedProduct.sku
    await this.props.setProductInventory(sku, qty)
    this.setState({ inventoryEdit: false })
    this.props.getProductInventoryActive(sku)
    this.props.hideWorkingMsg()
  }
  handleInventoryEditValueOnKeyDown = (e) => {
    if(e.keyCode === 13){
      this.handleInventoryEditValueUpdateOnClick()
    }
  }

  render() {
    const selProd = this.state.selectedProduct
    const editVendor = this.state.editVendor
    const assyProdsDet = this.state.assemblyProductsDetailed
    if (!selProd) {
      return null
    }
    if (!selProd.assemblyproducts) {
      selProd.assemblyproducts = []
    }
    if (!selProd.vendors) {
      selProd.vendors = []
    }
    if (!selProd.images) {
      selProd.images = []
    }
    return (
      <div>
        <Modal className="ProductEdit" size="lg" show={this.props.showEditModal && !this.state.inventoryEdit} onHide={this.handleClose} >
          <Modal.Header closeButton >
            <Modal.Title>
              <h5 className="product-edit-title">{this.props.isNew ? 'New product' : selProd.nameen}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="top">
              <Row className="tools-wrap d-flex justify-content-end">
              </Row>
              <Row className="product-edit-panel">
                <Col className="product-edit-fields">
                  <FilesDrop updateFiles={this.setImagesToUpload} filesExisting={selProd.images} fileExistingDelete={this.setExistingImageToDelete} productArchived={selProd.archived ? true : false} />
                  <Table size="sm" className="noborder">
                    <tbody>
                      <tr>
                        <td className="field-label">Internal Category</td>
                        <td>
                          <Form.Control disabled={selProd.archived ? true : false} size="sm" as="select" value={selProd.category} onChange={(e) => this.handleOnChange(e, 'category')} >
                            {xProductCategories.map((item, key) => {
                              return <option key={key} value={item}>{item}</option>
                            })}
                          </Form.Control>
                        </td>
                        <td className="field-label">Internal Code</td>
                        <td>
                          <Form.Control required disabled={true} size="sm" type="text" placeholder="" value={selProd.sku ? selProd.sku : ''} onChange={(e) => this.handleOnChange(e, 'sku')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Product Name (EN)</td>
                        <td colSpan="3">
                          <Form.Control required disabled={selProd.archived ? true : false} size="sm" type="text" placeholder="" value={selProd.nameen ? selProd.nameen : ''} onChange={(e) => this.handleOnChange(e, 'nameen')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Product Description (EN)</td>
                        <td colSpan="3">
                          <Form.Control disabled={selProd.archived ? true : false} size="sm" as="textarea" rows="3" placeholder="" value={selProd.descen ? selProd.descen : ''} onChange={(e) => this.handleOnChange(e, 'descen')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Nombre Producto (SP)</td>
                        <td colSpan="3">
                          <Form.Control disabled={selProd.archived ? true : false} size="sm" type="text" placeholder="" value={selProd.namesp ? selProd.namesp : ''} onChange={(e) => this.handleOnChange(e, 'namesp')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Descripcion Producto (SP)</td>
                        <td colSpan="3">
                          <Form.Control disabled={selProd.archived ? true : false} size="sm" as="textarea" rows="3" placeholder="" value={selProd.descsp ? selProd.descsp : ''} onChange={(e) => this.handleOnChange(e, 'descsp')} />
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Base Sale Price</td>
                        <td>
                          <InputGroup size="sm">
                            <Form.Control disabled={selProd.archived ? true : false} size="sm" type="text" placeholder="" value={selProd.price ? selProd.price : ''} onChange={(e) => this.handleOnChange(e, 'price')} />
                            <InputGroup.Append>
                              <InputGroup.Text>USD</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                        {this.props.showInventory &&
                          <td className="field-label">Inventory</td>
                        }
                        {this.props.showInventory &&
                          <td>
                            <InputGroup size="sm">
                              <Form.Control disabled={true} size="sm" type="text" placeholder="" value={this.state.inventoryEditValue} />
                              <InputGroup.Append>
                                <Button variant="outline-info" onClick={this.handleInentoryEditOnClick}>Edit</Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                        }
                      </tr>
                    </tbody>
                  </Table>
                  <hr/>
                  <div className="vendors-section">
                    <Row className="vendors-head">
                      <Col>
                        <h5 className="vendors-subtitle">Vendors</h5>
                      </Col>
                      <Col className="rgt">
                        {!(this.state.addVendorActive || this.state.editVendorActive) &&
                          <Button disabled={selProd.archived ? true : false} className="add-vendor" variant="light" size="sm" onClick={this.handleNewVendorOnClick}>
                            <FaPlusCircle /> Add vendor to product
                          </Button>
                        }
                      </Col>
                    </Row>
                    {(this.state.addVendorActive || this.state.editVendorActive) &&
                    <div className="vendor-new-form">
                      <Row>
                        <Col>
                          <Table size="sm" className="noborder">
                            <tbody>
                              <tr>
                                <td className="field-label">Vendor ID</td>
                                <td colSpan="3" className="field-value">{editVendor.id}</td>
                              </tr>
                              <tr>
                                <td className="field-label">Vendor Name</td>
                                <td colSpan="3">
                                  <Form.Control required disabled={(this.state.addVendorActive || this.state.editVendorName) ? false : true} size="sm" type="text" placeholder="" value={editVendor.name} onChange={(e) => this.handleEditVendorOnChange(e, 'name')} />
                                  {(!this.state.addVendorActive && !this.state.editVendorName) &&
                                    <Button variant="link" size="sm" onClick={this.handleVendorEditExternal}>Modify vendor</Button>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td className="field-label">Vendor Product Name</td>
                                <td colSpan="3">
                                  <Form.Control disabled={editVendor.productnamesameasinternal ? true : false} size="sm" type="text" placeholder="" value={editVendor.productnamesameasinternal ? selProd.nameen : editVendor.productname} onChange={(e) => this.handleEditVendorOnChange(e, 'productname')} />
                                  <Form.Check label="Same as internal" size="sm" checked={editVendor.productnamesameasinternal} onChange={(e) => this.handleEditVendorCheckOnChange(e, 'productnamesameasinternal')} />
                                </td>
                              </tr>
                              <tr>
                                <td className="field-label">Vendor Product Code</td>
                                <td>
                                  <Form.Control disabled={editVendor.productcodesameasinternal ? true : false} size="sm" type="text" placeholder="" value={editVendor.productcodesameasinternal ? selProd.sku : editVendor.productcode} onChange={(e) => this.handleEditVendorOnChange(e, 'productcode')} />
                                  <Form.Check label="Same as internal" size="sm" checked={editVendor.productcodesameasinternal} onChange={(e) => this.handleEditVendorCheckOnChange(e, 'productcodesameasinternal')} />
                                </td>
                                <td className="field-label">Vendor Price</td>
                                <td>
                                  <InputGroup size="sm">
                                    <Form.Control size="sm" type="text" placeholder="" value={editVendor.productprice} onChange={(e) => this.handleEditVendorOnChange(e, 'productprice')} />
                                    <InputGroup.Append>
                                      <InputGroup.Text>USD</InputGroup.Text>
                                    </InputGroup.Append>
                                  </InputGroup>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="action-buttons">
                          <ButtonToolbar className="vendor-delete">
                            {this.state.editVendorActive &&
                              <Button variant="light" size="sm" onClick={this.handleVendorDeleteOnClick}>
                                <FaRegTimesCircle /> Remove from product
                              </Button>
                            }
                          </ButtonToolbar>
                          <ButtonToolbar>
                            <Button className="cancel-save" variant="secondary" size="sm" onClick={this.handleNewVendorCloseClick}>Cancel</Button>
                            <Button className="cancel-save" variant="warning" size="sm" onClick={this.handleNewVendorSaveClick}>Save</Button>
                          </ButtonToolbar>
                        </Col>
                      </Row>
                    </div>
                    }
                    {!(this.state.addVendorActive || this.state.editVendorActive) &&
                    <Row>
                      <Col>
                        <ListGroup className="vendors-list">
                          {selProd.vendors.map((el, idx) => {
                            return(
                              <ListGroup.Item action key={idx} onClick={(e) => this.handleVendorOnClick(e, idx)} >
                                <Row>
                                  <Col xs={9} className="name">{el.name ? el.name : <Spinner animation="border" size="sm" />}</Col>
                                  <Col xs={3} className="price">{'$' + xFormatMoney(el.productprice)}</Col>
                                </Row>
                              </ListGroup.Item>
                            )
                          })}
                        </ListGroup>
                      </Col>
                    </Row>
                    }
                  </div>
                  <hr/>
                  <h5>Shipping</h5>
                  <Table size="sm" className="noborder">
                    <tbody>
                      <tr>
                        <td className="field-label">Unit Weight</td>
                        <td>
                          <InputGroup size="sm">
                            <Form.Control disabled={selProd.archived ? true : false} size="sm" type="text" placeholder="" value={selProd.unitweight ? selProd.unitweight : ''} onChange={(e) => this.handleOnChange(e, 'unitweight')} />
                            <InputGroup.Append>
                              <InputGroup.Text>lbs.</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                        <td className="field-label">Package Unit Count</td>
                        <td>
                          <Form.Control disabled={selProd.archived ? true : false} size="sm" type="text" placeholder="" value={selProd.pkgunitcount ? selProd.pkgunitcount : ''} onChange={(e) => this.handleOnChange(e, 'pkgunitcount')} />
                        </td>
                        <td className="field-label">Full Package Weight</td>
                        <td>
                          <InputGroup size="sm">
                            <Form.Control disabled={selProd.archived ? true : false} size="sm" type="text" placeholder="" value={selProd.fullpkgweight ? selProd.fullpkgweight : ''} onChange={(e) => this.handleOnChange(e, 'fullpkgweight')} />
                            <InputGroup.Append>
                              <InputGroup.Text>lbs.</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td className="field-label">Full Package Dimensions</td>
                        <td colSpan="5">
                          <InputGroup className="mb-3" size="sm">
                            <InputGroup.Prepend>
                              <InputGroup.Text>SideA</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control disabled={selProd.archived ? true : false} placeholder="" value={selProd.pkgdimsidea ? selProd.pkgdimsidea : ''} onChange={(e) => this.handleOnChange(e, 'pkgdimsidea')} />
                            <InputGroup.Append>
                              <InputGroup.Text>SideB</InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control disabled={selProd.archived ? true : false} placeholder="" value={selProd.pkgdimsideb ? selProd.pkgdimsideb : ''} onChange={(e) => this.handleOnChange(e, 'pkgdimsideb')} />
                            <InputGroup.Append>
                              <InputGroup.Text>Height</InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control disabled={selProd.archived ? true : false} placeholder="" value={selProd.pkgdimheight ? selProd.pkgdimheight : ''} onChange={(e) => this.handleOnChange(e, 'pkgdimheight')} />
                            <InputGroup.Append>
                              <InputGroup.Text>inches</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr/>
                  <div className="manufacturing-section">
                    <h5>Manufacturing</h5>
                    <Table size="sm" className="noborder">
                      <tbody>
                        <tr>
                          <td className="field-label">Manufacturer Product Code</td>
                          <td>
                            <Form.Control disabled={selProd.archived ? true : false} size="sm" type="text" placeholder="" value={selProd.manufsku ? selProd.manufsku : ''} onChange={(e) => this.handleOnChange(e, 'manufsku')} />
                          </td>
                          <td className="field-label">Assembly products required</td>
                          <td>
                            <Button className={'showassyprods ' + (this.state.showAssemblyProducts ? 'active' : '')} variant="light" size="lg" onClick={this.handleAssemblyProductsShow}>
                              <FaProjectDiagram /> <strong>{assyProdsDet.length}</strong>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    {this.state.showAssemblyProducts &&
                      <Row>
                        <Col xs={12} className="record val products">
                          <Table bordered size="sm">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Qty</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assyProdsDet.map((el, idx) => {
                                let elname = el.nameen ? el.nameen : <Spinner animation="border" size="sm" />
                                return(
                                  <tr key={idx}>
                                    <td className="no">{xZeroPad(idx + 1)}</td>
                                    <td><span>{'[' + el.sku + ']'}</span><span>&nbsp;{elname}</span></td>
                                    <td className="qty">{el.qty}</td>
                                    <td className="tools"><FaRegTrashAlt onClick={e => this.handleAssemblyProductDeleteOnClick(e, idx)} /></td>
                                  </tr>
                                )
                              })}
                              {this.state.selectedProduct.archived === false &&
                                <tr>
                                  <td className="no"><FaCaretRight /></td>
                                  <td><Form.Control size="sm" type="text" placeholder="" /></td>
                                  <td className="qty"><Form.Control size="sm" type="text" placeholder="" /></td>
                                  <td className="tools"><Button size="sm" variant="light" ><FaPlusCircle /></Button></td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar className="product-archived">
              <Form.Check label="Archived" size="sm" checked={selProd.archived} onChange={(e) => this.handleArchivedCheckOnChange(e, 'archived')} />
            </ButtonToolbar>
            <ButtonToolbar>
              <Button className="cancel-save" variant="secondary" size="sm" onClick={this.handleClose}>{selProd.archived === true ? 'Close' : 'Cancel'}</Button>
              {this.state.selectedProduct.archived !== true &&
                <Button className="cancel-save" variant="primary" size="sm" onClick={this.handleSave}>Save</Button>
              }
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
        <Modal className="InventoryEdit" size="md" show={this.props.showEditModal && this.state.inventoryEdit} onHide={this.handleInventoryEditClose} >
          <Modal.Header closeButton >
            <Modal.Title>
              <h5 className="product-edit-title">{selProd.nameen}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="top">
              <InputGroup size="sm">
                <Form.Control value={this.state.inventoryEditValue} disabled={false} size="sm" type="text" placeholder="" onChange={this.handleInventoryEditValueOnChange} onKeyDown={this.handleInventoryEditValueOnKeyDown} />
                <InputGroup.Append>
                  <Button variant="primary" onClick={this.handleInventoryEditValueUpdateOnClick}>Update</Button>
                </InputGroup.Append>
              </InputGroup>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}


