import React, { Component } from "react";
import { Container, Row, Col, Form, Image }  from 'react-bootstrap';
import "./InvoicingAR.css";
import invoicingar_cc_pay_guide_1 from '../images/invoicingar_cc_pay_guide_1.png';
import invoicingar_cc_pay_guide_2 from '../images/invoicingar_cc_pay_guide_2.png';
import invoicingar_cc_pay_guide_3 from '../images/invoicingar_cc_pay_guide_3.png';
import invoicingar_cc_pay_guide_4 from '../images/invoicingar_cc_pay_guide_4.png';

export default class InvoicingAR extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companySelected: 'Geedop LLC',
    }
  }
  handleCompanyOnChange = (e) => {
    let companySelected = e.target.value
    this.setState({ companySelected })
  }
  render() {

    return (
      <div className="InvoicingAR">
        <Container fluid >
          <Row className="section-title">
            <Col sm={8}><h4>Invoicing + Accounts Receivable (AR)</h4></Col>
            <Col sm={4}>
              <Form>
                <Form.Group controlId="exampleForm.SelectCustomSizeLg">
                  <Form.Control 
                    as="select" 
                    size="md" 
                    custom 
                    value={this.state.companySelected} 
                    onChange={this.handleCompanyOnChange}
                  >
                    <option value="Gegedop LLC">Geedop LLC (USA)</option>
                    <option value="RANS">RANS (Mexico)</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>How-to Guides</h3>
              <h4>Credit Card Payments</h4>
              <ol>
                <li>Sign in to Square <a href="https://squareup.com/dashboard/ecom/online-checkout" target="_blank" rel="noopener noreferrer">Online Checkout</a> using the email and password provided by your supervisor.</li>
                <li>Click on the <strong>Create checkout link</strong> button:<br/><Image src={invoicingar_cc_pay_guide_1} rounded /></li>
                <li>Select <strong>Collect payment</strong> and click <strong>Create link</strong>:<br/><Image src={invoicingar_cc_pay_guide_2} rounded /></li>
                <li>Enter a <strong>Title</strong> that includes the <strong>Invoice number and a brief purchased item descirption</strong>, then enter the <strong>Amount</strong> to be charged. Click <strong>Create link</strong> when finished:<br/><Image src={invoicingar_cc_pay_guide_3} rounded /></li>
                <li><strong>Copy</strong> the link and share with the customer via email:<br/><Image src={invoicingar_cc_pay_guide_4} rounded /></li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}