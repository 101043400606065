
export const cases = {
  ELP: {
    confirmed: 25,
    deaths: 0,
    recovered: 0,
    active: 25,
    tested: null,
    state: {
      confirmed: 1345,
      deaths: 14,
      recovered: 0,
      active: null,
      tested: 13235,
    }
  },
  LCR: {
    confirmed: 13,
    deaths: 0,
    recovered: 0,
    active: 13,
    tested: null,
    state: {
      confirmed: 113,
      deaths: 1,
      recovered: 0,
      active: null,
      tested: 7793,
    }
  },
  JRZ: {
    confirmed: 4,
    deaths: 0,
    recovered: 0,
    active: 0,
    tested: null,
    state: {
      confirmed: 6,
      deaths: 0,
      recovered: 0,
      active: null,
      tested: 137,
    }
  },
  FTB: {
    confirmed: 7,
    deaths: 0,
    recovered: 0,
    active: null,
    tested: null,
  },
}

export const hospitals = [
  {
    name: 'The Hospitals of Providence East Campus',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/edS2V7xfzcfF76S19',
    address: '3280 Joe Battle Blvd, El Paso, TX 79938',
    coords: [31.790595, -106.265027],
  },
  {
    name: 'University Medical Center of El Paso',
    type: 'University hospital',
    googlink: 'https://goo.gl/maps/Q4xmxbFUyc1PPo8d7',
    address: '1485 George Dieter Dr, El Paso, TX 79936',
    coords: [31.743470, -106.302551],
  },
  {
    name: 'The Hospitals of Providence Horizon City Campus',
    type: 'Hospital',
    googlink: 'https://goo.gl/maps/HcWq5vcDEi4TfjD76',
    address: '13600 Horizon Blvd, Horizon City, TX 79928',
    coords: [31.682294, -106.208033],
  },
  {
    name: 'Emergency Room - Trawood',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/VQ183TvEiRE5Shrw6',
    address: '2400 Trawood Dr #100, El Paso, TX 79936',
    coords: [31.764171, -106.316940],
  },
  {
    name: 'Regent Care Center',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/mF3se6wtBhzkx8Tv9',
    address: '10880 Edgemere Blvd, El Paso, TX 79935',
    coords: [31.791046, -106.324758],
  },
  {
    name: 'Del Sol Medical Center',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/bf6zciVauuwe49EAA',
    address: '10301 Gateway Blvd W, El Paso, TX 79925',
    coords: [31.756890, -106.350632],
  },
  {
    name: 'University Medical Center Foundation',
    type: 'University hospital',
    googlink: 'https://goo.gl/maps/LgLS42tQGG1h9Qzu5',
    address: '1400 Hardaway St, El Paso, TX 79903',
    coords: [31.788515, -106.435113],
  },
  {
    name: 'University Medical Center of El Paso',
    type: 'Medical Center',
    googlink: 'https://goo.gl/maps/mhPMMkY4PZ3qBEm17',
    address: '4815 Alameda Ave, El Paso, TX 79905',
    coords: [31.771860, -106.433938],
  },
  {
    name: 'El Paso Children’s Hospital',
    type: 'Children\'s hospital',
    googlink: 'https://goo.gl/maps/9GyJrVDk756P6Evd6',
    address: '4845 Alameda Ave, El Paso, TX 79905',
    coords: [31.771392, -106.433954],
  },
  {
    name: 'University Medical Center of El Paso Annex Building',
    type: 'Medical Center',
    googlink: 'https://goo.gl/maps/65ERbWMCfH1G675XA',
    address: '4815 Alameda Ave, El Paso, TX 79905',
    coords: [31.771472, -106.434489],
  },
  {
    name: 'Thomason General Hospital Heliport',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/JqKhZW1NZbiNLdg48',
    address: 'Alameda Ave, El Paso, TX 79905',
    coords: [31.771640, -106.435442],
  },
  {
    name: 'El Paso LTAC Hospital',
    type: 'Hospital',
    googlink: 'https://g.page/el-paso-ltac-hospital-el-paso?share',
    address: '1221 N Cotton St # 3, El Paso, TX 79902',
    coords: [31.783765, -106.474256],
  },
  {
    name: 'Kindred Hospital El Paso',
    type: 'Hospital',
    googlink: 'https://goo.gl/maps/2sDLRYxBQhfZ8ecK9',
    address: '1740 Curie Dr, El Paso, TX 79902',
    coords: [31.778721, -106.477206],
  },
  {
    name: 'The Hospitals of Providence Sierra Campus',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/nszSvDTQmsWoEBWv8',
    address: '1625 Medical Center Drive, El Paso, TX 79902',
    coords: [31.778338, -106.480523],
  },
  {
    name: 'Las Palmas Medical Center',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/97SCgdEQpLNknAb87',
    address: '1801 N Oregon St, El Paso, TX 79902',
    coords: [31.769903, -106.498465],
  },
  {
    name: 'The Hospitals of Providence Children\'s Hospital',
    type: 'Children\'s hospital',
    googlink: 'https://goo.gl/maps/jwhVhGq3bGMq1EeB7',
    address: '2001 N Oregon St, El Paso, TX 79902',
    coords: [31.771035, -106.500117],
  },
  {
    name: 'The Hospitals of Providence Memorial Campus',
    type: 'General hospital',
    googlink: 'https://goo.gl/maps/ZWesKYbWF4Afnyh99',
    address: '2001 N Oregon St, El Paso, TX 79902',
    coords: [31.770761, -106.500447],
  },
  {
    name: 'Premier Specialty Hospital of El Paso',
    type: 'Hospital',
    googlink: 'https://g.page/premiersh?share',
    address: '2311 N Oregon St floor 5, El Paso, TX 79902',
    coords: [31.773518, -106.502233],
  },
  {
    name: 'IMSS UMF 56',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/BmoSvHavCmoedjKw5',
    address: 'CALLE MIGUEL HIDALGO 3904 JUáREZ, COL. DURANGO, JUáREZ, C.P. 32160',
    coords: [31.742900, -106.503400],
  },
  {
    name: 'IMSS UMF 61',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/XNsAs9d3ZSJA4Y9R6',
    address: 'CALLE CARLOS VILLAREAL S/N JUáREZ, COL. ZONA PRONAF , JUáREZ, C.P. 32315',
    coords: [31.740700, -106.450300],
  },
  {
    name: 'IMSS UMF 47',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/q8ovdyEiavhKhyh66',
    address: 'CALLE LIBERTAD Y JUAN M MEDINA 2110 JUáREZ, COL. CHAVEñA , JUáREZ, C.P. 32060',
    coords: [31.725200, -106.479500],
  },
  {
    name: 'IMSS HGZ 6',
    type: 'Hospital',
    googlink: 'https://goo.gl/maps/7dFNSw3mgMXMdbUA7',
    address: 'CALLE S MEJIA ESQ AV. LóPEZ MATEOS 450 JUáREZ, COL. PRONAF, JUáREZ, C.P. 32315',
    coords: [31.739200, -106.450600],
  },
  {
    name: 'IMSS UMF 50',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/GRNhor9KN86nFWxB7',
    address: 'CALLE IRLANDA Y GUADIANA S/N JUáREZ, COL. MIRADOR , JUáREZ, C.P. 32260',
    coords: [31.705900, -106.464800],
  },
  {
    name: 'IMSS HGR 66',
    type: 'Hospital',
    googlink: 'https://goo.gl/maps/bo2J6tyVghvc6hFr6',
    address: 'CALLE RAMON RAYON 951 JUáREZ, COL. PASEO DE LAS TORRES , JUáREZ, C.P. 32575',
    coords: [31.618900, -106.384000],
  },
  {
    name: 'IMSS UMF 48',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/JugCTAywu1ShyuWK9',
    address: 'CALLE MANUEL J CLOUTIER 8625 JUáREZ, COL. ANGEL TRIAS INFONAVIT , JUáREZ, C.P. 32668',
    coords: [31.667000, -106.403700],
  },
  {
    name: 'IMSS UMF 65',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/RYb9XnvrG2dv57pP8',
    address: 'CALLE EJIDO GALEANA 1833 JUáREZ, COL. MANUEL VALDEZ, JUáREZ, C.P. 32590',
    coords: [31.632000, -106.363700],
  },
  {
    name: 'IMSS UMF 63',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/XnFtXKrpdwD7dVPH8',
    address: 'CALLE COATEPEC 4829 JUáREZ, COL. GUSTAVO DíAZ ORDAZ, JUáREZ, C.P. 32220',
    coords: [31.710400, -106.512200],
  },
   {
    name: 'IMSS HGZ-UMAA 35',
    type: 'Hospital',
    googlink: 'https://goo.gl/maps/qKSxgWDKdn4Ebk5D9',
    address: 'CALLE VALENTIN FUENTES ESQ ANTONIO MAURA 2582 JUáREZ, COL. CASAS GRANDES INFONAVIT 1ERA SECC, JUáREZ, C.P. 32600',
    coords: [31.705800, -106.436000],
  },
  {
    name: 'IMSS UMF 46',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/CuNaYL3Z25pnr3o39',
    address: 'AVENIDA HENRY DUNANT 610 JUáREZ, COL. PRONAF, JUáREZ, C.P. 32310',
    coords: [31.741700, -106.448200],
  },
   {
    name: 'IMSS UMF 34',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/77y9xFhasQboQT2s9',
    address: 'AVENIDA VALENTIN FUENTES 2582 JUáREZ, COL. CASAS GRANDES INFONAVIT, JUáREZ, C.P. 32600',
    coords: [31.705400, -106.436400],
  },
  {
    name: 'IMSS UMF 64',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/s2dyZMD57gLgWiaJ9',
    address: 'AVENIDA HENEQUEN ESQ 3A 1581 JUáREZ, COL. DESARROLLO SALVARCAR, JUáREZ, C.P. 32573',
    coords: [31.646800, -106.383700],
  },
   {
    name: 'IMSS UMF 67',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/XyViPr4TjvbcktWTA',
    address: 'AVENIDA SANTOS DUMONT 654 JUáREZ, COL. PANAMERICANA , JUáREZ, C.P. 32320',
    coords: [31.623600, -106.449600],
  },
  {
    name: 'IMSS UMF 62',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/VtKrUW2vByFyaGet7',
    address: 'CALLE PORFIRIO PARRA S/N JUáREZ, COL. FRANCISCO I MADERO, JUáREZ, C.P. 32170',
    coords: [31.736800, -106.509100],
  },
   {
    name: 'IMSS UMF 70',
    type: 'Clínica',
    googlink: 'https://goo.gl/maps/cT4cKQpFjsokgXKQ9',
    address: 'CALLE HACIENDA DE LOS FRESNOS CALLE ACACIA Y HACIENDA CAñADA 9703 JUáREZ, COL. FRACCIONAMIENTO LAS HUERTAS , JUáREZ, C.P. 32577',
    coords: [31.574060, -106.390900],
  },
]

export const screeningSites = [
  {
    area: 'Las Cruces',
    name: 'Memorial Medical Center',
    desc: 'Memorial Medical Center',
    type: 'DRIVE THRU testing',
    googlink: 'https://goo.gl/maps/pceQQ1uKWent97m48',
    address: '2450 S Telshor Blvd Las Cruces, NM 88011',
    coords: [32.291412, -106.737125],
    source: 'https://cv.nmhealth.org/project/las-cruces-memorial-medical-center/',
    phone: '1-855-600-3453',
  },
  {
    area: 'Las Cruces',
    name: 'New Mexico Department of Health – Public Health Division – Southwest Region – Location 001',
    desc: 'Burrell College of Medicine Parking Lot',
    type: 'DRIVE THRU testing. Offers screening on site to determine if you are eligible for testing',
    googlink: 'https://goo.gl/maps/gnKCNnTjSni358vVA',
    address: '3501 Arrowhead Dr, Las Cruces, NM 88003',
    coords: [32.278020, -106.737743],
    source: 'https://cv.nmhealth.org/project/las-cruces-new-mexico-department-of-health-public-health-division-southwest-region-location-001/',
    phone: '1-855-600-3453',
  },
  {
    area: 'Las Cruces',
    name: 'New Mexico Department of Health – Public Health Division – Southwest Region – Location 002',
    desc: 'Las Cruces Public Health Office (PHO) – Parking Lot',
    type: 'TBD',
    googlink: 'https://goo.gl/maps/WTLtZTETHLTidgAP9',
    address: '1170 N. Solano Las Cruces, NM 88001',
    coords: [32.320780, -106.770367],
    source: 'https://cv.nmhealth.org/project/las-cruces-new-mexico-department-of-health-public-health-division-southwest-region-location-002/',
    phone: '1-855-600-3453',
  },
  {
    area: 'Las Cruces',
    name: 'Burrell College of Osteopathic Medicine',
    desc: 'Burrell College of Osteopathic Medicine',
    type: 'DRIVE THRU testing',
    googlink: 'https://goo.gl/maps/oxVhMDvLTgfbSUBF8',
    address: '3501 Arrowhead Dr, Las Cruces, NM 88003',
    coords: [32.278006, -106.737657],
    source: 'https://cv.nmhealth.org/project/las-cruces-burrell-college-of-osteopathic-medicine/',
    phone: '1-855-600-3453',
  },
]

export const grocery = [
  {
    name: 'El Super',
    type: 'Grocery Store',
    googlink: 'https://goo.gl/maps/rBQfK3nagRFrE4kd8',
    address: '10501 Gateway Blvd W Ste 600, El Paso, TX 79925',
    coords: [31.755119, -106.346306],
  },
  {
    name: 'El Super',
    type: 'Grocery Store',
    googlink: 'https://goo.gl/maps/KnzYbfUkuuRQDdseA',
    address: '703 N Zaragoza Rd, El Paso, TX 79907',
    coords: [31.705564, -106.319893],
  },
]

export const pharmacies = [
  {
    "id": "ChIJpSYTOQ743YYRAegj047xDxE",
    "name": "CVS Pharmacy",
    "type": "pharmacy",
    "address": "801 Sunland Park Dr, El Paso, TX 79912, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJpSYTOQ743YYRAegj047xDxE",
    "coords": [
      31.819086,
      -106.5478444
    ],
    "phone": "(915) 255-4744"
  },
  {
    "id": "ChIJIYbebD753YYR8U2z8E27Cmc",
    "name": "CVS Pharmacy",
    "type": "pharmacy",
    "address": "1 N Mesa St suite a, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJIYbebD753YYR8U2z8E27Cmc",
    "coords": [
      31.7588253,
      -106.4874826
    ],
    "phone": "(915) 585-1458"
  },
  {
    "id": "ChIJgQYCsJdZ54YRoXCgEhK0ofU",
    "name": "Thao Ho, PharmD",
    "type": "pharmacy",
    "address": "2879 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJgQYCsJdZ54YRoXCgEhK0ofU",
    "coords": [
      31.7837267,
      -106.4620752
    ],
    "phone": "(915) 566-4464"
  },
  {
    "id": "ChIJf32BNVVY54YRSnNLxEpstSU",
    "name": "Surecare Specialty Pharmacy",
    "type": "pharmacy",
    "address": "4005 N Mesa St, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJf32BNVVY54YRSnNLxEpstSU",
    "coords": [
      31.78815400000001,
      -106.508147
    ],
    "phone": "(915) 532-2400"
  },
  {
    "id": "ChIJ_QIHc-VZ54YRkgLXAsKvCVY",
    "name": "The Pharmacy Shoppe",
    "type": "pharmacy",
    "address": "3310 Fort Blvd, El Paso, TX 79930, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ_QIHc-VZ54YRkgLXAsKvCVY",
    "coords": [
      31.8056541,
      -106.4547819
    ],
    "phone": "(915) 564-5451"
  },
  {
    "id": "ChIJUejoiyZb54YR_B-jOO98F6k",
    "name": "CVS Pharmacy",
    "type": "pharmacy",
    "address": "5305 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJUejoiyZb54YR_B-jOO98F6k",
    "coords": [
      31.78400969999999,
      -106.4259584
    ],
    "phone": "(915) 772-2824"
  },
  {
    "id": "ChIJ51liF0RY54YRtyJmazm6lUE",
    "name": "Albertsons Pharmacy",
    "type": "pharmacy",
    "address": "3100 N Mesa St, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ51liF0RY54YRtyJmazm6lUE",
    "coords": [
      31.7809293,
      -106.5040518
    ],
    "phone": "(915) 351-1183"
  },
  {
    "id": "ChIJ5--Ywgda54YR_qahML4C8Tw",
    "name": "Freedom Crossing PX Pharmacy",
    "type": "pharmacy",
    "address": "1611 Haan Road, Fort Bliss, TX 79906, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ5--Ywgda54YR_qahML4C8Tw",
    "coords": [
      31.81745459999999,
      -106.42966
    ],
    "phone": "(915) 742-9017"
  },
  {
    "id": "ChIJyfINwN1X54YRV48xqRVLB4c",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "5900 N Mesa St, El Paso, TX 79912, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJyfINwN1X54YRV48xqRVLB4c",
    "coords": [
      31.82932079999999,
      -106.5276455
    ],
    "phone": "(915) 584-1153"
  },
  {
    "id": "ChIJfadowU1a54YRsoroyA3Hwro",
    "name": "Juilian A. Herrera, Pharm",
    "type": "pharmacy",
    "address": "1100 Geronimo Dr, El Paso, TX 79925, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJfadowU1a54YRsoroyA3Hwro",
    "coords": [
      31.7826724,
      -106.4065059
    ],
    "phone": "(915) 778-9301"
  },
  {
    "id": "ChIJ3dynd7BZ54YRS622CiJxNjA",
    "name": "Joseph R. Novelo, Pharm",
    "type": "pharmacy",
    "address": "5001 North Piedras Street, El Paso, TX 79930, El Paso, TX 79930, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ3dynd7BZ54YRS622CiJxNjA",
    "coords": [
      31.8186081,
      -106.4615224
    ],
    "phone": "(915) 564-7949"
  },
  {
    "id": "ChIJq8j6fgJZ54YRPsjGD4_drJs",
    "name": "CVS Pharmacy",
    "type": "pharmacy",
    "address": "201 N Mesa St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJq8j6fgJZ54YRPsjGD4_drJs",
    "coords": [
      31.75887759999999,
      -106.4875557
    ],
    "phone": "(915) 533-3709"
  },
  {
    "id": "ChIJn2W9ujFa54YR93__6ffVDc4",
    "name": "Albertsons Pharmacy",
    "type": "pharmacy",
    "address": "5200 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJn2W9ujFa54YR93__6ffVDc4",
    "coords": [
      31.7825228,
      -106.4266607
    ],
    "phone": "(915) 778-0680"
  },
  {
    "id": "ChIJeXpaxE1X54YRX3_bB40pYPE",
    "name": "Walmart Pharmacy",
    "type": "pharmacy",
    "address": "5631 Dyer St, El Paso, TX 79904, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJeXpaxE1X54YRX3_bB40pYPE",
    "coords": [
      31.8293394,
      -106.4465703
    ],
    "phone": "(915) 245-3517"
  },
  {
    "id": "ChIJD1iXx9Nb54YR3eraD2fdkBE",
    "name": "Alameda Thrifty Pharmacy",
    "type": "pharmacy",
    "address": "4900 Alameda Ave #A, El Paso, TX 79905, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJD1iXx9Nb54YR3eraD2fdkBE",
    "coords": [
      31.7697188,
      -106.4326305
    ],
    "phone": "(915) 772-5331"
  },
  {
    "id": "ChIJ1ynDoAJZ54YRpFjgBzLebXo",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "302 S Oregon St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ1ynDoAJZ54YRpFjgBzLebXo",
    "coords": [
      31.7557082,
      -106.4869506
    ],
    "phone": "(915) 532-4344"
  },
  {
    "id": "ChIJ4eJH9ERY54YRaMBYLLQHPPk",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "2800 N Mesa St, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ4eJH9ERY54YRaMBYLLQHPPk",
    "coords": [
      31.77840849999999,
      -106.503612
    ],
    "phone": "(915) 533-6883"
  },
  {
    "id": "ChIJOzQU501a54YR5CY2Ccrv2o4",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "1100 Geronimo Dr, El Paso, TX 79925, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJOzQU501a54YR5CY2Ccrv2o4",
    "coords": [
      31.7825992,
      -106.4067737
    ],
    "phone": "(915) 778-9301"
  },
  {
    "id": "ChIJlxBx5TBa54YRmyIYN40nP0k",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "5401 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJlxBx5TBa54YRmyIYN40nP0k",
    "coords": [
      31.78407319999999,
      -106.4249736
    ],
    "phone": "(915) 779-8825"
  },
  {
    "id": "ChIJf4GWsZdZ54YRU08tuYdQU9Q",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "2879 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJf4GWsZdZ54YRU08tuYdQU9Q",
    "coords": [
      31.7837084,
      -106.4620659
    ],
    "phone": "(915) 566-4464"
  },
  {
    "id": "ChIJH26G7tJZ54YRpS52wfy9Fq0",
    "name": "Community, A Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "2701 N Mesa St Ste 100A, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJH26G7tJZ54YRpS52wfy9Fq0",
    "coords": [
      31.7768905,
      -106.503613
    ],
    "phone": "(915) 444-3423"
  },
  {
    "id": "ChIJZRhqRBxZ54YROoAGr1lzxJ4",
    "name": "Botica San Gabriel",
    "type": "pharmacy",
    "address": "720 S Stanton St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZRhqRBxZ54YROoAGr1lzxJ4",
    "coords": [
      31.7526184,
      -106.4837725
    ],
    "phone": "(915) 532-2239"
  },
  {
    "id": "ChIJe_bwXl5X54YRtWkubbhh3Ss",
    "name": "Mapula Joseph M",
    "type": "pharmacy",
    "address": "5001 N Piedras St, El Paso, TX 79930, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJe_bwXl5X54YRtWkubbhh3Ss",
    "coords": [
      31.8186054,
      -106.461523
    ],
    "phone": "(915) 564-7942"
  },
  {
    "id": "ChIJ2TDI80RY54YR6lSQSIY9h7M",
    "name": "Julius F. Agwe, PharmD",
    "type": "pharmacy",
    "address": "2800 N Mesa St, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ2TDI80RY54YR6lSQSIY9h7M",
    "coords": [
      31.7781732,
      -106.5036254
    ],
    "phone": "(915) 533-5449"
  },
  {
    "id": "ChIJ_-5EpxtZ54YROFheti4UnhY",
    "name": "Carly Cloud, PharmD",
    "type": "pharmacy",
    "address": "700 S Ochoa St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ_-5EpxtZ54YROFheti4UnhY",
    "coords": [
      31.7539644,
      -106.4796537
    ],
    "phone": "(915) 545-4550"
  },
  {
    "id": "ChIJg1cIi1VY54YRDWoi_1qtgTg",
    "name": "Kathryn M. Mier, PharmD",
    "type": "pharmacy",
    "address": "1100 N Stanton St, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJg1cIi1VY54YRDWoi_1qtgTg",
    "coords": [
      31.76643859999999,
      -106.4919872
    ],
    "phone": "(915) 747-8519"
  },
  {
    "id": "ChIJlXFDfF5X54YRI6sq4rsHs90",
    "name": "Charles N. McKee, PharmD",
    "type": "pharmacy",
    "address": "5005 North Piedras Street, El Paso, TX 79920, El Paso, TX 79930, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJlXFDfF5X54YRI6sq4rsHs90",
    "coords": [
      31.8216133,
      -106.4624893
    ],
    "phone": "(915) 569-1233"
  },
  {
    "id": "ChIJSSmmkD1Y54YR0rxIzOJ43e8",
    "name": "Phar Merica",
    "type": "pharmacy",
    "address": "4171 N Mesa St, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJSSmmkD1Y54YR0rxIzOJ43e8",
    "coords": [
      31.793964,
      -106.510959
    ],
    "phone": "(915) 545-1955"
  },
  {
    "id": "ChIJr33Y3fFZ54YR98mNejma6TA",
    "name": "Genoa Healthcare",
    "type": "pharmacy",
    "address": "1600 Montana Ave p1, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJr33Y3fFZ54YR98mNejma6TA",
    "coords": [
      31.7730062,
      -106.47683
    ],
    "phone": "(915) 235-0060"
  },
  {
    "id": "ChIJ5aQ0brBZ54YR2kttZlUxcKQ",
    "name": "Syncor Nuclear Pharmacy",
    "type": "pharmacy",
    "address": "1810 Murchison Dr, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ5aQ0brBZ54YR2kttZlUxcKQ",
    "coords": [
      31.7810764,
      -106.4764345
    ],
    "phone": "(915) 544-0094"
  },
  {
    "id": "ChIJyQcCrjFa54YRCilffFRwGWg",
    "name": "Savon Pharmacy",
    "type": "pharmacy",
    "address": "5200 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJyQcCrjFa54YRCilffFRwGWg",
    "coords": [
      31.78279239999999,
      -106.4261904
    ],
    "phone": "(915) 778-0680"
  },
  {
    "id": "ChIJ01DE-zBa54YRMSdWo2Rzyp0",
    "name": "Beatriz M. Nagatani, Pharm",
    "type": "pharmacy",
    "address": "5401 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ01DE-zBa54YRMSdWo2Rzyp0",
    "coords": [
      31.7840775,
      -106.4250362
    ],
    "phone": "(915) 779-8825"
  },
  {
    "id": "ChIJ0eM_BBj43YYRo03_i77YEUU",
    "name": "Walmart Pharmacy",
    "type": "pharmacy",
    "address": "1110 Sunland Park Dr, El Paso, TX 79922, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ0eM_BBj43YYRo03_i77YEUU",
    "coords": [
      31.8113432,
      -106.557668
    ],
    "phone": "(915) 352-2815"
  },
  {
    "id": "ChIJgQYCsJdZ54YRYUCV_jJ9d7Y",
    "name": "Juan L. Adame, PharmD",
    "type": "pharmacy",
    "address": "2879 Montana Ave, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJgQYCsJdZ54YRYUCV_jJ9d7Y",
    "coords": [
      31.7837267,
      -106.4620752
    ],
    "phone": "(915) 566-4464"
  },
  {
    "id": "ChIJ_3T1SLBZ54YROa9GqsYzbIo",
    "name": "Mike Gonzales, PharmD",
    "type": "pharmacy",
    "address": "1720 Murchison Dr, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ_3T1SLBZ54YROa9GqsYzbIo",
    "coords": [
      31.78047960000001,
      -106.4776685
    ],
    "phone": "(915) 313-9870"
  },
  {
    "id": "ChIJlYZlYYJZ54YRk_aizqWOqmQ",
    "name": "San Pedro Thrifty Pharmacy",
    "type": "pharmacy",
    "address": "3712 Alameda Ave, El Paso, TX 79905, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJlYZlYYJZ54YRk_aizqWOqmQ",
    "coords": [
      31.7731655,
      -106.4491683
    ],
    "phone": "(915) 533-8446"
  },
  {
    "id": "ChIJ43mdVKRZ54YRx7FIeXhclbU",
    "name": "The Medicine Shoppe®",
    "type": "pharmacy",
    "address": "2002 Grant Ave a, El Paso, TX 79930, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ43mdVKRZ54YRx7FIeXhclbU",
    "coords": [
      31.7768454,
      -106.4729295
    ],
    "phone": "(915) 544-6605"
  },
  {
    "id": "ChIJvUDTBAhb54YRAGWzBF1Gusg",
    "name": "American Star pharmacy",
    "type": "pharmacy",
    "address": "6028 Surety Dr ste a, El Paso, TX 79905, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJvUDTBAhb54YRAGWzBF1Gusg",
    "coords": [
      31.7800473,
      -106.4124242
    ],
    "phone": "(915) 706-2160"
  },
  {
    "id": "ChIJTT_0uk5a54YRMaQGnBjpKOI",
    "name": "Costco Pharmacy",
    "type": "pharmacy",
    "address": "6101 Gateway Blvd W, El Paso, TX 79925, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJTT_0uk5a54YRMaQGnBjpKOI",
    "coords": [
      31.7837261,
      -106.4102984
    ],
    "phone": "(915) 774-5203"
  },
  {
    "id": "ChIJNVDV4ORX54YRwtvS7xWt8ko",
    "name": "mesa hills pharmacy",
    "type": "pharmacy",
    "address": "310 S Mesa Hills Dr, El Paso, TX 79912, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJNVDV4ORX54YRwtvS7xWt8ko",
    "coords": [
      31.818495,
      -106.5263222
    ],
    "phone": "(915) 308-2050"
  },
  {
    "id": "ChIJ33OaJSdZ54YR6fQyRFrZOXM",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "AV. 16 DE Septiembre, Av. Benito Juárez 230, Centro, 32000 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ33OaJSdZ54YR6fQyRFrZOXM",
    "coords": [
      31.73875,
      -106.48527
    ],
    "phone": "656 632 0042"
  },
  {
    "id": "ChIJUYiYUCBZ54YRwI0kjyvJ7dI",
    "name": "Farmacias de Vanguardia S.A. de C.V.",
    "type": "pharmacy",
    "address": "Av. Benito Juárez 121, Centro, 32000 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJUYiYUCBZ54YRwI0kjyvJ7dI",
    "coords": [
      31.7426835,
      -106.4854633
    ],
    "phone": "656 614 1274"
  },
  {
    "id": "ChIJaytQTA1c54YRsPEnzUHrvSI",
    "name": "Farmacias Benavides",
    "type": "pharmacy",
    "address": "Boulevard Manuel Gómez Morín, Rincón de S. Marcos 7443, Rincones de San Marcos, 32450 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJaytQTA1c54YRsPEnzUHrvSI",
    "coords": [
      31.7216535,
      -106.4172928
    ]
  },
  {
    "id": "ChIJrRFWQmhZ54YRfPJm3kMmT20",
    "name": "Farmacias Similares \"Lo Mismo Pero Mas Barato\"",
    "type": "pharmacy",
    "address": "Córdova Americas, 32310 Ciudad Juárez, Chihuahua, Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJrRFWQmhZ54YRfPJm3kMmT20",
    "coords": [
      31.7510789,
      -106.4539962
    ]
  },
  {
    "id": "ChIJMxBLv7Fe54YRra2vIny1ST8",
    "name": "Lourdes Pharmacy",
    "type": "pharmacy",
    "address": "Calle Rubén Posada Pompa # 4308, Jardines de San José, 32390 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJMxBLv7Fe54YRra2vIny1ST8",
    "coords": [
      31.7193831,
      -106.4486369
    ],
    "phone": "656 616 6711"
  },
  {
    "id": "ChIJI4B-3ZFZ54YRuoZO69iDQMI",
    "name": "EP Scripts Pharmacy",
    "type": "pharmacy",
    "address": "4532 N Mesa St, El Paso, TX 79912, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJI4B-3ZFZ54YRuoZO69iDQMI",
    "coords": [
      31.80001679999999,
      -106.5098905
    ],
    "phone": "(915) 703-6302"
  },
  {
    "id": "ChIJ6QFjo-9g54YRX1wAY0A3ZEM",
    "name": "Farmacia del Sol S.A. de C.V.",
    "type": "pharmacy",
    "address": "Laguna de Zempoala 3851, Zona Pronaf Condominio La Plata, 32310 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ6QFjo-9g54YRX1wAY0A3ZEM",
    "coords": [
      31.7440653,
      -106.4561623
    ],
    "phone": "656 613 4528"
  },
  {
    "id": "ChIJ-fW4585e54YR547RM4xBeWs",
    "name": "Farmacia Y Clínica Veterinaria Santa Rosa",
    "type": "pharmacy",
    "address": "Ciudad Juárez, Chihuahua, Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ-fW4585e54YR547RM4xBeWs",
    "coords": [
      31.71697010000001,
      -106.471635
    ]
  },
  {
    "id": "ChIJe9bYcVv43YYRhqFyPoD0OF4",
    "name": "Walmart Pharmacy",
    "type": "pharmacy",
    "address": "7555 N Mesa St, El Paso, TX 79912, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJe9bYcVv43YYRhqFyPoD0OF4",
    "coords": [
      31.836367,
      -106.5657201
    ],
    "phone": "(915) 833-4332"
  },
  {
    "id": "ChIJycL4w0NZ54YR0Exb29RtC04",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "Av. Hermanos Escobar 2839, Americas, 32300 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJycL4w0NZ54YR0Exb29RtC04",
    "coords": [
      31.7449565,
      -106.4572918
    ]
  },
  {
    "id": "ChIJE1CuX5Zb54YRLDmyOb_BxI4",
    "name": "Soldier Family Medical Center Pharmacy",
    "type": "pharmacy",
    "address": "2496 Ricker Rd, Fort Bliss, TX 79916, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJE1CuX5Zb54YRLDmyOb_BxI4",
    "coords": [
      31.81076059999999,
      -106.419521
    ]
  },
  {
    "id": "ChIJ0WfBPiBZ54YRRfi7nO34tEk",
    "name": "Farmacias Benavides",
    "type": "pharmacy",
    "address": "Av. Benito Juárez s/n, Centro, 32000 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ0WfBPiBZ54YRRfi7nO34tEk",
    "coords": [
      31.743729,
      -106.4859549
    ],
    "phone": "656 615 6525"
  },
  {
    "id": "ChIJ_-5EpxtZ54YRe2sgCcZy1vY",
    "name": "Ngo Jenny S",
    "type": "pharmacy",
    "address": "700 S Ochoa St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ_-5EpxtZ54YRe2sgCcZy1vY",
    "coords": [
      31.75385399999999,
      -106.479722
    ],
    "phone": "(915) 545-7080"
  },
  {
    "id": "ChIJr1s-fwJZ54YRpGE8xk_JiyA",
    "name": "CVS",
    "type": "drugstore",
    "address": "201 N Mesa St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJr1s-fwJZ54YRpGE8xk_JiyA",
    "coords": [
      31.7589334,
      -106.4874018
    ],
    "phone": "(915) 533-3709"
  },
  {
    "id": "ChIJ_-5EpxtZ54YRV6ZxAzvhtfQ",
    "name": "Lucero Albert M",
    "type": "pharmacy",
    "address": "700 S Ochoa St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ_-5EpxtZ54YRV6ZxAzvhtfQ",
    "coords": [
      31.75385399999999,
      -106.479722
    ],
    "phone": "(915) 545-7080"
  },
  {
    "id": "ChIJh-lCrptZ54YRSE08oLKFGEY",
    "name": "El Paso Pain Center Pharmacy",
    "type": "pharmacy",
    "address": "3215 Gateway Blvd W suite a, El Paso, TX 79903, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJh-lCrptZ54YRSE08oLKFGEY",
    "coords": [
      31.7789526,
      -106.4559156
    ],
    "phone": "(915) 598-7246"
  },
  {
    "id": "ChIJMcxp9PJX54YRMM4UqzpmkEg",
    "name": "Rio Grande Nuclear Pharmacy",
    "type": "pharmacy",
    "address": "5360 N Mesa St # 17, El Paso, TX 79912, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJMcxp9PJX54YRMM4UqzpmkEg",
    "coords": [
      31.81739320000001,
      -106.5135938
    ],
    "phone": "(915) 584-3500"
  },
  {
    "id": "ChIJQ9f0-CFZ54YRpk7XtEBXlTk",
    "name": "Lincoln Pharmacy, S.A. De C.V.",
    "type": "pharmacy",
    "address": "Av Lerdo 729, Cuauhtémoc, 32000 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJQ9f0-CFZ54YRpk7XtEBXlTk",
    "coords": [
      31.74497289999999,
      -106.4819755
    ],
    "phone": "656 615 6323"
  },
  {
    "id": "ChIJN3pJegJZ54YRVC692nsmXko",
    "name": "Walgreens",
    "type": "drugstore",
    "address": "302 S Oregon St, El Paso, TX 79901, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJN3pJegJZ54YRVC692nsmXko",
    "coords": [
      31.7555713,
      -106.4869394
    ],
    "phone": "(915) 532-4344"
  },
  {
    "id": "ChIJ2TDI80RY54YRvkDzX5ASi-U",
    "name": "Ahmed R. Jallad, PharmD",
    "type": "pharmacy",
    "address": "2800 N Mesa St, El Paso, TX 79902, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ2TDI80RY54YRvkDzX5ASi-U",
    "coords": [
      31.7781732,
      -106.5036254
    ],
    "phone": "(915) 533-6883"
  },
  {
    "id": "ChIJM0FqNJZd54YRuWm2pwqGJbw",
    "name": "Farmacias Benavides",
    "type": "pharmacy",
    "address": "Centeno 8501, Granjero, 32690 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJM0FqNJZd54YRuWm2pwqGJbw",
    "coords": [
      31.66534020000001,
      -106.4088974
    ],
    "phone": "656 621 3104"
  },
  {
    "id": "ChIJ6wvcdale54YRm8ZWNBPxINg",
    "name": "Farmacias Benavides HERRERA",
    "type": "pharmacy",
    "address": "Av. Plutarco Elías Calles 1319, Magisterial, 32350 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ6wvcdale54YRm8ZWNBPxINg",
    "coords": [
      31.721723,
      -106.4436215
    ],
    "phone": "800 248 5555"
  },
  {
    "id": "ChIJnUYwX2xc54YRoPvDNUOP62Y",
    "name": "Farmacias Benavides CAMPESTRE",
    "type": "pharmacy",
    "address": "Blvd. Manuel Gómez Morín 7743, Rincones de San Marcos, 32450 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJnUYwX2xc54YRoPvDNUOP62Y",
    "coords": [
      31.7133502,
      -106.4102875
    ],
    "phone": "800 248 5555"
  },
  {
    "id": "ChIJC5z-jKZe54YR5faqYSHumsI",
    "name": "Farmacias Benavides PARTIDO IGLESIAS",
    "type": "pharmacy",
    "address": "Ejército Nacional 711, Partido Iglesias, 32528 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJC5z-jKZe54YR5faqYSHumsI",
    "coords": [
      31.7138204,
      -106.4451667
    ],
    "phone": "800 248 5555"
  },
  {
    "id": "ChIJneoFCzFc54YRDhBvJofKfVY",
    "name": "Farmacias Similares Cd. Juárez 22",
    "type": "pharmacy",
    "address": "Av. Tecnológico 3575-4, Rincón del Valle, 32605 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJneoFCzFc54YRDhBvJofKfVY",
    "coords": [
      31.693418,
      -106.425742
    ],
    "phone": "800 911 6666"
  },
  {
    "id": "ChIJqaYxeVtc54YR-PhkAIjyFls",
    "name": "Farmacias Benavides RIBERA LERMA",
    "type": "pharmacy",
    "address": "C, Calle Rivera del Bravo 1660, Etapa VIII, 32594 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJqaYxeVtc54YR-PhkAIjyFls",
    "coords": [
      31.70571049999999,
      -106.4025501
    ],
    "phone": "800 248 5555"
  },
  {
    "id": "ChIJB7lf1Thc54YRoXJa0gNQK6I",
    "name": "Farmacias Del Ahorro",
    "type": "pharmacy",
    "address": "32618 Ciudad Juárez, Chihuahua, Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJB7lf1Thc54YRoXJa0gNQK6I",
    "coords": [
      31.7035772,
      -106.4196918
    ]
  },
  {
    "id": "ChIJPdcFfQNc54YRgqpxXyCMTSc",
    "name": "Farmacia Guadalajara",
    "type": "pharmacy",
    "address": "Ciudad Juárez, Chihuahua, Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJPdcFfQNc54YRgqpxXyCMTSc",
    "coords": [
      31.7206521,
      -106.4350824
    ],
    "phone": "81 1254 8541"
  },
  {
    "id": "ChIJP3TdpVpc54YR1CWFMS6ut08",
    "name": "Farmacias Benavides",
    "type": "pharmacy",
    "address": "Del Fresno, Campestre 70, 32460 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJP3TdpVpc54YR1CWFMS6ut08",
    "coords": [
      31.7126711,
      -106.4095958
    ],
    "phone": "656 617 1098"
  },
  {
    "id": "ChIJybDq3mNc54YR8p3WBAO9cEc",
    "name": "Farmacias Especializadas",
    "type": "pharmacy",
    "address": "Local 14, Plaza Comercial Senecu, Av. A. J. Bermúdez 8770, Alamos de Senecú, 32472 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJybDq3mNc54YR8p3WBAO9cEc",
    "coords": [
      31.718078,
      -106.397622
    ],
    "phone": "656 618 3701"
  },
  {
    "id": "ChIJi96UdUdc54YRrIP7Ca1_54I",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "2, Ejército Nacional 7426, Pradera Dorada, 32618 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJi96UdUdc54YRrIP7Ca1_54I",
    "coords": [
      31.70164,
      -106.41583
    ],
    "phone": "656 617 7187"
  },
  {
    "id": "ChIJ2w9p1Thc54YRakBdKxjV4Z0",
    "name": "FARMACIAS DEL AHORRO",
    "type": "pharmacy",
    "address": "Ejército Nacional 7250, Pradera Dorada, Del Márquez, 32607 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ2w9p1Thc54YRakBdKxjV4Z0",
    "coords": [
      31.7042099,
      -106.4259549
    ],
    "phone": "656 204 4554"
  },
  {
    "id": "ChIJG7vrdltc54YRwQeBh1-oAO4",
    "name": "Farmacias Benavides SATELITE",
    "type": "pharmacy",
    "address": "Carretera, Juárez-porvenir 1605, Fidel Ávila, 32540 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJG7vrdltc54YRwQeBh1-oAO4",
    "coords": [
      31.705144,
      -106.4025707
    ],
    "phone": "800 248 5555"
  },
  {
    "id": "ChIJWzCq1zxc54YR2uY76z1qwOE",
    "name": "Farmacias Benavides",
    "type": "pharmacy",
    "address": "Del Márquez, 32607 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJWzCq1zxc54YR2uY76z1qwOE",
    "coords": [
      31.7053088,
      -106.4282372
    ],
    "phone": "81 1501 9207"
  },
  {
    "id": "ChIJWSYCXVNc54YRZ_TOXQvJpEA",
    "name": "Farmacias Benavides",
    "type": "drugstore",
    "address": "Ejército Nacional 711, Partido Iglesias, Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJWSYCXVNc54YRZ_TOXQvJpEA",
    "coords": [
      31.6915632,
      -106.403664
    ],
    "phone": "656 616 7100"
  },
  {
    "id": "ChIJnViWEFJc54YR7UnXcLLNGuQ",
    "name": "Farmacias Guadalajara",
    "type": "pharmacy",
    "address": "Av. Paseo de la Victoria, Misión de Los Lagos, Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJnViWEFJc54YR7UnXcLLNGuQ",
    "coords": [
      31.6938568,
      -106.4042273
    ],
    "phone": "656 648 4392"
  },
  {
    "id": "ChIJo50Lnvhc54YR2xqA1JlBD1o",
    "name": "Farmacia Guadalajara",
    "type": "pharmacy",
    "address": "Blvd. Manuel Gómez Morín 9106, Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJo50Lnvhc54YR2xqA1JlBD1o",
    "coords": [
      31.6980926,
      -106.3937907
    ],
    "phone": "656 687 6803"
  },
  {
    "id": "ChIJZyZZRjpc54YRDa763CMjwgE",
    "name": "Farmacias Benavides TECNOLOGICO CD. JUAREZ",
    "type": "pharmacy",
    "address": "Av. Tecnológico 3097, Del Márquez, 32607 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZyZZRjpc54YRDa763CMjwgE",
    "coords": [
      31.6995962,
      -106.4246392
    ],
    "phone": "800 248 5555"
  },
  {
    "id": "ChIJge2evHdd54YRQAxbJZU4JP0",
    "name": "Farmacias Benavides",
    "type": "pharmacy",
    "address": "C, Av. Manuel J. Clouthier 10110, Nuevo Juárez, 32583 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJge2evHdd54YRQAxbJZU4JP0",
    "coords": [
      31.6593717,
      -106.3913782
    ],
    "phone": "614 481 7440"
  },
  {
    "id": "ChIJp5HBlqRe54YRZH4a9yxQoEY",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "5 - 10, Av. López Mateos 2050 B, Jardines de San José, 32390 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJp5HBlqRe54YRZH4a9yxQoEY",
    "coords": [
      31.71178379999999,
      -106.4505048
    ],
    "phone": "656 611 3409"
  },
  {
    "id": "ChIJTTGK_8Ze54YRwFxZFis9_X8",
    "name": "GOD'S PHARMACY",
    "type": "pharmacy",
    "address": "Calle Grécia 398, San Antonio, 32250 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJTTGK_8Ze54YRwFxZFis9_X8",
    "coords": [
      31.7114056,
      -106.4672017
    ],
    "phone": "656 610 4045"
  },
  {
    "id": "ChIJC7Ga6uxd54YRks8dfT7Gfjc",
    "name": "Farmacia Fatima",
    "type": "pharmacy",
    "address": "Del Granjero 7513, Oasis, 32697 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJC7Ga6uxd54YRks8dfT7Gfjc",
    "coords": [
      31.65811,
      -106.4185457
    ],
    "phone": "656 620 1466"
  },
  {
    "id": "ChIJq0ukwCFc54YRILPlfS5WS8U",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "2579 B, Av Valentín Fuentes Varela, Casas Grandes, 32600 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJq0ukwCFc54YRILPlfS5WS8U",
    "coords": [
      31.70588099999999,
      -106.4366059
    ],
    "phone": "656 687 8022"
  },
  {
    "id": "ChIJF4a9lmFc54YRYe1aBO-x-m8",
    "name": "Farmalivio",
    "type": "drugstore",
    "address": "Av. Antonio, Privada Jaime Bermúdez Cuarón No. 1150, Parque Industrial Antonio, Parcelas Ejido Jesús Carranza, 32470 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJF4a9lmFc54YRYe1aBO-x-m8",
    "coords": [
      31.7170361,
      -106.3953884
    ],
    "phone": "656 625 3397"
  },
  {
    "id": "ChIJG8H7Dnhd54YRTOYbUwYv14E",
    "name": "Farmacia Hiedra",
    "type": "pharmacy",
    "address": "Hiedra 6607, Nuevo Juárez, 32583 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJG8H7Dnhd54YRTOYbUwYv14E",
    "coords": [
      31.65655060000001,
      -106.3929537
    ]
  },
  {
    "id": "ChIJ4ZHPcLRd54YRMvaHHn5ecAs",
    "name": "Farmacias del Ahorro Jazmines",
    "type": "pharmacy",
    "address": "Av. Paseo de la Victoria 3575, Partido Senecú, 32459 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ4ZHPcLRd54YRMvaHHn5ecAs",
    "coords": [
      31.693116,
      -106.405295
    ],
    "phone": "55 5593 4000"
  },
  {
    "id": "ChIJ18aFQHdd54YRN7DTtIfrn4M",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "Av. Manuel J. Clouthier 10117, Salvarcar, 32580 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ18aFQHdd54YRN7DTtIfrn4M",
    "coords": [
      31.65940389999999,
      -106.3888954
    ],
    "phone": "656 624 0341"
  },
  {
    "id": "ChIJZetsQ8Rd54YRgTdoPSCNmZs",
    "name": "Farmacia Carolina No 2 Servicios Medicos",
    "type": "pharmacy",
    "address": "Del Real, Ciudad Juárez, Chihuahua, Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZetsQ8Rd54YRgTdoPSCNmZs",
    "coords": [
      31.67149479999999,
      -106.4249436
    ]
  },
  {
    "id": "ChIJAQAAwElc54YR_JgMwaLi58c",
    "name": "Pharmacy Pradera Dorada",
    "type": "drugstore",
    "address": "Calle Rancho Aguacaliente 3005, Pradera Dorada, 32618 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJAQAAwElc54YR_JgMwaLi58c",
    "coords": [
      31.702028,
      -106.418719
    ],
    "phone": "656 623 1946"
  },
  {
    "id": "ChIJhyvIGOhd54YR2SnPVGb7uXg",
    "name": "Farmacia Similares",
    "type": "pharmacy",
    "address": "CLL. Yucatan, 6811, Villa DEL Real, Juarez, Aeropuerto, 32380 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJhyvIGOhd54YR2SnPVGb7uXg",
    "coords": [
      31.66362,
      -106.42743
    ],
    "phone": "656 637 7570"
  },
  {
    "id": "ChIJg0KUi7hd54YRLzzxaqSQDWM",
    "name": "Farmacias De Similares CD. JUAREZ 11",
    "type": "pharmacy",
    "address": "CLL. Morelia, 7320 LC 1,2 Y 3, Erendira, Juarez, Eréndira, 32662 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJg0KUi7hd54YRLzzxaqSQDWM",
    "coords": [
      31.6735201,
      -106.415818
    ],
    "phone": "656 619 8138"
  },
  {
    "id": "ChIJB-YiGGlc54YRnN2jE50-PY8",
    "name": "Farmacias Benavides FRESNO",
    "type": "pharmacy",
    "address": "Fresno 170, Campestre, 32457 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJB-YiGGlc54YRnN2jE50-PY8",
    "coords": [
      31.7148558,
      -106.4088955
    ],
    "phone": "800 248 5555"
  },
  {
    "id": "ChIJiarXScJd54YRgJxB08RDLwQ",
    "name": "Farmacias Benavides",
    "type": "pharmacy",
    "address": "Avenida las Granjas 6523, Granjas Polo Gamboa, 32697 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJiarXScJd54YRgJxB08RDLwQ",
    "coords": [
      31.6572584,
      -106.41396
    ]
  },
  {
    "id": "ChIJAZ6BiWlc54YRI_BepNSiI34",
    "name": "FARMACIAS SIMILARES",
    "type": "pharmacy",
    "address": "A 1, Blvd. Manuel Gómez Morín 7950, Partido Senecú, 32459 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJAZ6BiWlc54YRI_BepNSiI34",
    "coords": [
      31.7123567,
      -106.410941
    ]
  },
  {
    "id": "ChIJTyWwqrJf54YRoYqZY55xVnQ",
    "name": "Farmacia Guadalajara Suc. Oscar flores",
    "type": "pharmacy",
    "address": "Zaragoza, 32700 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJTyWwqrJf54YRoYqZY55xVnQ",
    "coords": [
      31.656654,
      -106.4430289
    ]
  },
  {
    "id": "ChIJfYpfQDVc54YRQqdGXEm9Ufw",
    "name": "Farmacia Santa Lucía",
    "type": "pharmacy",
    "address": "Fuente de Diana 3909, Jardines del Lago, 32616 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJfYpfQDVc54YRQqdGXEm9Ufw",
    "coords": [
      31.6883505,
      -106.4179289
    ],
    "phone": "656 304 8601"
  },
  {
    "id": "ChIJvzsc6lFc54YRbj9DHGjgbxw",
    "name": "Farmacia Milagrosa",
    "type": "pharmacy",
    "address": "Av. Paseo de la Victoria, Moradas del Porvenir, 32545 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJvzsc6lFc54YRbj9DHGjgbxw",
    "coords": [
      31.69651309999999,
      -106.4035363
    ]
  },
  {
    "id": "ChIJ0wnKG2tc54YRDZLj1Amd_Hg",
    "name": "Farmacias Guadalajara Suc. Pedro Rosales",
    "type": "drugstore",
    "address": "Calle Pedro Rosales de León 7851, San Jerónimo, 32505 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ0wnKG2tc54YRDZLj1Amd_Hg",
    "coords": [
      31.7132493,
      -106.4150991
    ]
  },
  {
    "id": "ChIJSTaC1ARc54YRu7kYNZ2V7_k",
    "name": "Farmacia Moris",
    "type": "pharmacy",
    "address": "Av. Valentín Fuentes Varela s/n, Villa Hermosa, Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJSTaC1ARc54YRu7kYNZ2V7_k",
    "coords": [
      31.71896559999999,
      -106.4321119
    ],
    "phone": "656 617 7501"
  },
  {
    "id": "ChIJDwH0gspe54YRhfyOL16k0Ak",
    "name": "Farmacia Revolución",
    "type": "pharmacy",
    "address": "Álavaro Obregón 2187, Ex Hipódromo, 32320 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJDwH0gspe54YRhfyOL16k0Ak",
    "coords": [
      31.7234274,
      -106.4642601
    ],
    "phone": "656 614 7331"
  },
  {
    "id": "ChIJiZEztsJd54YRsIokGaAV_JE",
    "name": "Farmacia de Similares",
    "type": "pharmacy",
    "address": "Calle Centeno 9020, El Granjero, 32690 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJiZEztsJd54YRsIokGaAV_JE",
    "coords": [
      31.6651782,
      -106.4282682
    ]
  },
  {
    "id": "ChIJ1Uo4q4pn54YR57IYbp5ynj4",
    "name": "Farmacia Homeopática Naturista El Buen Trato",
    "type": "drugstore",
    "address": "Av. Manuel J. Clouthier 8650, Eréndira, 32690 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ1Uo4q4pn54YR57IYbp5ynj4",
    "coords": [
      31.66644980000001,
      -106.4033825
    ],
    "phone": "656 620 7772"
  },
  {
    "id": "ChIJAQAAQMFd54YR6fPpfNxySv4",
    "name": "Farmacia Oscar",
    "type": "pharmacy",
    "address": "Iztlacihúatl 5710, Del Real, 32660 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJAQAAQMFd54YR6fPpfNxySv4",
    "coords": [
      31.6692109,
      -106.4185283
    ],
    "phone": "656 170 3749"
  },
  {
    "id": "ChIJaSiRMk9c54YRdCKKsEQDDEg",
    "name": "Farmacia Del Sol",
    "type": "pharmacy",
    "address": "Plaza Capri, Ejército Nacional 8258, Partido Iglesias, 32618 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJaSiRMk9c54YRdCKKsEQDDEg",
    "coords": [
      31.6971301,
      -106.4109225
    ]
  },
  {
    "id": "ChIJgzdQcjBd54YR2ZRI-uVpcek",
    "name": "Farmacias del Ahorro Tecnologico 2",
    "type": "drugstore",
    "address": "Av. Tecnológico 3411, Rincones Del Valle, 32605 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJgzdQcjBd54YR2ZRI-uVpcek",
    "coords": [
      31.69463510000001,
      -106.4248752
    ],
    "phone": "656 611 4522"
  },
  {
    "id": "ChIJPWECGsde54YRnHXn5gXsKFs",
    "name": "Farmacia El Mirador",
    "type": "pharmacy",
    "address": "Calle Cartagena 6210, El Mirador, 32260 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJPWECGsde54YRnHXn5gXsKFs",
    "coords": [
      31.7104882,
      -106.4663875
    ],
    "phone": "656 610 3018"
  },
  {
    "id": "ChIJk-J_n7Fe54YRIDS-nCvsb1U",
    "name": "Farmacia París",
    "type": "pharmacy",
    "address": "Local 7, Calle Efrén Ornelas 1526, Melchor Ocampo, 32380 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJk-J_n7Fe54YRIDS-nCvsb1U",
    "coords": [
      31.7205391,
      -106.4558821
    ],
    "phone": "656 613 4434"
  },
  {
    "id": "ChIJIbbuNStc54YRvmImBDZrk8I",
    "name": "Farmacia Genéricos y Similares",
    "type": "pharmacy",
    "address": "Blvd. Oscar Flores 4735, La Cuesta, 32650 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJIbbuNStc54YRvmImBDZrk8I",
    "coords": [
      31.68530359999999,
      -106.4362374
    ],
    "phone": "656 120 1080"
  },
  {
    "id": "ChIJAw7q1phd54YRPa42vTeoKy4",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "Calle Centeno 9020, Granjero, 32690 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJAw7q1phd54YRPa42vTeoKy4",
    "coords": [
      31.6651151,
      -106.3998447
    ]
  },
  {
    "id": "ChIJ2fu9GnZd54YRiH_W6tU7wwU",
    "name": "Farmacia Linda",
    "type": "pharmacy",
    "address": "Av. Manuel J. Clouthier 5816, Ampliación Aeropuerto, Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ2fu9GnZd54YRiH_W6tU7wwU",
    "coords": [
      31.6622316,
      -106.3928088
    ],
    "phone": "656 626 0736"
  },
  {
    "id": "ChIJzW4wf7Ze54YRb-1mGJBx_WY",
    "name": "Farmacia MS",
    "type": "pharmacy",
    "address": "Blvd. Municipio Libre 1399, Melchor Ocampo, 32380 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJzW4wf7Ze54YRb-1mGJBx_WY",
    "coords": [
      31.7203549,
      -106.4615273
    ],
    "phone": "656 251 0363"
  },
  {
    "id": "ChIJ38foMNhd54YRVNJ2u5tiXNg",
    "name": "Super y Farmacias los Pinos",
    "type": "pharmacy",
    "address": "Antigua Carretera Panamericana 228, Samalayuca, 32730 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ38foMNhd54YRVNJ2u5tiXNg",
    "coords": [
      31.6727296,
      -106.4382468
    ],
    "phone": "656 664 5265"
  },
  {
    "id": "ChIJqYKqWpJd54YRSsGuZTOLvCs",
    "name": "Farmacia D'nayeli",
    "type": "pharmacy",
    "address": "Del Granjero 7910, Oasis, 32697 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJqYKqWpJd54YRSsGuZTOLvCs",
    "coords": [
      31.65681949999999,
      -106.4133645
    ]
  },
  {
    "id": "ChIJ____r7le54YRFlmCplBKtBA",
    "name": "Farmacia Homeopatica",
    "type": "pharmacy",
    "address": "Eje Juan Gabriel SN, Barreal, 32040 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ____r7le54YRFlmCplBKtBA",
    "coords": [
      31.7129979,
      -106.4604647
    ],
    "phone": "656 614 7275"
  },
  {
    "id": "ChIJS3pfV6pe54YR_UR2ipOeJjY",
    "name": "Farmacia Central",
    "type": "pharmacy",
    "address": "Ejido 807, Anáhuac Centro, 31600 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJS3pfV6pe54YR_UR2ipOeJjY",
    "coords": [
      31.7254498,
      -106.4427979
    ],
    "phone": "625 585 0342"
  },
  {
    "id": "ChIJP2GK-A1d54YRmLfWfXaZBrY",
    "name": "Farmacias Guadalajara",
    "type": "pharmacy",
    "address": "Chihuahua - Miguel Ahumada 5055, Comunitario Jarudo, 62659 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJP2GK-A1d54YRmLfWfXaZBrY",
    "coords": [
      31.6825113,
      -106.4248593
    ]
  },
  {
    "id": "ChIJpzin355d54YR7Zb7PnI17z0",
    "name": "Farmacia Guadalajara",
    "type": "drugstore",
    "address": "Av. Manuel J. Clouthier 9082, Granjero, 32690 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJpzin355d54YR7Zb7PnI17z0",
    "coords": [
      31.6649444,
      -106.3981248
    ],
    "phone": "656 285 9634"
  },
  {
    "id": "ChIJNTzZ7M5d54YR5CAsrdSa_yo",
    "name": "Farmacias Similares",
    "type": "pharmacy",
    "address": "Av. Tecnológico 5217, Comunitario Jarudo, 62659 Cd Juárez, Chih., Mexico",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJNTzZ7M5d54YR5CAsrdSa_yo",
    "coords": [
      31.67947449999999,
      -106.4248854
    ]
  },
  {
    "id": "ChIJGz88zk893oYR6mAxkNAz5qk",
    "name": "Kara Taylor, Pharm",
    "type": "pharmacy",
    "address": "3990 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJGz88zk893oYR6mAxkNAz5qk",
    "coords": [
      32.3196116,
      -106.7320333
    ],
    "phone": "(575) 522-1457"
  },
  {
    "id": "ChIJOcGKlWY93oYRWtqEq4R5qBE",
    "name": "Tammie Saulsberry, RPH",
    "type": "pharmacy",
    "address": "2300 E Lohman Ave, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJOcGKlWY93oYRWtqEq4R5qBE",
    "coords": [
      32.3124824,
      -106.7515691
    ],
    "phone": "(505) 647-2506"
  },
  {
    "id": "ChIJr0qoXOI93oYRqCYtL5qLCzQ",
    "name": "Jeanne Sill, RPh",
    "type": "pharmacy",
    "address": "2700 W Picacho Ave, Las Cruces, NM 88007, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJr0qoXOI93oYRqCYtL5qLCzQ",
    "coords": [
      32.31150700000001,
      -106.8158854
    ],
    "phone": "(575) 523-0833"
  },
  {
    "id": "ChIJ-VSVqNQX3oYRFELBG5DNMoc",
    "name": "Linda Vanselous, RPH",
    "type": "pharmacy",
    "address": "1285 El Paseo Rd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ-VSVqNQX3oYRFELBG5DNMoc",
    "coords": [
      32.2981,
      -106.77
    ],
    "phone": "(575) 541-1264"
  },
  {
    "id": "ChIJvyYjL6o93oYRpAGHEfxjHoo",
    "name": "Jana M. Behrens, Pharm",
    "type": "pharmacy",
    "address": "Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJvyYjL6o93oYRpAGHEfxjHoo",
    "coords": [
      32.34180660000001,
      -106.7734568
    ],
    "phone": "(575) 525-0298"
  },
  {
    "id": "ChIJGz88zk893oYRJv8Q71KVYQw",
    "name": "Jason P. Drozda, PharmD",
    "type": "pharmacy",
    "address": "3990 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJGz88zk893oYRJv8Q71KVYQw",
    "coords": [
      32.3196116,
      -106.7320333
    ],
    "phone": "(575) 522-1457"
  },
  {
    "id": "ChIJOcGKlWY93oYR6NVNSs9mhYo",
    "name": "Rosemarie Janowski, RPH",
    "type": "pharmacy",
    "address": "2300 E Lohman Ave, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJOcGKlWY93oYR6NVNSs9mhYo",
    "coords": [
      32.3124824,
      -106.7515691
    ],
    "phone": "(575) 647-2506"
  },
  {
    "id": "ChIJI3oQrtQX3oYR83_jdO7nB70",
    "name": "Albertsons Pharmacy",
    "type": "pharmacy",
    "address": "1285 S El Paseo Rd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJI3oQrtQX3oYR83_jdO7nB70",
    "coords": [
      32.29831,
      -106.7702395
    ],
    "phone": "(575) 541-1264"
  },
  {
    "id": "ChIJZ_7I2Fw93oYRpYfbl2GaWKY",
    "name": "Albertsons Pharmacy",
    "type": "pharmacy",
    "address": "2551 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZ_7I2Fw93oYRpYfbl2GaWKY",
    "coords": [
      32.3172719,
      -106.7433411
    ],
    "phone": "(575) 521-9841"
  },
  {
    "id": "ChIJOcGKlWY93oYRe_yKYtnI0js",
    "name": "Virginia Robles, PharmD",
    "type": "pharmacy",
    "address": "2300 E Lohman Ave, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJOcGKlWY93oYRe_yKYtnI0js",
    "coords": [
      32.3124824,
      -106.7515691
    ],
    "phone": "(575) 647-2506"
  },
  {
    "id": "ChIJQ0i1smc93oYRmCUA8uhlMys",
    "name": "Walmart Pharmacy",
    "type": "pharmacy",
    "address": "571 Walton Blvd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJQ0i1smc93oYRmCUA8uhlMys",
    "coords": [
      32.314858,
      -106.749921
    ],
    "phone": "(575) 524-3501"
  },
  {
    "id": "ChIJl8dxW-I93oYR-t01MnJz2lE",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "2700 W Picacho Ave, Las Cruces, NM 88007, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJl8dxW-I93oYR-t01MnJz2lE",
    "coords": [
      32.31129420000001,
      -106.8158278
    ],
    "phone": "(575) 523-0833"
  },
  {
    "id": "ChIJBcS53joW3oYRPKbzLRuLums",
    "name": "Walmart Pharmacy",
    "type": "pharmacy",
    "address": "1550 S Valley Dr, Las Cruces, NM 88005, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJBcS53joW3oYRPKbzLRuLums",
    "coords": [
      32.28873379999999,
      -106.7849328
    ],
    "phone": "(575) 523-6844"
  },
  {
    "id": "ChIJlTTt2f483oYR5UVGUYG6w4s",
    "name": "Del-Rey Pharmacy",
    "type": "pharmacy",
    "address": "3291 Del Rey Blvd, Las Cruces, NM 88012, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJlTTt2f483oYR5UVGUYG6w4s",
    "coords": [
      32.3524634,
      -106.7674489
    ],
    "phone": "(575) 888-4756"
  },
  {
    "id": "ChIJIxV-WEU93oYRcjkS7RB-xtA",
    "name": "Healthcare pharmacy",
    "type": "pharmacy",
    "address": "3865 E Lohman Ave #2, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJIxV-WEU93oYRcjkS7RB-xtA",
    "coords": [
      32.3199403,
      -106.7352763
    ],
    "phone": "(800) 596-5506"
  },
  {
    "id": "ChIJRwVt8Ks93oYRxc-CfOIMwZ0",
    "name": "CVS Pharmacy",
    "type": "pharmacy",
    "address": "3011 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJRwVt8Ks93oYRxc-CfOIMwZ0",
    "coords": [
      32.3419092,
      -106.7757241
    ],
    "phone": "(575) 647-8878"
  },
  {
    "id": "ChIJvyYjL6o93oYRntFwzxPnt5Y",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "3100 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJvyYjL6o93oYRntFwzxPnt5Y",
    "coords": [
      32.3419049,
      -106.7737121
    ],
    "phone": "(575) 525-0298"
  },
  {
    "id": "ChIJtUd2Kk493oYR--61I144jSU",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "3990 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJtUd2Kk493oYR--61I144jSU",
    "coords": [
      32.3195354,
      -106.7320336
    ],
    "phone": "(575) 522-1457"
  },
  {
    "id": "ChIJo3AoZSsW3oYRn_W9Gt0KhCw",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "1256 El Paseo Rd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJo3AoZSsW3oYRn_W9Gt0KhCw",
    "coords": [
      32.2979661,
      -106.7728642
    ],
    "phone": "(575) 525-8713"
  },
  {
    "id": "ChIJgW4nv2Y93oYRWbQxsFwl7ns",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "2300 E Lohman Ave, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJgW4nv2Y93oYRWbQxsFwl7ns",
    "coords": [
      32.31245019999999,
      -106.7516331
    ],
    "phone": "(575) 647-2506"
  },
  {
    "id": "ChIJKRR0XisW3oYR4w-infvgHEo",
    "name": "Travis P. Chlumsky, Pharm",
    "type": "pharmacy",
    "address": "1256 El Paseo Rd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJKRR0XisW3oYR4w-infvgHEo",
    "coords": [
      32.2980193,
      -106.7728116
    ],
    "phone": "(575) 525-8713"
  },
  {
    "id": "ChIJv0IOxbw93oYRNyDK1itPjd4",
    "name": "Compounding Specialists",
    "type": "pharmacy",
    "address": "2140 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJv0IOxbw93oYRNyDK1itPjd4",
    "coords": [
      32.32741439999999,
      -106.7828115
    ],
    "phone": "(575) 523-5067"
  },
  {
    "id": "ChIJZ_7I2Fw93oYRhP85P7Iloug",
    "name": "Lawrence Q. Escudero Jr, Pharm",
    "type": "pharmacy",
    "address": "2551 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZ_7I2Fw93oYRhP85P7Iloug",
    "coords": [
      32.3173707,
      -106.7434597
    ],
    "phone": "(575) 521-9841"
  },
  {
    "id": "ChIJdUMNdu4X3oYRyKHEMXo-mBw",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "2450 S Telshor Blvd, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJdUMNdu4X3oYRyKHEMXo-mBw",
    "coords": [
      32.2913839,
      -106.7371286
    ],
    "phone": "(224) 323-5065"
  },
  {
    "id": "ChIJF03nLOA83oYRTtHRHZKiHvc",
    "name": "Pecos Valley Pharmaceuticals",
    "type": "drugstore",
    "address": "2460 S Locust St I, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJF03nLOA83oYRTtHRHZKiHvc",
    "coords": [
      32.35064059999999,
      -106.7498142
    ]
  },
  {
    "id": "ChIJjTgXaFI93oYR-eqvdJr5cUc",
    "name": "RX Innovations",
    "type": "pharmacy",
    "address": "151 S Walnut St Suite A5, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJjTgXaFI93oYR-eqvdJr5cUc",
    "coords": [
      32.3152166,
      -106.7554973
    ],
    "phone": "(575) 288-1412"
  },
  {
    "id": "ChIJ9TeOzwE93oYRAsTcBfhSGv8",
    "name": "Aaron P. Wilson, RPh",
    "type": "pharmacy",
    "address": "2711 N Telshor Blvd, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ9TeOzwE93oYRAsTcBfhSGv8",
    "coords": [
      32.3473183,
      -106.766775
    ],
    "phone": "(575) 521-7890"
  },
  {
    "id": "ChIJZ_7I2Fw93oYRk2x9U1PZgXA",
    "name": "Andrea N. Pacheco, RPH",
    "type": "pharmacy",
    "address": "2551 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZ_7I2Fw93oYRk2x9U1PZgXA",
    "coords": [
      32.3173707,
      -106.7434597
    ],
    "phone": "(505) 521-9841"
  },
  {
    "id": "ChIJ4wwkv2Y93oYRgimmo9IuztU",
    "name": "Walgreens",
    "type": "pharmacy",
    "address": "2300 E Lohman Ave & Walton, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ4wwkv2Y93oYRgimmo9IuztU",
    "coords": [
      32.3125555,
      -106.7514663
    ],
    "phone": "(575) 647-2506"
  },
  {
    "id": "ChIJAQCQLKo93oYRYPF4iG2RXx4",
    "name": "Walgreens",
    "type": "pharmacy",
    "address": "3100 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJAQCQLKo93oYRYPF4iG2RXx4",
    "coords": [
      32.34160029999999,
      -106.7734495
    ],
    "phone": "(575) 525-0298"
  },
  {
    "id": "ChIJm-6Jgo893oYRW3ejDADSFzQ",
    "name": "Kathleen L. Mclane, RPH",
    "type": "pharmacy",
    "address": "415 W Las Cruces Ave, Las Cruces, NM 88005, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJm-6Jgo893oYRW3ejDADSFzQ",
    "coords": [
      32.3099933,
      -106.7829728
    ],
    "phone": "(505) 523-6317"
  },
  {
    "id": "ChIJvyYjL6o93oYRWZQOF47NRXU",
    "name": "Brent J. Barrow, PharmD",
    "type": "pharmacy",
    "address": "3100 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJvyYjL6o93oYRWZQOF47NRXU",
    "coords": [
      32.34180660000001,
      -106.7734568
    ],
    "phone": "(575) 525-0298"
  },
  {
    "id": "ChIJ3VeDM1o93oYRxK39323DZPg",
    "name": "Allen E. Plymale, RPH",
    "type": "pharmacy",
    "address": "3530 Foothills Rd, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ3VeDM1o93oYRxK39323DZPg",
    "coords": [
      32.31343740000001,
      -106.7385297
    ],
    "phone": "(505) 544-7507"
  },
  {
    "id": "ChIJZRR0XisW3oYRlklHFqdBOQ8",
    "name": "Craig M. Lewandowski, RPH",
    "type": "pharmacy",
    "address": "1256 El Paseo Rd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZRR0XisW3oYRlklHFqdBOQ8",
    "coords": [
      32.2980193,
      -106.7728116
    ],
    "phone": "(575) 525-8708"
  },
  {
    "id": "ChIJy-z83tYX3oYRaUIG1QpeMNw",
    "name": "Robert F. Adams, RPh",
    "type": "pharmacy",
    "address": "1205 S Solano Dr, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJy-z83tYX3oYRaUIG1QpeMNw",
    "coords": [
      32.3000581,
      -106.762098
    ],
    "phone": "(505) 526-1599"
  },
  {
    "id": "ChIJlZ9oeqM93oYROql7GhE3PnQ",
    "name": "Las Cruces Apothecary",
    "type": "pharmacy",
    "address": "525 E Madrid Ave #8, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJlZ9oeqM93oYROql7GhE3PnQ",
    "coords": [
      32.3289856,
      -106.7807488
    ],
    "phone": "(575) 541-5030"
  },
  {
    "id": "ChIJEWgOxbw93oYRHiGEQ9C5gXU",
    "name": "Alegra T. Alexander, RPH",
    "type": "pharmacy",
    "address": "Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJEWgOxbw93oYRHiGEQ9C5gXU",
    "coords": [
      32.32741439999999,
      -106.7828115
    ],
    "phone": "(575) 523-5544"
  },
  {
    "id": "ChIJvyYjL6o93oYRkTE34dXT5hU",
    "name": "Nicole D. Lucas, Pharm",
    "type": "pharmacy",
    "address": "3100 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJvyYjL6o93oYRkTE34dXT5hU",
    "coords": [
      32.34180660000001,
      -106.7734568
    ],
    "phone": "(575) 525-0298"
  },
  {
    "id": "ChIJB2lq2gE93oYRzmd-MD9HQng",
    "name": "Sam's Club Pharmacy",
    "type": "pharmacy",
    "address": "2711 N Telshor Blvd, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJB2lq2gE93oYRzmd-MD9HQng",
    "coords": [
      32.3473768,
      -106.7668722
    ],
    "phone": "(575) 521-7890"
  },
  {
    "id": "ChIJKRR0XisW3oYRiS1ZfMzI9NE",
    "name": "Eugene B. Liberace, RPH",
    "type": "pharmacy",
    "address": "1256 El Paseo Rd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJKRR0XisW3oYRiS1ZfMzI9NE",
    "coords": [
      32.2980193,
      -106.7728116
    ],
    "phone": "(575) 525-8713"
  },
  {
    "id": "ChIJl5xXU6o93oYR8eWmx1pq6V0",
    "name": "Animal Hospital and Emergency Center of Las Cruces",
    "type": "pharmacy",
    "address": "3171 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJl5xXU6o93oYR8eWmx1pq6V0",
    "coords": [
      32.344213,
      -106.7741019
    ],
    "phone": "(575) 541-6610"
  },
  {
    "id": "ChIJZRR0XisW3oYRWYft_OgdO8c",
    "name": "Walgreens",
    "type": "drugstore",
    "address": "1256 El Paseo Rd, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZRR0XisW3oYRWYft_OgdO8c",
    "coords": [
      32.2979797,
      -106.7728159
    ],
    "phone": "(575) 525-8713"
  },
  {
    "id": "ChIJOcGKlWY93oYRu3vSJ6v2s70",
    "name": "Walgreens",
    "type": "drugstore",
    "address": "2300 E Lohman Ave, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJOcGKlWY93oYRu3vSJ6v2s70",
    "coords": [
      32.31242520000001,
      -106.7516291
    ],
    "phone": "(575) 647-2506"
  },
  {
    "id": "ChIJGz88zk893oYRWRTFDY-Uv5k",
    "name": "Walgreens",
    "type": "drugstore",
    "address": "3990 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJGz88zk893oYRWRTFDY-Uv5k",
    "coords": [
      32.3196916,
      -106.7321694
    ],
    "phone": "(575) 522-1457"
  },
  {
    "id": "ChIJ5fnpDRA93oYRK-5rDXgeXF8",
    "name": "Pecos valey productions",
    "type": "pharmacy",
    "address": "3050 Roadrunner Pkwy #3, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ5fnpDRA93oYRK-5rDXgeXF8",
    "coords": [
      32.3575327,
      -106.7561228
    ]
  },
  {
    "id": "ChIJu8eF4as93oYRnyzjoEkZ_fc",
    "name": "CVS",
    "type": "drugstore",
    "address": "3011 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJu8eF4as93oYRnyzjoEkZ_fc",
    "coords": [
      32.34182500000001,
      -106.775908
    ],
    "phone": "(575) 647-8878"
  },
  {
    "id": "ChIJLedVuMEX3oYRUJWlZ5cvxaM",
    "name": "Pecos Valley Production",
    "type": "drugstore",
    "address": "2460 S Locust St I, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJLedVuMEX3oYRUJWlZ5cvxaM",
    "coords": [
      32.2878706,
      -106.748697
    ],
    "phone": "(575) 571-4351"
  },
  {
    "id": "ChIJJ0Q580w93oYRJQIP5PV35LY",
    "name": "ASP Cares",
    "type": "pharmacy",
    "address": "4371 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJJ0Q580w93oYRJQIP5PV35LY",
    "coords": [
      32.3246495,
      -106.7262982
    ],
    "phone": "(575) 532-8919"
  },
  {
    "id": "ChIJRVvaC6c83oYRxJvV9y9l7QE",
    "name": "Walmart Pharmacy",
    "type": "pharmacy",
    "address": "150 Sonoma Ranch Blvd, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJRVvaC6c83oYRxJvV9y9l7QE",
    "coords": [
      32.3281134,
      -106.7215304
    ],
    "phone": "(575) 323-6097"
  },
  {
    "id": "ChIJw4f8xDM93oYRMXdzZ1VUxz4",
    "name": "CVS",
    "type": "drugstore",
    "address": "940 N Main St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJw4f8xDM93oYRMXdzZ1VUxz4",
    "coords": [
      32.3170013,
      -106.7800004
    ],
    "phone": "(575) 524-5900"
  },
  {
    "id": "ChIJr5lUN_AX3oYR1mWYy22Dk6U",
    "name": "Vital Care of Las Cruces",
    "type": "pharmacy",
    "address": "1680 Hickory Loop, Las Cruces, NM 88005, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJr5lUN_AX3oYR1mWYy22Dk6U",
    "coords": [
      32.2924597,
      -106.7915128
    ],
    "phone": "(575) 636-1570"
  },
  {
    "id": "ChIJdT9zEug83oYRRZPa1yiYCIE",
    "name": "Walgreens Pharmacy",
    "type": "pharmacy",
    "address": "3375 Rinconada Blvd, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJdT9zEug83oYRRZPa1yiYCIE",
    "coords": [
      32.3622513,
      -106.7481767
    ],
    "phone": "(575) 382-9100"
  },
  {
    "id": "ChIJyVrnEeg83oYRQX_TC3Q06uo",
    "name": "Walmart Pharmacy",
    "type": "pharmacy",
    "address": "3331 Rinconada Blvd, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJyVrnEeg83oYRQX_TC3Q06uo",
    "coords": [
      32.3607838,
      -106.7462098
    ],
    "phone": "(575) 680-3779"
  },
  {
    "id": "ChIJ95NWpoc93oYRywxFcwDbbxg",
    "name": "Sacred Garden dispensary",
    "type": "pharmacy",
    "address": "642 S Alameda Blvd, Las Cruces, NM 88005, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ95NWpoc93oYRywxFcwDbbxg",
    "coords": [
      32.3039358,
      -106.7797055
    ],
    "phone": "(575) 300-0154"
  },
  {
    "id": "ChIJlw0EFnAX3oYRltrutKWg4nc",
    "name": "Mesilla Valley Pharmacy & Consulting",
    "type": "pharmacy",
    "address": "100 Wyatt Dr STE B, Las Cruces, NM 88005, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJlw0EFnAX3oYRltrutKWg4nc",
    "coords": [
      32.2986355,
      -106.7788714
    ],
    "phone": "(575) 323-2093"
  },
  {
    "id": "ChIJmzoW21w93oYR6bpFEHj0cE4",
    "name": "Sav-On Pharmacy",
    "type": "pharmacy",
    "address": "Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJmzoW21w93oYR6bpFEHj0cE4",
    "coords": [
      32.31706749999999,
      -106.743463
    ]
  },
  {
    "id": "ChIJT_C9S1k93oYRC3Giihx3FG0",
    "name": "Dispensary",
    "type": "pharmacy",
    "address": "Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJT_C9S1k93oYRC3Giihx3FG0",
    "coords": [
      32.3116925,
      -106.740906
    ],
    "phone": "(575) 541-5580"
  },
  {
    "id": "ChIJjx0Jp3893oYR1JPxttpw7fw",
    "name": "Riveras",
    "type": "pharmacy",
    "address": "635 S Almendra St, Las Cruces, NM 88001, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJjx0Jp3893oYR1JPxttpw7fw",
    "coords": [
      32.3061708,
      -106.7686336
    ]
  },
  {
    "id": "ChIJZ_7I2Fw93oYR5GGp_dwSFVw",
    "name": "Wood Megan",
    "type": "pharmacy",
    "address": "2551 E Lohman Ave, Las Cruces, NM 88011, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJZ_7I2Fw93oYR5GGp_dwSFVw",
    "coords": [
      32.3175219,
      -106.7433874
    ],
    "phone": "(505) 521-9841"
  },
  {
    "id": "ChIJT6FMbYg93oYRM_esgeluOPY",
    "name": "Respond New Mexico",
    "type": "pharmacy",
    "address": "655 El Molino Blvd, Las Cruces, NM 88005, USA",
    "googlink": "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJT6FMbYg93oYRM_esgeluOPY",
    "coords": [
      32.3026107,
      -106.7836816
    ],
    "phone": "(575) 527-2400"
  }
]

export const foodBanks = []

export const handWashStations = [
  {
    area: 'ELP',
    name: '1081 E Missouri Ave',
    desc: 'Corner of E Missouri & N Cotton',
    googlink: 'https://goo.gl/maps/TWapFxG3nTD7hkNCA',
    coords: [31.772271, -106.473594],
    contact: 'gilbert.perezh24@yahoo.com',
    contactdesc: 'Alliance of Border Collaboratives'
  },
  {
    area: 'ELP',
    name: '398 Yandell Dr',
    desc: 'Gateway West 19A off-ramp',
    googlink: 'https://goo.gl/maps/ozfnUdRAky6vVUUx8',
    coords: [31.764232, -106.489151],
    contact: 'gilbert.perezh24@yahoo.com',
    contactdesc: 'Alliance of Border Collaboratives'
  },
  {
    area: 'ELP',
    name: '801 N Kansas St',
    desc: 'Corner of N Kansas & Yandell',
    googlink: 'https://goo.gl/maps/z2adE8caHGLRfbXU9',
    coords: [31.764308, -106.489462],
    contact: 'gilbert.perezh24@yahoo.com',
    contactdesc: 'Alliance of Border Collaboratives'
  },
  {
    area: 'ELP',
    name: '1025 Myrtle Ave',
    desc: 'Magoffin Park',
    googlink: 'https://goo.gl/maps/kctcaArBEyiyBRHg6',
    coords: [31.763284, -106.478690],
    contact: 'gilbert.perezh24@yahoo.com',
    contactdesc: 'Alliance of Border Collaboratives'
  },
  {
    area: 'ELP',
    name: '1208 Myrtle Ave',
    desc: 'Opportunity Center, four set up at this location',
    googlink: 'https://goo.gl/maps/1NmxqAqcM87wh7FE7',
    coords: [31.764071, -106.476617],
    contact: 'gilbert.perezh24@yahoo.com',
    contactdesc: 'Alliance of Border Collaboratives'
  },
]

