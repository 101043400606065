import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import LinkedInPopUp from "./components/LPopUp";
import Sales from "./containers/Sales";
import Marketing from "./containers/Marketing";
import Traffic from "./containers/Traffic";
import CRM from "./containers/CRM";
import Cal from "./containers/Cal";
import COVIDAdmin from "./containers/COVIDAdmin";
import CuatroCAdmin from "./containers/CuatroCAdmin";
import InvoicingAR from "./containers/InvoicingAR";
import Products from "./containers/Products";
import Purchasing from "./containers/Purchasing";

export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/sales" exact component={Sales} props={childProps} />
    <AuthenticatedRoute path="/marketing" exact component={Marketing} props={childProps} />
    <AuthenticatedRoute path="/traffic" exact component={Traffic} props={childProps} />
    <AuthenticatedRoute path="/crm" exact component={CRM} props={childProps} />
    <AuthenticatedRoute path="/calendar" exact component={Cal} props={childProps} />
    <AuthenticatedRoute path="/covidadmin" exact component={COVIDAdmin} props={childProps} />
    <AuthenticatedRoute path="/cuatrocadmin" exact component={CuatroCAdmin} props={childProps} />
    <AuthenticatedRoute path="/invoicingar" exact component={InvoicingAR} props={childProps} />
    <AuthenticatedRoute path="/products" exact component={Products} props={childProps} />
    <AuthenticatedRoute path="/purchasing" exact component={Purchasing} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <Route exact path="/linkedin" component={LinkedInPopUp} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;