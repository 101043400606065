import React, { Component } from "react";
import "./NoteEdit.css";
import { Container, Modal, Button, Row, Col, Table, ButtonToolbar, Form } from "react-bootstrap";
import { xToLocalDate } from './XFunctions';
import { FaRegTimesCircle } from 'react-icons/fa';

const initialState = {
  selectedNote: null,
}

export default class NoteEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {...initialState}
  }
  componentDidMount = () => {
    let note = this.props.isNew ? {} : {...this.props.selectedNote}
    let account = this.props.selectedAccount
    let selectedNote = {
      id:                        note.id,
      accountId:                 account.id,
      description:              (note.description && note.description !== 'NULL_STRING') ? note.description : '',
      occurredOnDateTime:        note.occurredOnDateTime,
      modifiedDateTime:          note.modifiedDateTime,
      latestActivityDateTime:    note.latestActivityDateTime,
      createdDateTime:           note.createdDateTime,
      ownerUserId:               note.ownerUserId,
      ownerUser:                 note.ownerUser,
      createdByUserId:           note.createdByUserId,
      createdByUser:             note.createdByUser,
      modifiedByUserId:          note.modifiedByUserId,
      modifiedByUser:            note.modifiedByUser,
    }
    this.setState({ selectedNote})
  }
  UNSAFE_componentWillUnmount = () => {
    this.setState({ selectedNote: null })
  }
  handleClose = () => {
    this.componentDidMount()
    this.props.triggerCloseModal()
  }
  handleOnChange = (e, id) => {
    let value = e.target.value
    this.setState(prevState => ({
      selectedNote: {
        ...prevState.selectedNote,
        [id]: value
      }
    }))
  }
  handleSave = () => {
    let note = {...this.state.selectedNote}
    if (note.description === '') {
      alert('Please enter a Description.')
      return
    }
    let selectedNote = {}
    for(var prop in note) {
      if (note[prop] !== '') {
        selectedNote[prop] = note[prop]
      }
    }
    this.props.triggerSaveNote(selectedNote)
  }
  handleNoteDeleteOnClick = () => {
    this.props.triggerNoteDelete(this.props.selectedNote.id)
  }
  
  render() {
    const selNote = this.state.selectedNote
    if (!selNote) {
      return null
    }
    return (
      <Modal className="NoteEdit" size="lg" show={this.props.showNoteEditModal} onHide={this.handleClose} >
        <Modal.Header closeButton >
          <Modal.Title>
            <span className="pre">Note modified:</span>
            <h5 className="note-edit-title">{xToLocalDate(selNote.modifiedDateTime)}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="top">
            <Row className="tools-wrap d-flex justify-content-end">
            </Row>
            <Row className="note-edit-panel">
              <Col className="note-edit-fields">
                <Table size="sm">
                  <tbody>
                    <tr>
                      <td className="field-label">Description</td>
                      <td colSpan="3">
                        <Form.Control size="sm" as="textarea" rows="10" placeholder="Description" value={selNote.description} onChange={(e) => this.handleOnChange(e, 'description')} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
            <ButtonToolbar className="note-delete">
              {!this.props.isNoteNew &&
                <Button variant="light" size="sm" onClick={this.handleNoteDeleteOnClick}>
                  <FaRegTimesCircle /> Delete
                </Button>
              }
            </ButtonToolbar>
          <ButtonToolbar>
            <Button className="cancel-save" variant="secondary" size="sm" onClick={this.handleClose}>Cancel</Button>
            <Button className="cancel-save" variant="primary" size="sm" onClick={this.handleSave}>Save</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
