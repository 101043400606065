// /* global google */
import React, { Component } from "react";
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, Modal, Table, Form, Accordion, Card }  from 'react-bootstrap';
import "./CuatroCAdmin.css";
import { ReactSortable } from "react-sortablejs";
import { xGenerateUID } from '../components/XFunctions';
import { ImArrowDown, ImArrowUp, ImPencil, ImPlus } from 'react-icons/im';
import { FaRegTrashAlt, FaPlusCircle } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md'
import { API } from "aws-amplify";

export default class CuatroCAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      editItemActive: false,
      editItemIdActive: false,
      editItemIdNewId: null,
      productVariantsTemp: [],
      selectedItem: null,
      selectedCollection: null,
      showWorkingMsg: false,
      collIsNew: false,
      pVarIsNew: false,
      listGrabState: 'end',
      collectionsUpdated: []
    }
  }
  componentDidMount() {
    this.updateCollections()
  }
  updateCollections = async () => {
    this.setState({ showWorkingMsg: true })
    let list = await this.handleGetCollectionsAll()
    for (let item of list) {
      item.onlineId = item.id
      if (item.id !== 'non-assigned') {
        item.id = xGenerateUID()
      } else {
        item.id = 'non-assigned'
      }
      let pVars = item.productVariants
      for (let pVar of pVars) {
        pVar.onlineId = pVar.id
        pVar.name = pVar.onlineId
        pVar.id = xGenerateUID()
      }
    }
    this.setState({ list, showWorkingMsg: false })
  }
  handleSetList = (collId, newCollItems) => {
    if (this.state.listGrabState === 'start') {
      let collectionsUpdated = [...this.state.collectionsUpdated]
      collectionsUpdated.push(collId)
      this.setState({ collectionsUpdated })
    } else if (this.state.listGrabState === 'end') {
      this.setState({ collectionsUpdated: [] })
    }
    for (let nCItem of newCollItems) {
      nCItem.groupId = collId
    }
    let list = [ ...this.state.list ]
    let mCollIdx = list.findIndex((coll) => {
      return coll.id === collId
    })
    list[mCollIdx].productVariants = newCollItems
    this.setState({ list })
  }
  handleOnListGrabStartEnd = async (listGrabState) => {
    if (listGrabState === 'end') {
      // Get unique values
      let uniqCollUpdated = [...new Set(this.state.collectionsUpdated)]
      await this.handleSaveCollectionSet(uniqCollUpdated)
    }
    this.setState({ listGrabState })
  }
  handleSaveCollectionOne = async (one) => {
    this.setState({ showWorkingMsg: true })
    let pVars = one.productVariants
    let productVariants = []
    for (let pVar of pVars) {
      let newPVar = { id: pVar.onlineId }
      productVariants.push(newPVar)
    }
    let newColl = {
      id:               one.onlineId,
      productVariants:  productVariants,
      title:            one.title,
      desc:             one.desc
    }
    await this.handleSaveCollection(newColl)
    this.setState({ showWorkingMsg: false })
  }
  handleSaveCollectionSet = async (set) => {
    this.setState({ showWorkingMsg: true })
    let list = [...this.state.list]
    for (const uniqCU of set) {
      let coll = list.find((item) => {
        return item.id === uniqCU
      })
      let pVars = coll.productVariants
      let productVariants = []
      for (let pVar of pVars) {
        let newPVar = { id: pVar.onlineId }
        productVariants.push(newPVar)
      }
      let newColl = {
        id:               coll.onlineId,
        productVariants:  productVariants,
        title:            coll.title,
        desc:             coll.desc
      }
      await this.handleSaveCollection(newColl)
    }
    this.setState({ showWorkingMsg: false })
  }
  handleCollDownOnClick = (id) => {
    let list = [ ...this.state.list ]
    let mCollIdx = list.findIndex((coll) => {
      return coll.id === id
    })
    // remove `from` item and store it
    let f = list.splice(mCollIdx, 1)[0];
    this.setState({ list })
    // insert stored item into position `to`
    list.splice(mCollIdx + 1, 0, f);
    this.setState({ list })
  }
  handleCollUpOnClick = (id) => {
    let list = [ ...this.state.list ]
    let mCollIdx = list.findIndex((coll) => {
      return coll.id === id
    })
    // remove `from` item and store it
    let f = list.splice(mCollIdx, 1)[0];
    // insert stored item into position `to`
    list.splice(mCollIdx - 1, 0, f);
    this.setState({ list })
  }
  handleOnChange = (e, id) => {
    let value = e.target.value
    this.setState(prevState => ({
      selectedItem: {
        ...prevState.selectedItem,
        [id]: value
      }
    }))
  }
  handleOnChangeVars = (e, idx, id) => {
    let value = e.target.value
    let selectedItem  = {...this.state.selectedItem}
    selectedItem.variants[idx][id] = value
    this.setState({ selectedItem })
  }
  handleOnChangeTabs = (e, idx, id) => {
    let value = e.target.value
    let selectedItem  = {...this.state.selectedItem}
    selectedItem.tabs[idx][id] = value
    this.setState({ selectedItem })
  }
  handleOnChangeVarsTitle = (e) => {
    let value = e.target.value
    let selectedItem = {...this.state.selectedItem}
    for (let variant of selectedItem.variants) {
      variant.title = value
    }
    this.setState({ selectedItem })
  }
  handleSelectedItemAddVariantOnClick = () => {
    let selectedItem = {...this.state.selectedItem}
    selectedItem.variants.push({sku: '', label: ''})
    this.setState({ selectedItem })
  }
  handleSelectedItemAddTabOnClick = () => {
    let selectedItem = {...this.state.selectedItem}
    selectedItem.tabs.push({title: '[title]', value: ''})
    this.setState({ selectedItem })
  }
  handleTabDeleteOnClick = (idx) => {
    let selectedItem = {...this.state.selectedItem}
    if (selectedItem.tabs.length === 1) {
      alert('There must be at least 1 tab.')
      return
    }
    selectedItem.tabs.splice(idx, 1)
    this.setState({ selectedItem })
  }
  handleVariantDeleteOnClick = (idx) => {
    let selectedItem = {...this.state.selectedItem}
    if (selectedItem.variants.length === 1) {
      alert('There must be at least 1 variant.')
      return
    }
    selectedItem.variants.splice(idx, 1)
    this.setState({ selectedItem })
  }



  // ****************************************************************************************
  // ITEM /
  // ****************************************************************************************
  handleNewItemOnClick = (groupId) => {
    let selectedItem  = {
      id: xGenerateUID(),
      intro: null,
      tabs: [{title: '[title]', value: null}],
      title: null,
      variants: [{label: null, sku: null}],
      vendor: null,
      groupId: groupId
    }
    this.setState({ selectedItem, pVarIsNew: true })
  }
  handleEditItemIdOnClick = async (selItem) => {
    this.setState({ editItemIdActive: true, editItemIdNewId: selItem.onlineId })
  }
  handleEditItemIdOnChange = (e) => {
    let editItemIdNewId = e.target.value
    this.setState({ editItemIdNewId })
  }
  handleEditItemIdSaveOnClick = async () => {
    let { editItemIdNewId } = this.state
    let newid = encodeURIComponent(editItemIdNewId.toLowerCase().replace(/\s+/g, '-').replaceAll('---', '-'))
    let pVarExists = await this.handleGetProductVariantById(newid)
    if (pVarExists) {
      alert('Please enter a different ID.')
      return
    }
    let selectedItem = {...this.state.selectedItem}
    selectedItem.id = xGenerateUID()
    selectedItem.onlineId = newid
    selectedItem.name = newid
    await this.handleItemRemove()
    this.setState({ selectedItem, pVarIsNew: false }, async () => {
      await this.handleItemSave()
      let list = [ ...this.state.list ]
      let collIdx = list.findIndex((coll) => {
        return coll.id === selectedItem.groupId
      })
      list[collIdx].productVariants.push(selectedItem)
      await this.handleSaveCollectionOne(list[collIdx])
      this.setState({ list })
    })
    this.setState({ editItemIdActive: false })
  }
  handleEditItemIdCancelOnClick = () => {
    this.setState({ editItemIdNewId: null, editItemIdActive: false })
  }
  handleEditItemOnClick = async (matchitem, groupId) => {
    this.setState({ showWorkingMsg: true })
    let selectedItem = null
    if (!matchitem.onlineId) {
      // If it does not have an online id, the item is new
      selectedItem  = {
        id: matchitem.id,
        intro: null,
        tabs: [{title: '[title]', value: null}],
        title: null,
        variants: [{label: null, sku: null}],
        vendor: null,
        groupId: groupId
      }
    } else {
      let onlineItem = await this.handleGetProductVariantById(matchitem.onlineId)
      selectedItem = {...onlineItem}
      selectedItem.onlineId = onlineItem.id
      selectedItem.id = matchitem.id
      selectedItem.groupId = groupId
    }
    this.setState({ showWorkingMsg: false, selectedItem })
  }
  handleEditItemClose = () => {
    this.setState({ selectedItem: false, pVarIsNew: false })
  }
  handleDuplicateItemOnClick = (item, collId) => {
    let dupItem = {...item}
    dupItem.id = xGenerateUID()
    let list = [ ...this.state.list ]
    let mCollIdx = list.findIndex((coll) => {
      return coll.id === collId
    })
    list[mCollIdx].productVariants.push(dupItem)
    this.setState({ list })
  }
  handleItemSave = async () => {
    let selectedItem = {...this.state.selectedItem}
    if (!selectedItem.title || selectedItem.title === '') {
      alert('A title is required.')
      return
    }
    let list = [ ...this.state.list ]
    let mCollIdx = list.findIndex((coll) => {
      return coll.id === selectedItem.groupId
    })
    if (this.state.pVarIsNew) {
      let title = selectedItem.title
      let newid = encodeURIComponent(title.toLowerCase().replace(/\s+/g, '-').replaceAll('---', '-'))
      selectedItem.onlineId = newid
      let prodVar = {
        groupId: selectedItem.groupId,
        id: selectedItem.id,
        name: selectedItem.onlineId,
        onlineId: selectedItem.onlineId
      }
      list[mCollIdx].productVariants.push(prodVar)
    }
    let onlinePVar = {
      id: selectedItem.onlineId,
      intro: selectedItem.intro,
      tabs: selectedItem.tabs,
      title: selectedItem.title,
      variants: selectedItem.variants,
      vendor: selectedItem.vendor,
      disabled: selectedItem.disabled
    }
    await this.handleSaveProductVariant(onlinePVar)
    await this.handleSaveCollectionOne(list[mCollIdx])
    this.setState({ selectedItem: null, pVarIsNew: false })
    this.handleEditItemClose()
  }
  handleItemRemove = async () => {
    let list = [ ...this.state.list ]
    let { selectedItem } = this.state
    let { groupId, id } = selectedItem
    let collIdx = list.findIndex((coll) => {
      return coll.id === groupId
    })
    let itemIdx = list[collIdx].productVariants.findIndex((variant) => {
      return variant.id === id
    })
    let nonCollIdx = list.findIndex((coll) => {
      return coll.id === 'non-assigned'
    })
    if (nonCollIdx === -1) {
      list.push({ 
        id: 'non-assigned',
        name: '(Unassigned)',
        onlineId: 'non-assigned',
        productVariants: [],
        title: '(Unassigned)'
      })
      nonCollIdx = list.length - 1
    }
    let orphanedPVar = {...list[collIdx].productVariants[itemIdx]}
    orphanedPVar.groupId = 'non-assigned'
    list[nonCollIdx].productVariants.push(orphanedPVar)
    list[collIdx].productVariants.splice(itemIdx, 1)
    await this.handleSaveCollectionOne(list[collIdx])
    await this.handleSaveCollectionOne(list[nonCollIdx])
    this.setState({ list, selectedItem: null })
  }
  handleItemDisabledCheckOnChange = (e, id) => {
    let value = e.target.checked
    this.setState(prevState => ({
      selectedItem: {
        ...prevState.selectedItem,
        [id]: value
      }
    }))
  }
  // ****************************************************************************************
  // / ITEM
  // ****************************************************************************************


  // ****************************************************************************************
  // COLLECTION >
  // ****************************************************************************************
  handleCollNewOnClick = () => {
    this.setState({ collNew: true })
    let selectedCollection = {
      id: xGenerateUID(),
      onlineId: '',
      name: '',
      desc: '',
      productVariants: [],
      title: ''
    }
    this.setState({ selectedCollection, collIsNew: true })
  }
  handleCollEditClose = () => {
    this.setState({ selectedCollection: null, collIsNew: false })
  }
  handleCollEditOnClick = (collId) => {
    this.setState({ collIsNew: false })
    let list = [ ...this.state.list ]
    let selectedCollection = list.find((coll) => {
      return coll.id === collId
    })
    this.setState({ selectedCollection })
  }
  handleCollEditTitleOnChange = (e) => {
    let title = e.target.value
    if (this.state.collIsNew) {
      let onlineId = encodeURIComponent(title.toLowerCase().replace(/\s+/g, '-').replaceAll('---', '-'))
      this.setState(prevState => ({
        selectedCollection: {
          ...prevState.selectedCollection,
          title,
          onlineId
        }
      }))
    } else {
      this.setState(prevState => ({
        selectedCollection: {
          ...prevState.selectedCollection,
          title,
        }
      }))
    }
  }
  handleCollEditDescOnChange = (e) => {
    let desc = e.target.value
    this.setState(prevState => ({
      selectedCollection: {
        ...prevState.selectedCollection,
        desc,
      }
    }))
  }
  handleCollEditSaveOnClick = async (collId) => {
    let selectedCollection = {...this.state.selectedCollection}
    let { title, onlineId } = selectedCollection
    if (!title) {
      alert('A title is required.')
      return
    }
    let onlineColl = await this.handleGetCollectionById(onlineId)
    if (onlineColl && this.state.collIsNew) {
      alert('This ID already exists. Please change the name to change the ID.')
      return
    }
    // Update online
    await this.handleSaveCollectionOne(selectedCollection)
    // Update local
    let list = [ ...this.state.list ]
    if (this.state.collIsNew) {
      list.unshift(selectedCollection)
    } else {
      let mCollIdx = list.findIndex((coll) => {
        return coll.id === collId
      })
      list[mCollIdx].title = selectedCollection.title
      list[mCollIdx].desc = selectedCollection.desc
    }
    this.setState({ list, selectedCollection: null, collIsNew: false })
  }
  handleCollDeleteOnClick = async (collId) => {
    let list = [ ...this.state.list ]
    let mCollIdx = list.findIndex((coll) => {
      return coll.id === collId
    })
    let onlineId = list[mCollIdx].onlineId
    let orphanedPVars = [...list[mCollIdx].productVariants]
    list.splice(mCollIdx, 1)
    let nonCollIdx = list.findIndex((coll) => {
      return coll.id === 'non-assigned'
    })
    if (nonCollIdx === -1) {
      list.push({ 
        id: 'non-assigned',
        name: '(Unassigned)',
        onlineId: 'non-assigned',
        productVariants: [],
        title: '(Unassigned)',
        desc: ''
      })
      nonCollIdx = list.length - 1
    }
    list[nonCollIdx].productVariants.push(...orphanedPVars)
    // Update online
    await this.handleDeleteCollection(onlineId)
    await this.handleSaveCollectionOne(list[nonCollIdx])
    this.setState({ list, selectedCollection: null })
  }
  // ****************************************************************************************
  // / COLLECTION
  // ****************************************************************************************

  // ****************************************************************************************
  // API CALLS >
  // ****************************************************************************************
  handleGetCollectionsAll = async () => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getproductcollectionscuatroall',
        params: {}
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Items) {
      let list = res.result.Items
      return list
    } else {
      return false
    }
  }
  handleSaveCollection = async (collection) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'addproductcollectioncuatro',
        params:{
          collection
        }
      }
    }).then(response => response).catch(error => null)
    if (!(res && res.result)) {
      alert('There was a problem saving the collection: ' + collection.id)
    }
  }
  handleDeleteCollection = async (id) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'deleteproductcollectioncuatro',
        params: {
          id
        }
      }
    }).then(response => response).catch(error => null)
    if (!(res && res.result)) {
      alert('There was a problem deleting the collection: ' + id)
    }
  }
  handleGetCollectionById = async (id) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getproductcollectionscuatrobyid',
        params:{
          id
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let coll = res.result.Item
      return coll
    } else {
      return false
    }
  }
  handleGetProductVariantById = async (id) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'getproductvariantcuatrobyid',
        params:{
          id
        }
      }
    }).then(response => response).catch(error => null)
    if (res && res.result && res.result.Item) {
      let variant = res.result.Item
      return variant
    } else {
      return false
    }
  }
  handleSaveProductVariant = async (variant) => {
    let res = await API.post('base', 'products', {
      body: {
        op: 'addproductvariantcuatro',
        params:{
          variant
        }
      }
    }).then(response => response).catch(error => null)
    if (!(res && res.result)) {
      alert('There was a problem saving the variant: ' + variant.id)
    }
  }
  // ****************************************************************************************
  // / API CALLS
  // ****************************************************************************************

  render() {
    const selItem = this.state.selectedItem
    const selColl = this.state.selectedCollection
    return (
      <div className="CuatroCAdmin">
        <Container >
          <Row className="mt-1">
            <Col><h3>Cuatro Collections</h3></Col>
            <Col className="header-tools">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button variant="outline-secondary" size="sm" onClick={this.handleCollNewOnClick}><FaPlusCircle/> New collection</Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.list.map((group, gidx) => (
                <div key={group.id} className={"list-group " + group.id} >
                  <Row>
                    <Col>
                      <div className="group-title">{group.title}</div>
                      <div className="group-onlineid">{group.onlineId}</div>
                    </Col>
                    <Col className={'group-tools'}>
                      <ButtonToolbar>
                        {group.id !== 'non-assigned' &&
                          <ButtonGroup>
                            <Button variant="link" className="delete" size="sm" onClick={() => this.handleCollEditOnClick(group.id)}><ImPencil/></Button>
                          </ButtonGroup>
                        }
                        <ButtonGroup className="mr-2">
                          <Button disabled={gidx === 0 ? true : false} variant="outline-secondary" size="sm" onClick={() => this.handleCollUpOnClick(group.id)}><ImArrowUp/></Button>
                          <Button disabled={gidx === this.state.list.length - 1 ? true : false} variant="outline-secondary" size="sm" onClick={() => this.handleCollDownOnClick(group.id)}><ImArrowDown/></Button>
                        </ButtonGroup>
                        {group.id !== 'non-assigned' &&
                          <ButtonGroup>
                            <Button variant="outline-secondary" size="sm" onClick={() => this.handleNewItemOnClick(group.id)}><ImPlus/></Button>
                          </ButtonGroup>
                        }
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ReactSortable
                        group="shared"
                        list={group.productVariants}
                        setList={(newGroupItems) => this.handleSetList(group.id, newGroupItems)}
                        animation={150}
                        ghostClass="chosen"
                        onStart={(e) => this.handleOnListGrabStartEnd('start', e)}
                        onEnd={(e) => this.handleOnListGrabStartEnd('end', e)}
                      >
                        {group.productVariants.map((item) => (
                          <div id={item.id} key={item.id} className="list-group-item grabbable">
                            <Row>
                              <Col>
                                {item.name}
                              </Col>
                              <Col className="item-tools">
                              {group.id !== 'non-assigned' &&
                                <ButtonToolbar>
                                  <ButtonGroup className="mr-2">
                                    <Button variant="link" size="sm" onClick={() => this.handleDuplicateItemOnClick(item, group.id)} ><MdContentCopy /></Button>
                                    <Button className="ml-2" variant="link" size="sm" onClick={() => this.handleEditItemOnClick(item, group.id)} ><ImPencil/></Button>
                                  </ButtonGroup>
                                </ButtonToolbar>
                              }
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </ReactSortable>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
        {selColl &&
          <Modal className="CuatroCAdminEditColl" size="md" show={selColl ? true : false} onHide={this.handleCollEditClose} backdrop="static" >
            <Modal.Header closeButton onHide={this.handleCollEditClose}>
              <h5>id: {selColl.onlineId}</h5>
            </Modal.Header>
            <Modal.Body>
              <div className="group-title">
                <div className="field-label">Title</div>
                <Form.Control size="sm" type="text" placeholder="" value={selColl.title ? selColl.title : ''} onChange={(e) => this.handleCollEditTitleOnChange(e)} />
              </div>
              <div className="group-desc mt-1">
                <div className="field-label">Description</div>
                <Form.Control size="sm" as="textarea" rows="8" placeholder="" value={selColl.desc ? selColl.desc : ''} onChange={(e) => this.handleCollEditDescOnChange(e)} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Col>
                {!this.state.collIsNew &&
                <ButtonToolbar className="delete-remove">
                  <Button className="delete" variant="outline-danger" size="sm" onClick={() => this.handleCollDeleteOnClick(selColl.id)}>Delete</Button>
                </ButtonToolbar>
                }
              </Col>
              <Col>
                <ButtonToolbar className="cancel-save">
                  <Button className="cancel" variant="secondary" size="sm" onClick={this.handleCollEditClose}>Cancel</Button>
                  <Button className="save" variant="primary" size="sm" onClick={() => this.handleCollEditSaveOnClick(selColl.id)}>Save</Button>
                </ButtonToolbar>
              </Col>
            </Modal.Footer>
          </Modal>
        }
        {selItem &&
          <Modal className="CuatroCAdminEditItem" size="lg" show={selItem ? true : false} onHide={this.handleEditItemClose} backdrop="static" >
            <Modal.Header closeButton onHide={this.handleEditItemClose}>
              <div>
                <h5>id: {selItem.onlineId}
                {!this.state.editItemIdActive &&
                  <Button variant="link" size="sm" onClick={() => this.handleEditItemIdOnClick(selItem)} ><ImPencil/></Button>
                }
                </h5>
              </div>
            </Modal.Header>
            {this.state.editItemIdActive ?
              <Modal.Body className="edit-value">
                <Form.Control size="sm" type="text" placeholder="" value={this.state.editItemIdNewId ? this.state.editItemIdNewId : ''} onChange={(e) => this.handleEditItemIdOnChange(e)} />
                <Button className="cancel" variant="secondary" size="sm" onClick={() => this.handleEditItemIdCancelOnClick()} >Cancel</Button>
                <Button className="save" variant="primary" size="sm" onClick={() => this.handleEditItemIdSaveOnClick()} >Save New ID</Button>
              </Modal.Body>
            :
              <Modal.Body>
                <Table size="sm" className="noborder">
                  <tbody>
                    <tr>
                      <td className="field-label">Title</td>
                      <td>
                        <Form.Control size="sm" type="text" placeholder="" value={selItem.title ? selItem.title : ''} onChange={(e) => this.handleOnChange(e, 'title')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Vendor</td>
                      <td>
                        <Form.Control size="sm" type="text" placeholder="" value={selItem.vendor ? selItem.vendor : ''} onChange={(e) => this.handleOnChange(e, 'vendor')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Intro</td>
                      <td>
                        <Form.Control size="sm" as="textarea" rows="8" placeholder="" value={selItem.intro ? selItem.intro : ''} onChange={(e) => this.handleOnChange(e, 'intro')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Vary by</td>
                      <td>
                        <Form.Control size="sm" type="text" placeholder="" value={selItem.variants[0].title ? selItem.variants[0].title : ''} onChange={(e) => this.handleOnChangeVarsTitle(e, 'title')} />
                      </td>
                    </tr>
                    <tr>
                      <td className="variants-values" colSpan="2">
                        <Table size="sm" className="noborder">
                          <tbody>
                            {selItem.variants.map((variant, idx) => {
                              return(
                                <tr key={idx}>
                                  <td className="field-label">Sku</td>
                                  <td>
                                    <Form.Control size="sm" type="text" placeholder="" value={variant.sku ? variant.sku : ''} onChange={(e) => this.handleOnChangeVars(e, idx, 'sku')} />
                                  </td>
                                  <td className="field-label">Label</td>
                                  <td>
                                    <Form.Control size="sm" type="text" placeholder="" value={variant.label ? variant.label : ''} onChange={(e) => this.handleOnChangeVars(e, idx, 'label')} />
                                  </td>
                                  <td>
                                    <Button className="delete" variant="link" size="sm" onClick={() => this.handleVariantDeleteOnClick(idx)}><FaRegTrashAlt/></Button>
                                  </td>
                                </tr>
                              )
                            })}
                            <tr>
                              <td colSpan="5" className="addmore">
                                <Button variant="link" size="sm" onClick={() => this.handleSelectedItemAddVariantOnClick()}><FaPlusCircle/> Add variant</Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Accordion className="tabs">
                  {selItem.tabs.map((tab, idx) => {
                    idx++
                    return(
                    <Card key={idx}>
                      <Accordion.Toggle as={Card.Header} eventKey={idx}>
                        {tab.title}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={idx}>
                        <Card.Body>
                          <Form.Control className="title" size="sm" type="text" placeholder="" value={tab.title ? tab.title : ''} onChange={(e) => this.handleOnChangeTabs(e, idx - 1, 'title')} />
                          <Form.Control className="value" size="sm" as="textarea" rows="8" placeholder="" value={tab.value ? tab.value : ''} onChange={(e) => this.handleOnChangeTabs(e, idx - 1, 'value')} />
                          <Button variant="link" className="delete" size="sm" onClick={() => this.handleTabDeleteOnClick(idx - 1)}>Delete</Button>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    )
                  })}
                </Accordion>
                <div className="addmore">
                  <Button variant="link" size="sm" onClick={() => this.handleSelectedItemAddTabOnClick()}><FaPlusCircle/> Add tab</Button>
                </div>
                <div className="disabled">
                  <Form.Check label="Disabled" size="sm" checked={selItem.disabled} onChange={(e) => this.handleItemDisabledCheckOnChange(e, 'disabled')} />
                </div>
              </Modal.Body>
            }
            {!this.state.editItemIdActive &&
              <Modal.Footer>
                <Col>
                  <ButtonToolbar className="delete-remove">
                    {/*<Button className="delete" variant="outline-danger" size="sm" onClick={this.handleItemDelete}>Delete</Button>*/}
                    <Button className="remove" variant="outline-secondary" size="sm" onClick={this.handleItemRemove}>Remove</Button>
                  </ButtonToolbar>
                </Col>
                <Col>
                  <ButtonToolbar className="cancel-save">
                    <Button className="cancel" variant="secondary" size="sm" onClick={this.handleEditItemClose}>Cancel</Button>
                    {/*<Button className="save" variant="primary" size="sm" onClick={() => this.handleItemSave('www')}>www</Button>*/}
                    <Button className="save" variant="primary" size="sm" onClick={() => this.handleItemSave()}>Save</Button>
                  </ButtonToolbar>
                </Col>
              </Modal.Footer>
            }
          </Modal>
        }
        <Modal className="working-msg" size="sm" show={this.state.showWorkingMsg} >
          <Modal.Header></Modal.Header>
          <Modal.Body>Please wait...</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }
}


