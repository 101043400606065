import React from "react";
import { Button } from "react-bootstrap";
import "./LoaderButton.css";
import { FaSpinner } from 'react-icons/fa'

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button className={`LoaderButton ${className}`} disabled={disabled || isLoading} {...props} >
    {isLoading && <FaSpinner className="spinning" />}
    {!isLoading ? text : loadingText}
  </Button>;